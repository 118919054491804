import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, LabelList, Label } from 'recharts';

const MultiLineChart = ({ label, value1, value2, chartData, chartHeight, chartHeading, color1, color2 }) => {

    const CustomizedLabel = ({ x, y, width, height, value }) => (
        <>
            <text x={x + width / 2} y={y - 5} fill={value === 0 ? 'red' : '#8884d8'} textAnchor="middle" fontSize={11}>
                {value}
            </text>
        </>
    );

    return (
        <>
            <p className='text-center fw-bold'>{chartHeading}</p>
            <ResponsiveContainer height={chartHeight ? chartHeight : '500'}>
                <BarChart
                    width={100}
                    height={500}
                    data={chartData}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" stroke="#ccc" strokeWidth={0.5} />
                    <XAxis dataKey={label} fontSize={10} angle={-10} textAnchor="end" interval={0}>
                        <Label offset={0} position="bottom" fontSize={10} />
                    </XAxis>
                    <YAxis type="number" fontSize={10} />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey={value1} fill={color1}>
                        <LabelList dataKey={value1} position="top" content={<CustomizedLabel />} />
                    </Bar>
                    <Bar dataKey={value2} fill={color2}>
                        <LabelList dataKey={value2} position="top" content={<CustomizedLabel />} />
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        </>
    );

}

export default MultiLineChart;