import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row , InputGroup} from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";

const CreateClientModel = ({show, close}) => {

      // toast notification
    const AddClientSuccess = () => {
        toast.success("Added Successfully", {
        position: toast.POSITION.TOP_CENTER,
        });
    };

    const AlreadyClientAdd = () => {
        toast.error("Client Already Added", {
        position: toast.POSITION.TOP_CENTER,
        });
    };

    const [clientName, setClientName] = useState("")
    const [contactno, setContactNo] = useState("")
    const [company, setCompany] = useState("")
    const [address, setAddress] = useState("")
    const [details, setDetails] = useState("")
    const[email,setEmail] = useState("")
    const [status, setStatus] = useState("")

    // for error msg state
     // for error
  const [clientnameerror, setClientNameError] = useState(false);
  const [clientcontacterror, setClientConatctError] = useState(false);
  const [clientcompanyerror, setClientCompanyError] = useState(false);
  const [clientaddresserror, setClientAddressError] = useState(false);
  const [clientdetailserror, setClientDetailsError] = useState(false);
  const [clientemailerror, setClientEmailError] = useState(false);
  const [clientstatuserror, setClientStatusError] = useState(false);
  const StatusOptions = [{ value: 'inactive', label: 'In Active' }, { value: 'active', label: 'Active' }];
    
    const handlerValidate = () => {
    let FormValid = true;

    if (clientName.length === 0) {
      FormValid = false;
      setClientNameError(true);
    }

    else if (company.length === 0) {
      FormValid = false;
      setClientCompanyError(true);
    }

    else if (contactno.length === 0) {
      FormValid = false;
      setClientConatctError(true);
    } else if (contactno.length < 10) {
      FormValid = false;
      setClientConatctError(true);
    }

    else if (email.length === 0) {
      FormValid = false;
      setClientEmailError(true);
    }

    else if (details.length === 0) {
      FormValid = false;
      setClientDetailsError(true);
    }

    else if (status.length === 0) {
      FormValid = false;
      setClientStatusError(true);
    }

   else if (address.length === 0) {
      FormValid = false;
      setClientAddressError(true);
    }

    return FormValid;
  };

    const handlerSubmit = async (e) => {
        // e.preventDefault();
        setClientNameError(false);
        setClientConatctError(false);
        setClientCompanyError(false);
        setClientAddressError(false);
        setClientDetailsError(false);
        setClientEmailError(false)
        setClientStatusError(false)
    
        if (handlerValidate()) {
          const payload = {
            clientName: clientName,
            contactNo: contactno,
            company: company,
            address: address,
            details: details,
            email: email,
            status: status.value
          };
          await axios
            .post(process.env.REACT_APP_BASE_URL + "/client/addClient", payload)
            .then((res) => {
                console.log("response",res)
              if(res.data.message === "Client already added."){
                AlreadyClientAdd()
              }else{
                AddClientSuccess()
                close()
                setClientName("")
                setCompany("")
                setAddress("")
                setContactNo("")
                setDetails("")
                setStatus("")
                setEmail("")
    }
            })
            .catch((error) => {
              console.log("Error", error)
            });
        }
      };

  return (
    <>
    <ToastContainer/>
    <Modal show={show} onHide={close} size="lg" centered backdrop='static' keyboard={false}>
    <Modal.Header
      closeButton
      style={{
        color: "black",
        width: "100%",
        height: "50px",
        backgroundColor: "#F9D65F",
      }}
    >
      <Modal.Title style={{ fontWeight: "bold", fontFamily: "Poppins" }}>
        Create Client
      </Modal.Title>
    </Modal.Header>
    <Modal.Body size="lg">

        <Row className='mb-4'>
          <Col sm={12} md={6}>
            <Form.Group controlId="formBasicId">
              <Form.Label>
                Client Name
                <span className="requiredfield">*</span>
              </Form.Label>
               <Form.Control
                    placeholder='Client Name'
                    name="clientName"
                    value={clientName}
                    onChange={(e) => {
                      if (
                        !e.target.value.match(
                          /[+@#$&%!~=^_:(){}\[\]*.|//\-/?<>,;`'""/\\]/
                        ) &&
                        e.target.value.trim()
                      ) {
                        setClientName(e.target.value);
                      } else if (e.target.value.length === 0) {
                        setClientName(e.target.value);
                      }
                      clientName?.length >= 0
                        ? setClientNameError(false)
                        : setClientNameError(true);
                    }}
                  />
              {clientnameerror ? (
                  <p className='errMsg'>
                    <>
                      <Icon.ExclamationTriangle className="me-1" />
                    </>
                    Field is Required
                  </p>
                ) : null}
            </Form.Group>
          </Col>

           <Col sm={12} md={6}>
            <Form.Group controlId="formBasicId">
              <Form.Label >
                Company Name
                <span className="requiredfield">*</span>
              </Form.Label>
               <Form.Control
                    placeholder='Company Name'
                    name="company"
                    value={company}
                    onChange={(e) => {
                      if (
                        !e.target.value.match(
                          /[+@#$&%!~=^_:(){}\[\]*.|//\-/?<>,;`'""/\\]/
                        ) &&
                        e.target.value.trim()
                      ) {
                        setCompany(e.target.value);
                      } else if (e.target.value.length === 0) {
                        setCompany(e.target.value);
                      }
                      company?.length >= 0
                        ? setClientCompanyError(false)
                        : setClientCompanyError(true);
                    }}
                  />
              {clientcompanyerror ? (
                  <p className='errMsg'>
                    <>
                      <Icon.ExclamationTriangle className="me-1" />
                    </>
                    Field is Required
                  </p>
                ) : null}
            </Form.Group>
          </Col>
        </Row>

        <Row className='mb-4'>
          <Col sm={12} md={6}>
            <Form.Group controlId="">
              <Form.Label >
                Conatct No
                <span className="requiredfield">*</span>
              </Form.Label>
              <Form.Control
                  placeholder='Conatct Number'
                  name="contactno"
                  value={contactno}
                  onChange={(e) => {
                    if (
                      !e.target.value.match(
                        /[A-Za-z+@#$&%!~=^_:(){}\[\]*.|/\s/g/\-/?<>,;`'""/\\]/
                      ) &&
                      e.target.value.length <= 10
                    ) {
                      setContactNo(e.target.value);
                    } else if (e.target.value.length === 0) {
                      setContactNo(e.target.value);
                    }
                    contactno?.length <= 0
                      ? setClientConatctError(true)
                      : setClientConatctError(false);
                  }}
                >
                </Form.Control>
                {clientcontacterror ? (
                  <p className='errMsg'>
                    <>
                      <Icon.ExclamationTriangle className="me-1" />
                    </>
                    Field is Required
                  </p>
                ) : null}
            </Form.Group>
          </Col>
          <Col sm={12} md={6}>
            <Form.Group>
              <Form.Label >
                Email
                <span className="requiredfield">*</span>
              </Form.Label>
             <Form.Control
                  placeholder='Email Id'
                  name="email"
                  value={email}
                  onChange={(e) => {
                    if (
                      !e.target.value.match(
                        /[A-Z+#$&%!~=^_:(){}\[\]*|/\s/\-/?<>,;`'""/\\]/
                      )
                    ) {
                      setEmail(e.target.value);
                    } else if (e.target.value.length === 0) {
                     setEmail(e.target.value);
                    }
                    email?.length >= 0
                      ? setClientEmailError(false)
                      : setClientEmailError(true);
                  }}
                >
                </Form.Control>
                {clientemailerror ? (
                  <p className='errMsg'>
                    <>
                      <Icon.ExclamationTriangle className="me-2" />
                    </>
                    Required Field
                  </p>
                ) : null}
            </Form.Group>
          </Col>
        </Row>
        
        <Row className='mb-4'>
        <Col sm={12} md={6}>
            <Form.Group className="">
              <Form.Label>
                Client Details
                <span className="requiredfield">*</span>
              </Form.Label>
              <Form.Control
                  placeholder='Client Details'
                  name="details"
                  value={details}
                  onChange={(e) => {
                    if (
                      !e.target.value.match(
                        /[0-9+@#$&%!~=^_:(){}\[\]*.|//\-/?<>,;`'""/\\]/
                      )
                    ) {
                      setDetails(e.target.value);
                    } else if (e.target.value.length === 0) {
                       setDetails(e.target.value);
                    }
                    details?.length >= 0
                      ? setClientDetailsError(false)
                      : setClientDetailsError(true);
                  }}
                >
                </Form.Control>
                {clientdetailserror ? (
                  <p className='errMsg'>
                    <>
                      <Icon.ExclamationTriangle className="me-1" />
                    </>
                    Field is Required
                  </p>
                ) : null}
            </Form.Group>
        </Col>
        
        <Col sm={12} md={6}>
            <Form.Group className="">
              <Form.Label>
                 Status
                <span className="requiredfield">*</span>
              </Form.Label>
              <Select
                isMulti={false}
                isSearchable={true}
                name='status'
                value={status}
                options={StatusOptions}
                onChange={(option) => {
                  setStatus(option);
                  status.length < 0
                    ? setClientStatusError(true)
                    : setClientStatusError(false);
                }}
              />
              {clientstatuserror ? (
                <p className="errMsg">
                  <>
                    <Icon.ExclamationTriangle className="me-2" />
                  </>
                  Required Field
                </p>
              ) : null}
            </Form.Group>
        </Col>
        </Row>

        <Row className='mb-4'>
          <Col sm={12} md={12}>
            <Form.Group className="">
              <Form.Label className="mt-3 fw-bold">
                Address
                <span className="requiredfield">*</span>
              </Form.Label>
             <Form.Control
                  placeholder='Address'
                  as="textarea"
                  value={address}
                  name="address"
                  onChange={(e) => {
                    if (
                      !e.target.value.match(
                        /[+@#$&%!~=^_:(){}\[\]*.|//\-/?<>,;`'""/\\]/
                      )
                    ) {
                      setAddress(e.target.value);
                    } else if (e.target.value.length === 0) {
                      setAddress(e.target.value);
                    }
                    address?.length >= 0
                      ? setClientAddressError(false)
                      : setClientAddressError(true);
                  }}
                />
                {clientaddresserror ? (
                  <p className='errMsg'>
                    <>
                      <Icon.ExclamationTriangle className="me-2" />
                    </>
                    Required Field
                  </p>
                ) : null}
            </Form.Group>
          </Col>
        </Row>
    </Modal.Body>

      <Modal.Footer className="text-center mt-4">
        <Button className="primaryBtn " 
        onClick={() => { handlerSubmit() }}
        >
          <Icon.PersonAdd className='me-2' /> Create Client
        </Button>
      </Modal.Footer>
  </Modal>
    </>
   
  )
}

export default CreateClientModel