import axios from 'axios'
import moment from 'moment/moment';
import { InputGroup, Modal, Button, Form, Col, Row} from "react-bootstrap";
import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import * as Icon from "react-bootstrap-icons";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import "react-toastify/dist/ReactToastify.css";
import Badge from 'react-bootstrap/Badge';
import Select from "react-select";
import useGetAllCategories from '../../Common/ReuseApi/useGetAllCategories';
import useGetDepartments from '../../Common/ReuseApi/useGetDepartments';
import { getProjectData ,getAdminFilterOption} from '../AdminSlice';

export const FilterProjectModel = ({show, close}) => {
  const dispatch = useDispatch()

  const allAdminDropdownOption = useSelector(state => state?.admin?.getAdminFilterDropdown)
  const { allCategoriesOptions } = useGetAllCategories()
  const { allDepartmentOptionsById } = useGetDepartments()

  const [projName, setProjName] = useState("")
  const [departmentName, setDepartmentName ] = useState("")
  const [clientName, setClientName ] = useState("")
  const [projectType, setProjectType ] = useState("")
  const [categoryName, setCategoryName ] = useState("")
  const [status, setStatus ] = useState("")

    const getAllProjectData = () =>{
      const payload = {
        "projName": projName.value ?? "",
        "departmentName": departmentName.label ?? "",
        "clientName": clientName.value ?? "",
        "projectType": projectType.value ?? "",
        "categoryName": categoryName.label ?? "",
        "status": status.value ?? "",
        "documentsPerPage": 10,
        "page": 1,
    }
      dispatch(getProjectData(payload))
  }

  // for filter function call
   useEffect(() => {
        dispatch(getAdminFilterOption())
    }, [])
    
    
    const handleFilterSubmit = () =>{
      getAllProjectData()
      close()
    }
    // for clear data
    const clearFilter = () =>{
      setProjName("")
      setDepartmentName("")
      setClientName("")
      setProjectType("")
      setCategoryName("")
      setStatus("") 
    }

   
    const projectTypeOptions = [{ value: 'internal', label: 'Internal' }, { value: 'external', label: 'External' }];
    const projectStatusOptions = [{ value: 'inprogress', label: 'Inprogress' }, { value: 'completed', label: 'Completed' },
                                 { value: 'planed', label: 'Planed' }, { value: 'in support', label: 'In Support' }];


  return (
    <>
    <ToastContainer/>
    <Modal show={show} onHide={close} size="lg" centered backdrop='static' keyboard={false}>
    <Modal.Header
      closeButton
      style={{
        color: "black",
        width: "100%",
        height: "50px",
        backgroundColor: "#F9D65F",
      }}
    >
      <Modal.Title style={{ fontWeight: "bold", fontFamily: "Poppins" }}>
        Filter
      </Modal.Title>
    </Modal.Header>
    <Modal.Body size="lg">

        <Row className='mb-4'>
          <Col sm={12} md={6}>
            <Form.Group controlId="formBasicId">
              <Form.Label className="mt-3 fw-bold">
                Project Name
              </Form.Label>
              <Select
                isMulti={false}
                options={
                allAdminDropdownOption !== null &&
                allAdminDropdownOption !== undefined &&
                allAdminDropdownOption?.data?.projects
              }
              value={projName}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder="Select Project Name"
              onChange={(option) => { setProjName(option) }}
              isSearchable={true}
          />
            </Form.Group>
          </Col>

          <Col sm={12} md={6}>
            <Form.Group controlId="">
              <Form.Label className="mt-3 fw-bold">
                Client Name
              </Form.Label>
              <Select
                isMulti={false}
                options={
                allAdminDropdownOption !== null &&
                allAdminDropdownOption !== undefined &&
                allAdminDropdownOption?.data?.clients
              }
              value={clientName}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder="Select Client Name"
              onChange={(option) => { setClientName(option) }}
              isSearchable={true}
            />
            </Form.Group>
          </Col>
        </Row>

        <Row className='mb-4'>
        <Col sm={12} md={6}>
            <Form.Group className="">
              <Form.Label className="mt-3 fw-bold">
                Project Category
              </Form.Label>
              <Select
                isMulti={false}
                isSearchable={true}
                name='categoryName'
                value={categoryName}
                options={allCategoriesOptions}
                placeholder="Select Category "
                onChange={(option) => {
                  setCategoryName(option);
                }} 
                />
            </Form.Group>
          </Col>

          <Col sm={12} md={6}>
            <Form.Group className="">
              <Form.Label className="mt-3 fw-bold">
                Department Name
              </Form.Label>
              <Select
                isMulti={false}
                isSearchable={true}
                name='departmentName'
                value={departmentName}
                placeholder="Select Department Name"
                options={allDepartmentOptionsById}
                onChange={(option) => {
                  setDepartmentName(option);
                }}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className='mb-4'>
          <Col sm={12} md={6}>
            <Form.Group className="">
              <Form.Label className="mt-3 fw-bold">
                Project Type
              </Form.Label>
              <Select
                isMulti={false}
                isSearchable={true}
                name='projectType'
                value={projectType}
                options={projectTypeOptions}
                 placeholder="Select Project Type"
                onChange={(option) => {
                  setProjectType(option);
                }}
              />
            </Form.Group>
          </Col>

          <Col sm={12} md={6}>
            <Form.Group className="">
              <Form.Label className="mt-3 fw-bold">
                Project Status
              </Form.Label>
              <Select
                isMulti={false}
                isSearchable={true}
                name='status'
                value={status}
                options={projectStatusOptions}
                placeholder="Select Status"
                onChange={(option) => {
                  setStatus(option);
                }}
              />
            </Form.Group>
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <Button className="secondaryBtn" type='clear' onClick={clearFilter}>Reset</Button>
        <Button className="primaryBtn" onClick={() => { handleFilterSubmit() }}>Submit </Button>
      </Modal.Footer>
    </Modal>
    </>
  )
}
