import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    activeNumberKey: ''
};


const sidebarSlice = createSlice({
  name: 'activeKey',
  initialState,
  reducers:{
    setActiveKey(state, action){
      state.activeNumberKey = action.payload
    }
  },
});


export const {setActiveKey} = sidebarSlice.actions;
export default sidebarSlice.reducer;