import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
    employeeData: [],
    filteredEmpData: [],
    HrAllEmpTaskReview: [],
    HrSelfReview: [],
    filterManageEmp: [],
    loading: 'idle'
}

export const getEmployeeData = createAsyncThunk("getEmployeeDataPromise", async (data, { rejectWithValue }) => {
    const response = await fetch(process.env.REACT_APP_BASE_URL + data.endpoint, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data.payload)
    })
    try {
        const result = await response.json()
        return result
    } catch (error) {
        return rejectWithValue(error)
    }
}
)

export const getAllTask = createAsyncThunk("getAllTaskPromise", async (data, { rejectWithValue }) => {
    const response = await fetch(process.env.REACT_APP_BASE_URL + "/task/getAllTask", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data)
    })
    try {
        const result = await response.json()
        return result
    } catch (error) {
        return rejectWithValue(error)
    }
})


// ------------ New changes 18-07-2023 -------------  //

export const getAllReview = createAsyncThunk("getAllReviewPromise", async (data, { rejectWithValue }) => {
    const response = await fetch(process.env.REACT_APP_NEW_BASE_URL + '/reviewTask', {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data)
    })
    try {
        const result = await response.json()
        return result
    } catch (error) {
        return rejectWithValue(error);
    }
}
)

export const getSelfReview = createAsyncThunk("getSelfReviewPromise", async (data, { rejectWithValue }) => {
    const response = await fetch(process.env.REACT_APP_NEW_BASE_URL + '/selfReview', {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data)
    })
    try {
        const result = await response.json()
        return result
    } catch (error) {
        return rejectWithValue(error);
    }
}
)

export const getFilterManageEmp = createAsyncThunk("getFilterManageEmpPromise", async (data, { rejectWithValue }) => {
    const response = await fetch(process.env.REACT_APP_NEW_BASE_URL + '/manageEmployeeFilter', {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data)
    })
    try {
        const result = await response.json()
        return result
    } catch (error) {
        return rejectWithValue(error);
    }
}
)


const employeeSlice = createSlice({
    name: "employeeData",
    initialState,
    reducers: {
        setEmpDetails(state, action) {
            state.employeeData = action.payload
        }
    },

    extraReducers: builder => {
        builder
            .addCase(getEmployeeData.pending, (s, a) => {
                s.loading = "pending";
            })
            .addCase(getEmployeeData.rejected, (s, a) => {
                s.loading = "idle";
            })
            .addCase(getEmployeeData.fulfilled, (s, a) => {
                s.employeeData = a.payload
                // s.filteredEmpData = a.payload.data
                s.loading = "success"
            })

            .addCase(getAllTask.pending, (s, a) => {
                s.loading = "pending";
            })
            .addCase(getAllTask.rejected, (s, a) => {
                s.loading = "idle";
            })
            .addCase(getAllTask.fulfilled, (s, a) => {
                s.employeeData = a.payload.data
                s.loading = "success"
            })

            //  ----new chnages 18-07-2023

            // get all employee reviews
            .addCase(getAllReview.pending, (s, a) => {
                s.loading = "pending";
            })
            .addCase(getAllReview.rejected, (s, a) => {
                s.loading = "idle";
            })
            .addCase(getAllReview.fulfilled, (s, a) => {
                s.HrAllEmpTaskReview = a.payload;
                s.loading = 'success';
            })


            // self reviews
            .addCase(getSelfReview.pending, (s, a) => {
                s.loading = "pending";
            })
            .addCase(getSelfReview.rejected, (s, a) => {
                s.loading = "idle";
            })
            .addCase(getSelfReview.fulfilled, (s, a) => {
                s.HrSelfReview = a.payload;
                s.loading = 'success';
            })

            //  ----new chnages 18-07-2023
            // manage employee filter 
            .addCase(getFilterManageEmp.pending, (s, a) => {
                s.loading = "pending";
            })
            .addCase(getFilterManageEmp.rejected, (s, a) => {
                s.loading = "idle";
            })
            .addCase(getFilterManageEmp.fulfilled, (s, a) => {
                s.filterManageEmp = a.payload;
                s.loading = 'success';
            });

    }
})

export const { setEmpDetails } = employeeSlice.actions;
export default employeeSlice.reducer