import axios from 'axios';
import React, { useState } from 'react'
import { Button, Col, Form, InputGroup, Modal, Row, Spinner } from 'react-bootstrap';
import * as Icon from "react-bootstrap-icons";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from "react-select";
import { useSelector } from 'react-redux';
import useGetAssignTo from '../ReuseApi/useGetAssignTo';
import { useNavigate } from 'react-router-dom';
import useGetDeptWiseResource from '../ReuseApi/UseGetDeptWiseAssignTo';

export const AssignAllocatedTaskModel = ({ show, close, data }) => {

  const loginUserData = useSelector((state) => state.loginData.userData)
  const navigate = useNavigate()
  const userDepartment = (loginUserData?.department || []).map(item => ({
    value: item.value,
    label: item.label
  }));

  const userDataDept = loginUserData.department.map((itm) => { return itm.label }).toString()

  const [departmentName, setDepartmentName] = useState([])
  const [assignto, setAssignto] = useState("")
  const[taskdesc,setTaskDesc] = useState("")
  const [taskinitialestihr, setTaskInitialEstimateHr] = useState("");
  const [taskinitialminute,setTaskInitialMinute] = useState("")
  
  // state for error
  const [deprtError, setDeprtError] = useState(false)
  const [assignToError, setAssignToError] = useState(false)
  const[taskdescerr,setTaskDescErr] = useState(false)
  const [taskinitialestihrerror, setTaskInitialEstimateHrError] = useState(false);
  const[taskinitialminuteerr,settaskInitialMinuteErr] = useState(false)

  const handleDepartmentName = (selectedOption) => {
    setDepartmentName(selectedOption);
    setAssignto(null);
  };

  const handleAssignTo = (selectedOption) => {
    setAssignto(selectedOption)
  }

 const AddTaskSuccess = () => {
  toast.success("Assigned Task Successfully", {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 1000,
    onClose: () => {
      setTimeout(() => {
        navigate(`/${loginUserData.resourceType}/employee-task`);
      }, 1000); 
    }
  });
};

  const AlreadyTaskAdd = () => {
    toast.error("Task Already Assigned", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose:1000,
    });
  };

  const AddActualHoursSuccess = () => {
    toast.error("Please Insert less than initial Estimate Hours", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose:1000,
    });
  };

  const InitialHrMinute = () =>{
      toast.error("00 hours and 00 minutes at the same time are not permitted, please choose an acceptable time.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose:1000,
      });
  }


  const handlerValidate = () => {
    let FormValid = true;

    if(taskdesc.length === 0){
       FormValid = false;
       setTaskDescErr(true)
    }
    else if(departmentName.length === 0) {
      FormValid = false;
      setDeprtError(true);
    }
    else if (assignto.length === 0) {
      FormValid = false;
      setAssignToError(true);
    }
    else if(taskinitialestihr.length === 0){
       FormValid = false;
       setTaskInitialEstimateHrError(true)
    }
    else if(taskinitialminute.length === 0){
       FormValid = false;
       settaskInitialMinuteErr(true)
    }
    return FormValid
  }

  const handleSubmit = (e) => {
    setDeprtError(false)
    setAssignToError(false)
    setTaskDescErr(false)
    setTaskInitialEstimateHrError(false)
    settaskInitialMinuteErr(false)
    settaskInitialMinuteErr(false)

    if (handlerValidate()) {
      if(parseFloat(taskinitialestihr) === 0 && parseFloat(taskinitialminute) === 0){
        InitialHrMinute();
      }else{
       const payload = {
        "empId": assignto.value,
        "taskName": data.taskName,
        "taskPriority": data.taskPriority,
        "taskDescription":taskdesc,
        "taskDepartmentId":departmentName.value,
        "taskDepartmentName":departmentName.label,
         "department_id": (loginUserData?.department).some((itm) => itm.label === departmentName.label) ? departmentName.value : loginUserData?.department[0]?.value,
        "departmentName": (loginUserData?.department).some((itm) => itm.label === departmentName.label) ? departmentName.label : loginUserData?.department[0]?.label,
        // "department_id": departmentName.value,
        // "departmentName": departmentName.label,
        "projectId": data.projectname,
        "projectName": data.projectName,
        "categoryOfName": data.categoryOfName,
        "category_id": data.category_id,
        "resource_id": assignto.value,
        "resourceName": assignto.label,
        "assignBy_id": data?.empId,
        "assignBy_Name": `${loginUserData.firstName} ${loginUserData.lastName}`,
        "proposedStartDate": new Date(data.proposedStartDate).toISOString().split('T')[0],
        "proposedEndDate": new Date(data.proposedEndDate).toISOString().split('T')[0],
        "initialEstimateHr": taskinitialestihr,
        "initialEstimateMinutes":taskinitialminute,
        "headTaskId":data.taskId,
        "headTaskName":data.taskName,
        "headTaskDescription":data.taskDescription,
        "headTaskPriority":data.taskPriority,
        "headProjectId":data.projectId,
        "headProjectName":data.projectName
      }
    
      
      axios.post(process.env.REACT_APP_NEW_BASE_URL + '/assignTask', payload)
        .then((res) => {
          // { res.data.message === "Task Assigned Successfully" ? AddTaskSuccess() : AlreadyTaskAdd(); }
          AddTaskSuccess();
          close()
        })
        .catch((err) => {
          console.log("Error", err)
        })
    }
  }
  }

  const payload = {
  "departments": departmentName?.label ? [departmentName.label] : loginUserData.department.map((itm) => itm.label),
  "empId": ""
};
  const urlEndpoint = "/emp/getEmployeeByDepartment" 

  const { allAssignToOptions } = useGetDeptWiseResource(urlEndpoint, payload, departmentName, loginUserData?.department.map((itm) => itm.label))

  return (
    <>
    <ToastContainer/>
     <Modal show={show} onHide={close} size="lg" centered backdrop='static' keyboard={false}>
      <Modal.Header closeButton className="mngmodelheader">
        <Modal.Title><h5>Assign Task</h5></Modal.Title>
      </Modal.Header>
      <Modal.Body size="lg">
        <Row className='mb-4'>
          <Col sm={12} md={6}>
            <Form.Group controlId="formBasicId">
              <Form.Label className="mt-3 fw-bold">
                Task Title
                <span className="requiredfield">*</span>
              </Form.Label>
              <InputGroup>
                <Form.Control
                  placeholder="Task Name"
                  className="input-field project-inputgroup"
                  name="taskName"
                  value={data?.taskName}
                />
              </InputGroup>
            </Form.Group>
          </Col>

          <Col sm={12} md={6}>
            <Form.Group className="">
              <Form.Label className="emp-form-label mt-3 fw-bold">
                Task Priority
                <span className="requiredfield">*</span>
              </Form.Label>
              <InputGroup>
                <Form.Control
                  placeholder="Task Name"
                  className="input-field project-inputgroup"
                  name="taskPriority"
                  value={data?.taskPriority}
                />
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>

        <Row className='mb-4'>
          <Col sm={12} md={12}>
            <Form.Group className="">
              <Form.Label className="mt-3 fw-bold">
                Task Description{" "}
                <span className="requiredfield">*</span>
              </Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder="Task Description"
                  name='taskdesc'
                  value={taskdesc}
                  onChange={(e) =>{setTaskDesc(e.target.value);
                    taskdesc.length >= 0
                      ? setTaskDescErr(false)
                      : setTaskDescErr(true);
                  }}
                />
            </Form.Group>
            {taskdescerr ? (
                <p className="error-padding" style={{ color: "red" }}>
                  <>
                    <Icon.ExclamationTriangle className="me-2" />
                  </>
                  Required Field
                </p>
              ) : null}
          </Col>
        </Row>

        <Row className='mb-4'>
          <Col sm={12} md={6}>
            <Form.Group className="">
              <Form.Label className="mt-3 fw-bold">
                Department Name
                <span className="requiredfield">*</span>
              </Form.Label>
              <Select
                isMulti={false}
                isSearchable={true}
                placeholder="Select Department Name"
                name='departmentName'
                value={departmentName}
                options={userDepartment}
                onChange={(option) => {
                  handleDepartmentName(option);
                  departmentName.length < 0 ?
                    setDeprtError(true) :
                    setDeprtError(false)
                }
                }
              />
              {deprtError ? (
                <p className="error-padding" style={{ color: "red" }}>
                  <>
                    <Icon.ExclamationTriangle className="me-2" />
                  </>
                  Required Field
                </p>
              ) : null}
            </Form.Group>
          </Col>

          <Col sm={12} md={6}>
            <Form.Group className="">
              <Form.Label className="mt-3 fw-bold">
                Project Name
                <span className="requiredfield">*</span>
              </Form.Label>
              <InputGroup>
                <Form.Control
                  name='projectName'
                  value={data?.projectName}
                />
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>

        <Row className='mb-4'>
          <Col sm={12} md={4}>
            <Form.Group className="">
              <Form.Label className="emp-form-label mt-3 fw-bold">
                Category Name
                <span className="requiredfield">*</span>
              </Form.Label>
              <InputGroup>
                <Form.Control
                  name='categoryOfName'
                  value={data?.categoryOfName}
                />
              </InputGroup>
            </Form.Group>
          </Col>

          <Col sm={12} md={4}>
            <Form.Group className="">
              <Form.Label className="mt-3 fw-bold">
                Assign To
                <span className="requiredfield">*</span>
              </Form.Label>
              <Select
                isMulti={false}
                isSearchable={true}
                name='assignto'
                value={assignto}
                options={allAssignToOptions}
                onChange={(option) => {
                  handleAssignTo(option);
                  assignto?.length < 0 ?
                    setAssignToError(true) :
                    setAssignToError(false)
                }}
              />
              {assignToError ? (
                <p className="error-padding" style={{ color: "red" }}>
                  <>
                    <Icon.ExclamationTriangle className="me-2" />
                  </>
                  Required Field
                </p>
              ) : null}
            </Form.Group>
          </Col>

          <Col sm={12} md={4}>
            <Form.Group controlId="formBasicId">
              <Form.Label className="mt-3 fw-bold">
                Assign By
              </Form.Label>
              <Form.Control
                name='assignBy_Name'
                value={`${loginUserData?.firstName} ${loginUserData?.lastName}`} readOnly
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className='mb-4'>
          <Col sm={12} md={3}>
            <Form.Group controlId="formBasiContact" className="w-100">
              <Form.Label className="mt-3 fw-bold">
                Proposed Start date{" "}
                <span className="requiredfield">*</span>
              </Form.Label>
              <InputGroup className="mb-2">
                <Form.Control
                  className=""
                  // type="date"
                  name="proposedStartDate"
                  value={data?.proposedStartDate ? new Date(data?.proposedStartDate).toISOString().split("T")[0] : ""}
                />
              </InputGroup>
            </Form.Group>
          </Col>

          <Col sm={12} md={3}>
            <Form.Group controlId="formBasiContact" style={{ width: "100%" }}>
              <Form.Label className="mt-3 fw-bold">
                Proposed End date{" "}
                <span className="requiredfield">*</span>
              </Form.Label>
              <InputGroup className="mb-2">
                <Form.Control
                  className=""
                  // type="date"
                  name='proposedEndDate'
                  value={data?.proposedEndDate ? new Date(data?.proposedEndDate).toISOString().split("T")[0] : ""}
                />
              </InputGroup>
            </Form.Group>
          </Col>

          <Col sm={12} md={3}>
            <Form.Group controlId="formBasiContact" style={{ width: "100%" }}>
              <Form.Label className="mt-3 fw-bold">
                Initial Estimate(Hours){" "}
                <span className="requiredfield">*</span>
              </Form.Label>
                <Form.Control
                  type="text"
                  name="initialEstimateHr"
                  value={taskinitialestihr}
                   onChange={(e) => {
                    if(/^[0-9]*(\.[0-9]*)?$/.test(e.target.value) && e.target.value.length <= 4){
                        setTaskInitialEstimateHr(e.target.value);
                      } else if (e.target.value.length === 0) {
                        setTaskInitialEstimateHr(e.target.value);
                      }
                    taskinitialestihr?.length < 0
                      ? setTaskInitialEstimateHrError(true)
                      : setTaskInitialEstimateHrError(false);
                  }}
                />
            </Form.Group>
            {taskinitialestihrerror ? (
                <p className="errMsg">
                  <>
                    <Icon.ExclamationTriangle className="me-2" />
                  </>
                  Required Field
                </p>
              ) : null}
          </Col>

          
          <Col sm={12} md={3}>
            <Form.Group controlId="formBasiContact" style={{ width: "100%" }}>
              <Form.Label className="mt-3 fw-bold">
                Initial Minutes
                <span className="requiredfield">*</span>
              </Form.Label>
                <Form.Control
                  type="text"
                  className=""
                  name="initialEstimateMinutes"
                  value={taskinitialminute}
                  onChange={(e) => {
                    if(/^[0-9]{0,2}$/.test(e.target.value) && e.target.value >= 0 && e.target.value <= 59){
                        setTaskInitialMinute(e.target.value);
                      } else if (e.target.value > 59) {
                         toast.error('Please enter a value between 0 and 59.')}
                        else if (e.target.value.length === 0) {
                        setTaskInitialMinute(e.target.value);
                      }
                     taskinitialminute?.length < 0
                      ? settaskInitialMinuteErr(true)
                      : settaskInitialMinuteErr(false);
                  }}
                />
            </Form.Group>
            {taskinitialminuteerr ? (
                <p className="errMsg">
                  <>
                    <Icon.ExclamationTriangle className="me-2" />
                  </>
                  Required Field
                </p>
              ) : null}
          </Col>
        </Row>

      </Modal.Body>

      <Modal.Footer className="text-center mt-4">
        <Button className="primaryBtn "
          onClick={() => { handleSubmit() }}
        >
          <Icon.PersonAdd className='me-2' /> Create Task
        </Button>

      </Modal.Footer>

    </Modal>
  </>
  )
   
}
