import axios from 'axios'
import React, { useEffect, useState } from 'react'

const useGetAllSkills = (payload, department) => {

    const [allSkillOptions, setAllSkillOptions] = useState([])

    useEffect(() => {
        try {
            
            axios.post(process.env.REACT_APP_BASE_URL + '/skill/getDepartmentwiseSkill', payload)
                .then((res) => {
                    const filterOptions = (res?.data?.data)?.length > 0 && (res?.data?.data)?.map((itm) => {
                        return ({ value: itm.skillName, label: itm.skillName })
                    })

                    if (filterOptions?.length > 0) {
                        setAllSkillOptions([{ value: "All", label: "All" }, ...filterOptions])
                    }
                })
                .catch((err) => {
                    console.log("Error", err)
                })
        } catch (error) {
            console.log("Error", error)
        }
    }, [department])



    return { allSkillOptions }
}

export default useGetAllSkills