import React, { useEffect } from "react";


// Importing Commons -------
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollToTop from './Features/Common/ScrollToTop';

// Importing Auth's --------
import Login from "./Features/Auth/Login/Login";

// Importing Layout's -------


//-------------- EMPLOYEE -------------------
import CommonLayout from "./Layout/CommonLayout";
import EmpDash from "./Features/Employee/EmpDash";
import { useSelector } from "react-redux";
import Register from "./Features/Auth/Login/Register";
import ShowAllTask from "./Features/Common/ShowAllTask";
import Employees from "./Features/HR/Employees";
import ViewTaskDetails from "./Features/Common/ViewTaskDetails";
import AssignTask from "./Features/Common/AssignTask";
import NoTask from "./Features/Common/NoTask";
import EmpDetails from "./Features/Common/EmpDetails";
import SelfAllTask from "./Features/Common/SelfAllTask";
import ViewSelfTaskDetails from "./Features/Common/ViewSelfTaskDetails";
import { AllDashboard } from "./Features/Common/AllDashboard";
import Review from "./Features/Manager/Review";
import SelfReview from "./Features/Manager/SelfReview";
import TlReview from "./Features/TeamLead/TlReview";
import TlSelfReview from "./Features/TeamLead/TlSelfReview";
import HrReview from "./Features/HR/HrReview";
import HrSelfReview from "./Features/HR/HrSelfReview";
import EmpSelfReview from "./Features/Employee/EmpSelfReview";
import EmployeeTask from "./Features/Employee/EmployeeTask";
import MngManageEmp from "./Features/Manager/MngManageEmp";
import TLManageEmp from "./Features/TeamLead/TLManageEmp";
import Profile from "./Features/Common/Profile";
import ForgotPass from "./Features/Auth/ForgotPass";
import Client from "./Features/Admin/Client";
import Project from "./Features/Admin/Project";
import AdminReview from "./Features/Admin/AdminReview";
import AdminSelfReview from "./Features/Admin/AdminSelfReview";
import EmployeeTasks from "./Features/Common/EmployeeTasks";
import AllocatedTask from "./Features/Common/AllocatedTask"
import ResourceUtilization from "./Features/Common/ResourceUtilization";
import PrivateRoutes from "./Features/Auth/PrivateRoutes";
import ViewAllocatedTaskDetails from "./Features/Common/ViewAllocatedTaskDetails";
import HrReportGenerate from "./Features/HR/HrReportGenerate";
import ToDoList from "./Features/Common/ToDoList/ToDoList";

function App() {

  const userState = useSelector(state => state.loginData.userData);
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path='/' element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgot-pasword" element={<ForgotPass />} />
           <Route path="*" element={<Login />} />

          {
            // for employee
            userState?.resourceType === "employee" && 
              <Route path='/employee' element={<CommonLayout />}>
                <Route path='/employee' element={<EmployeeTask />} />
                <Route path='/employee/dashboard' element={<EmpDash />} />
                <Route path='/employee/review' element={<EmpSelfReview />} />
                <Route path='/employee/all-task/view-details' element={<ViewTaskDetails />} />
                <Route path='/employee/profile' element={<Profile />} />
              </Route>
            }

             {
              // for intern
             userState?.resourceType === "intern" && 
              <Route path='/intern' element={<CommonLayout />}>
                <Route path='/intern' element={<EmployeeTask />} />
                <Route path='/intern/dashboard' element={<EmpDash />} />
                <Route path='/intern/review' element={<EmpSelfReview />} />
                <Route path='/intern/all-task/view-details' element={<ViewTaskDetails />} />
                <Route path='/intern/profile' element={<Profile />} />
              </Route>
            }

            {
              // for contractual
            userState?.resourceType === "contractual" &&
              <Route path='/contractual' element={<CommonLayout />}>
                <Route path='/contractual' element={<EmployeeTask />} />
                <Route path='/contractual/dashboard' element={<EmpDash />} />
                <Route path='/contractual/review' element={<EmpSelfReview />} />
                <Route path='/contractual/all-task/view-details' element={<ViewTaskDetails />} />
                <Route path='/contractual/profile' element={<Profile />} />
              </Route>
            }

          {
            userState?.resourceType === "teamlead" &&
            <Route path='/teamlead' element={<CommonLayout />}>
              <Route path='/teamlead' element={<ShowAllTask />} />
              <Route path="/teamlead/dashboard" element={<AllDashboard />} />
              <Route path='/teamlead/all-task' element={<ShowAllTask />} />
              <Route path='/teamlead/self-task' element={<SelfAllTask />} />
              <Route path='/teamlead/all-task/view-details' element={<ViewTaskDetails />} />
              <Route path='/teamlead/self-task/view-details' element={<ViewSelfTaskDetails />} />
              <Route path='/teamlead/allocated-task/view-details' element={<ViewAllocatedTaskDetails />} />
              <Route path='/teamlead/assign-task' element={<AssignTask />} />
              <Route path='/teamlead/allocated-task' element={<AllocatedTask />} />
              <Route path='/teamlead/employee-task' element={<EmployeeTasks />} />
              <Route path='/teamlead/no-tasks' element={<NoTask />} />
              <Route path='/teamlead/resourceutilize' element={<ResourceUtilization />} />
              <Route path='/teamlead/review' element={<TlReview />} />
              <Route path='/teamlead/self-review' element={<TlSelfReview />} />
              <Route path="/teamlead/employee/employee-details" element={<EmpDetails />} />
              <Route path="/teamlead/employee" element={<TLManageEmp />} />
              <Route path="/teamlead/profile" element={<Profile />} />
              <Route path="/teamlead/todolist" element = {<ToDoList/>} />
            </Route>
          }

          {
            userState?.resourceType === "manager" &&
            <Route path='/manager' element={<CommonLayout />}>
              <Route path="/manager/dashboard" element={<AllDashboard />} />
              <Route path='/manager' element={<ShowAllTask />} />
              <Route path='/manager/all-task' element={<ShowAllTask />} />
              <Route path='/manager/self-task' element={<SelfAllTask />} />
              <Route path='/manager/all-task/view-details' element={<ViewTaskDetails />} />
              <Route path='/manager/self-task/view-details' element={<ViewSelfTaskDetails />} />
              <Route path='/manager/allocated-task/view-details' element={<ViewAllocatedTaskDetails />} />
              <Route path='/manager/assign-task' element={<AssignTask />} />
              <Route path='/manager/employee-task' element={<EmployeeTasks />} />
              <Route path="/manager/allocated-task" element={<AllocatedTask />} />
              <Route path='/manager/no-tasks' element={<NoTask />} />
              <Route path='/manager/resourceutilize' element={<ResourceUtilization />} />
              <Route path='/manager/review' element={<Review />} />
              <Route path='/manager/self-review' element={<SelfReview />} />
              <Route path="/manager/employee/employee-details" element={<EmpDetails />} />
              <Route path="/manager/employee" element={<MngManageEmp />} />
              <Route path="/manager/profile" element={<Profile />} />
            </Route>
          }


          {
            userState?.resourceType === "admin" &&
            <Route path='/admin' element={<CommonLayout />}>
              <Route path="/admin/dashboard" element={<AllDashboard />} />
              <Route path='/admin' element={<ShowAllTask />} />
              <Route path='/admin/all-task' element={<ShowAllTask />} />
              <Route path='/admin/self-task' element={<SelfAllTask />} />
              <Route path="/admin/all-task/view-details" element={<ViewTaskDetails />} />
              <Route path='/admin/self-task/view-details' element={<ViewSelfTaskDetails />} />
               <Route path='/admin/allocated-task/view-details' element={<ViewAllocatedTaskDetails />} />
              <Route path="/admin/employee/employee-details" element={<EmpDetails />} />
              <Route path="/admin/employee" element={<Employees />} />
              <Route path='/admin/resourceutilize' element={<ResourceUtilization />} />
              <Route path="/admin/review" element={<AdminReview />} />
              <Route path='/admin/self-review' element={<AdminSelfReview />} />
              <Route path="/admin/assign-task" element={<AssignTask />} />
              <Route path='/admin/employee-task' element={<EmployeeTasks />} />
              <Route path="/admin/" element={<AllocatedTask />} />
              <Route path="/admin/project" element={<Project />} />
              <Route path="/admin/client" element={<Client />} />
              <Route path="/admin/profile" element={<Profile />} />
            </Route>
          }
          {
            userState?.resourceType === "humanResource" &&
            <Route path='/humanResource' element={<CommonLayout />}>
              <Route path="/humanResource/dashboard" element={<AllDashboard />} />
              <Route path='/humanResource' element={<ShowAllTask />} />
              <Route path='/humanResource/all-task' element={<ShowAllTask />} />
              <Route path='/humanResource/self-task' element={<SelfAllTask />} />
              <Route path="/humanResource/all-task/view-details" element={<ViewTaskDetails />} />
              <Route path='/humanResource/self-task/view-details' element={<ViewSelfTaskDetails />} />
              <Route path='/humanResource/allocated-task/view-details' element={<ViewAllocatedTaskDetails />} />
              <Route path="/humanResource/employee/employee-details" element={<EmpDetails />} />
              <Route path="/humanResource/employee" element={<Employees />} />
              <Route path='/humanResource/resourceutilize' element={<ResourceUtilization />} />
              <Route path="/humanResource/review" element={<HrReview />} />
              <Route path='/humanResource/self-review' element={<HrSelfReview />} />
              <Route path="/humanResource/assign-task" element={<AssignTask />} />
              <Route path='/humanResource/employee-task' element={<EmployeeTasks />} />
              <Route path="/humanResource/allocated-task" element={<AllocatedTask />} />
              <Route path="/humanResource/profile" element={<Profile />} />
              <Route path="/humanResource/report" element = {<HrReportGenerate/>} />
            </Route>
          }

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
