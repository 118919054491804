import EmpDash from '../Employee/EmpDash';
import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import DashCards from '../Common/DashCards';
import MultibarChart from '../Charts/MultibarCharts';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Select from "react-select";
import { setActiveDashToggle } from './AllTaskSlice';
import { HrAllDashboard } from '../HR/HrAllDashboard';
import HorizontalChart from '../Charts/HorizontalChart';
import MultiLineChart from '../Charts/MultiLineChart';


export const  AllDashboard = () => {

  const dispatch = useDispatch();

    const userState = useSelector(state => state.loginData.userData);
    const currentDashToggleTab= useSelector((state)=> state?.AllTasks?.ActiveDashToggle);

    const EmpId = userState?.empId
    const departmentData = userState.department.map((itm) => {
                          return {label: itm.label,value: itm.value};
    });

    const departmentDatas = userState.department.map((itm) => {return itm.label});
    const toggle = [{ name: 'Self Dashboard', value: 'Self Dashboard' }, { name: 'All Dashboard', value: 'All Dashboard' }];
  
    // ---------------------- STATES & API TOGETHER --------------------------------
    // ---- FILTER States -----
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
  
    // All dashboard graph
    const [dept, setDept] = useState([]);
    const [lead, setLead] = useState("")
    const [empList, SetEmpList] = useState("")
    const [projectLabel,setProjectLabel] = useState()
    const [projectData, setProjectData] = useState();
    const [categoryLable,setCategoryLable] = useState()
    const [categoryData, setCategoryData] = useState()
    const [projectTimeLable,setProjectTimeLable] = useState()
    const [projectWiseTime, setProejctWiseTime] = useState()
    const [resourceLable, setResourceLable] = useState()
    const [resourceSlippage,setResourceSlippage] = useState()
    const [projectSlippage, setProjectSlippage] = useState()
    const [projectSlippageLable ,setProjeSlippageLable] = useState()
    const [resource, setResource] = useState([]);


    const [resourceQwdData,setResourceQwdData] = useState()
    const [resourceQwdLable,setResourceQwdLable] = useState()

    const [resourceIdcData,setResourceIdcData] = useState()
    const [resourceIdcLable,setResourceIdcLable] = useState()

    const [projectQwdData,setProjectQwdData] = useState()
    const [projectQwdLable,setProjectQwdLable] = useState()

    const [projectIdcData,setProjectIdcData] = useState()
    const [projectIdcLable,setProjectIdcLable] = useState()

    const getProjectWiseEstimatedVsActualTimespentData = async() => {
      try {
        const payload = {
          "empId": EmpId,
          "department": dept?.label !== 'All' ? dept.label : "",
          "resource": resource.value !== 'All' ? resource.value : "",
          "lead": lead?.label !== 'All' ? lead.label : "",
          "fromDate": startDate ? startDate : "",
          "toDate": endDate ? endDate : "",
        };
        await axios
          .post(process.env.REACT_APP_BASE_URL + "/graph/projectWiseChartAndTimeTL", payload)
          .then((res) => {
            setProjectLabel(res.data.data);

            let projectsArrayData = [];

            projectsArrayData.push({
              type: "bar",
              label: "Actual Hours",
              fill: true,
              data: res.data.data,
              backgroundColor: "#4E79A7",
            });

            projectsArrayData.push({
              type: "bar",
              label: "Estimate Hours",
              fill: true,
              data: res.data.data,
              backgroundColor: "#F28E2B",
            });
            setProjectData(projectsArrayData);
          })
          .catch((err) => console.log("Error", err));
      } catch (error) {
        console.log("Error", "Something went wrong...!")
      }
    }
  
    const getWorkCategoryWiseTimeSpentData = async() => {
      try {
        const payload = {
          "empId": EmpId,
          "department": dept?.label !== 'All' ? dept.label : "",
          "resource": resource.value !== 'All' ? resource.value : "",
          "lead": lead?.label !== 'All' ? lead.label : "",
          "fromDate": startDate ? startDate : "",
          "toDate": endDate ? endDate : "",
        };
        await axios
          .post(process.env.REACT_APP_BASE_URL + "/graph/workCategoryWiseTimeSpendTL", payload)
          .then((res) => {
            setCategoryLable(res.data.data)

            let projectsArrayData = [];

            projectsArrayData.push({
              type: "bar",
              label: "Categories",
              fill: true,
              data: res.data.data,
              backgroundColor: "#4E79A7",
            });
            setCategoryData(projectsArrayData)
          })
          .catch((err) => console.log("Error", err));
      } catch (error) {
        console.log("Error", "Something went wrong...!")
      }
    }
  
    const getProjectwiseTimeSpentData = async () => {
      try {
        const payload = {
          "empId": EmpId,
          "department": dept?.label !== 'All' ? dept.label : "",
          "resource": resource.value !== 'All' ? resource.value : "",
          "lead": lead?.label !== 'All' ? lead.label : "",
          "fromDate": startDate ? startDate : "",
          "toDate": endDate ? endDate : "",
        };
        await axios
          .post(process.env.REACT_APP_BASE_URL + "/graph/projectWiseTimeSpentTL", payload)
          .then((res) => {
            setProjectTimeLable(res.data.data)

            let projectsArrayData = [];

            projectsArrayData.push({
              type: "bar",
              label: "Projects",
              fill: true,
              data: res.data.data,
              backgroundColor: "#F28E2B",
            });
            setProejctWiseTime(projectsArrayData)
          })
          .catch((err) => console.log("Error", err));
      } catch (error) {
        console.log("Error", "Something went wrong...!")
      }
    }

    const getResourceWiseSlippageData = async () => {
      try {
        const payload = {
          "empId": EmpId,
          "department": dept?.label !== 'All' ? dept.label : "",
          "resource": resource.value !== 'All' ? resource.value : "",
          "lead": lead?.label !== 'All' ? lead.label : "",
          "fromDate": startDate ? startDate : "",
          "toDate": endDate ? endDate : "",
        };
       
        await axios
          .post(process.env.REACT_APP_BASE_URL + "/graph/resourceWiseSlippageTL", payload)
          .then((res) => {
            setResourceLable(res.data.data)
            let projectsArrayData = [];

            projectsArrayData.push({
              type: "bar",
              label: "Resource",
              fill: true,
              data: res.data.data,
              backgroundColor: "#4E79A7",
            });

            setResourceSlippage(projectsArrayData)
          })
          .catch((err) => console.log("Error", err));
      } catch (error) {
        console.log("Error", "Something went wrong...!")
      }
    }
  
    const getProjectWiseSlippageData = useCallback(async () => {
      try {
        const payload = {
          "empId": EmpId,
          "department": dept?.label !== 'All' ? dept.label : "",
          "resource": resource.value !== 'All' ? resource.value : "",
          "lead": lead?.label !== 'All' ? lead.label : "",
          "fromDate": startDate ? startDate : "",
          "toDate": endDate ? endDate : "",
        };

        await axios
          .post(process.env.REACT_APP_BASE_URL + "/graph/projectWiseSlippageTL", payload)
          .then((res) => {
            setProjeSlippageLable(res.data.data)

            let projectsArrayData = [];

            projectsArrayData.push({
              type: "bar",
              label: "Projects",
              fill: true,
              data: res.data.data,
              backgroundColor: "#F28E2B",
            });

            setProjectSlippage(projectsArrayData)
          })
          .catch((err) => console.log("Error", err));
      } catch (error) {
        console.log("Error", "Something went wrong...!")
      }
    })
  
    const getResourceWiseQWCData = async () => {
      try {
        const payload = {
          "empId": EmpId,
          "department": dept?.label !== 'All' ? dept.label : "",
          "resource": resource.value !== 'All' ? resource.value : "",
          "lead": lead?.label !== 'All' ? lead.label : "",
          "fromDate": startDate ? startDate : "",
          "toDate": endDate ? endDate : "",
        };
        await axios
          .post(process.env.REACT_APP_BASE_URL + "/graph/resourceWiseQWDTL", payload)
          .then((res) => {
            setResourceQwdLable(res.data.data)

            let projectsArrayData = [];

            projectsArrayData.push({
              type: "bar",
              label: "Resource",
              fill: true,
              data: res.data.data,
              backgroundColor: "#4E79A7",
            });

            setResourceQwdData(projectsArrayData)
          })
          .catch((err) => console.log("Error", err));
      } catch (error) {
        console.log("Error", "Something went wrong...!")
      }
    }
  
    const getProjectWiseResourceQWDComparisonData = useCallback(async () => {
      try {
        const payload = {
          "empId": EmpId,
          "department": dept?.label !== 'All' ? dept.label : "",
          "resource": resource.value !== 'All' ? resource.value : "",
          "lead": lead?.label !== 'All' ? lead.label : "",
          "fromDate": startDate ? startDate : "",
          "toDate": endDate ? endDate : "",
        };

        await axios
          .post(process.env.REACT_APP_BASE_URL + "/graph/projectWiseQWDTL", payload)
          .then((res) => {
            setProjectQwdLable(res.data.data)
            let projectsArrayData = [];

            projectsArrayData.push({
              type: "bar",
              label: "Project",
              fill: true,
              data: res.data.data,
              backgroundColor: "#F28E2B",
            });

            setProjectQwdData(projectsArrayData)
          })
          .catch((err) => console.log("Error", err));
      } catch (error) {
        console.log("Error", "Something went wrong...!")
      }
    })
  
    const getResourceWiseIDCData = async() => {
      try {
        const payload = {
          "empId": EmpId,
          "department": dept?.label !== 'All' ? dept.label : "",
          "resource": resource.value !== 'All' ? resource.value : "",
          "lead": lead?.label !== 'All' ? lead.label : "",
          "fromDate": startDate ? startDate : "",
          "toDate": endDate ? endDate : "",
        };
        await axios
          .post(process.env.REACT_APP_BASE_URL + "/graph/resourceWiseIDCTL", payload)
          .then((res) => {
           
            setResourceIdcLable(res.data.data)
            let projectsArrayData = [];

            projectsArrayData.push({
              type: "bar",
              label: "Resource",
              fill: true,
              data: res.data.data,              
              backgroundColor: "#4E79A7",
            });

            setResourceIdcData(projectsArrayData)
          })
          .catch((err) => console.log("Error", err));
      } catch (error) {
        console.log("Error", "Something went wrong...!")
      }
    }

    const getProjectWiseResourceIDCComparisonData = async () => {
      try {
        const payload = {
          "empId": EmpId,
          "department": dept?.label !== 'All' ? dept.label : "",
          "resource": resource.value !== 'All' ? resource.value : "",
          "lead": lead?.label !== 'All' ? lead.label : "",
          "fromDate": startDate ? startDate : "",
          "toDate": endDate ? endDate : "",
        };
        await axios
          .post(process.env.REACT_APP_BASE_URL + "/graph/projectWiseIDCTL", payload)
          .then((res) => {
            setProjectIdcLable(res.data.data)
            let projectsArrayData = [];

            projectsArrayData.push({
              type: "bar",
              label: "Project",
              fill: true,
              data: res.data.data,
              backgroundColor: "#F28E2B",
            });

            setProjectIdcData(projectsArrayData)
          })
          .catch((err) => console.log("Error", err));
      } catch (error) {
        console.log("Error", "Something went wrong...!")
      }
    }

    // for cards api
    const [cardApiRes, setCardApiRes] = useState();
    const baseUrl = userState.resourceType === "humanResource"
      ? process.env.REACT_APP_BASE_URL + "/HRDashboard/HRDashboardAPI"
      : process.env.REACT_APP_BASE_URL + "/TLDashboard/teamLeadDashboardAPI";

      const [payload, setPayload] = useState({
        empId: EmpId,
        fromDate: startDate ? startDate : "",
        toDate: endDate ? endDate : "",
        lead: lead.label ? lead.label : "",
        resource:resource.value !== "All" ? resource.value : "",
        taskDepartmentName: dept?.label ? dept?.label : "",
      });

    const totalCardsApis = async () => {
      await axios.post(baseUrl, payload)
      .then((res) => {
      setCardApiRes(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
    };

    useEffect(() => {
       totalCardsApis()
    },[startDate,endDate,lead,resource,EmpId,dept])

    // get departmentwise TL
    const [teamLeadList,setTeamLeadList] = useState([]);

    const getTLbyDept = async () => {
      const payload = {
        // departmentName:  dept ? dept.label : departmentDatas
        departmentName:  dept.label === "All" ? departmentDatas : dept.label ,
      };
      await axios.post(process.env.REACT_APP_BASE_URL  + "/department/getDepartmentTL", payload)
        .then((res) => {
          setTeamLeadList(res.data.data);
        })
        .catch((error) => {
          console.log(error);
      });
  };

 const leadName = teamLeadList && Array.isArray(teamLeadList)
  ? teamLeadList.map((itm) => {
      return {
        label: itm.teamLeadName,
        value: itm.teamLeadId,
      };
    })
  : [];


  const getEmpByManagerId = async (e) => {
    const payload = {
      empIdTL: lead ? lead.value : EmpId,
    };
    await axios
      .post(process.env.REACT_APP_BASE_URL + "/emp/getParticularTlEmployee", payload)
      .then((res) => {
        SetEmpList(res.data.data)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const departmentDataWithAll = [
    { label: "All", value: "All" },
    ...departmentData, 
  ];

// for resource list
const resourceList = empList
  ? empList
      .filter((itm) => itm.empId !== (lead ? lead.value : ''))
      .map((itm) => ({
        label: `${itm.firstName} ${itm.lastName}`,
        value: itm.empId,
      }))
  : [];
const resourceData = [
  { label: "All", value: "All" },
  ...resourceList,
];


  useEffect(()=>{
    setResource("")
    getEmpByManagerId()
  },[lead])

  useEffect(()=>{
    setLead("")
    setResource("")
    getTLbyDept()
  },[dept]);
  
  useEffect(()=>{
    if(userState){
        getProjectWiseEstimatedVsActualTimespentData()
        getWorkCategoryWiseTimeSpentData()
        getProjectwiseTimeSpentData()
        getResourceWiseSlippageData()
        getProjectWiseSlippageData()
        getResourceWiseQWCData()
        getProjectWiseResourceIDCComparisonData()
        getProjectWiseResourceQWDComparisonData()
        getResourceWiseIDCData()
    }
  },[startDate,endDate,lead,resource,EmpId,dept])
  
    return (
      <div className='EmpDash outletPadding' data-aos='fade-up'>
        <Container>
        {userState.resouceType !== "employee" && 
        <Row className='d-flex justify-content-end align-items-center'>
          <Col sm={12} md={12} className='d-flex justify-content-end align-items-center'>
            <ButtonGroup>
                {toggle.map((radio, idx) => (
                  <ToggleButton
                    key={idx}
                    id={`radio-${idx}`}
                    type="radio"
                    variant={idx % 2 ? 'outline-success' : 'outline-danger'}
                    name="radio"
                    value={radio.value}
                    checked={currentDashToggleTab === radio.value }
                    onChange={(e) => {dispatch(setActiveDashToggle(e.currentTarget.value)); }}
                  >
                    {radio.name}
                </ToggleButton>
                            ))}
            </ButtonGroup>
          </Col>
        </Row>}
        
            {
              currentDashToggleTab === "Self Dashboard"
              ?
              <EmpDash/>
              :
              <>
              {userState?.resourceType === "humanResource" && currentDashToggleTab === "All Dashboard"  ? <HrAllDashboard/> : 
              <>
              <Row className='justify-content-between w-100 mt-2'>
                {userState?.resourceType !== "teamlead" && <>
                  <Col className='mb-4 mb-md-0'>
                  <Form.Group>
                    <Form.Label> Department </Form.Label>
                    <Select
                        isMulti={false}
                        isSearchable={true}
                        value={dept}
                        options={departmentDataWithAll}
                        onChange={(selectedOption) => {
                        if (selectedOption && selectedOption.value === "All") {
                          setDept(selectedOption);
                          setLead("");
                          setResource("");
                          // Set the 'taskDepartmentName' in the 'payload' to an empty string when "All" is selected
                          setPayload((prevPayload) => ({ ...prevPayload, taskDepartmentName: "" , lead:"" , resource:"" }));
                        } else {
                          setDept(selectedOption);
                          setLead("");
                          setResource("");
                          // Set the 'taskDepartmentName' in the 'payload' to the selected department label
                          setPayload((prevPayload) => ({ ...prevPayload, taskDepartmentName: selectedOption.label }));
                        }

                        totalCardsApis();
                      }}
                                  />
                  </Form.Group>
                  </Col>
      
                  <Col className='mb-4 mb-md-0'>
                  <Form.Group>
                    <Form.Label> Lead/Manager </Form.Label>
                    <Select
                        isMulti={false}
                        isSearchable={true}
                        value={lead}
                        options = {leadName}
                        onChange={(selectedOption)=>{setPayload((prevPayload) => ({ ...prevPayload, lead: selectedOption.label }));setLead(selectedOption);setResource("")}}
                  />
                  </Form.Group>
                  </Col>
                  </> 
                }
                <Col className='mb-4 mb-md-0'>
                <Form.Group>
                  <Form.Label>Resource</Form.Label>
                  <Select
                    isMulti={false}
                    isSearchable={true}
                    value={resource}
                    options={userState?.resourceType === "teamlead" ? resourceData : lead ? resourceData : []}
                    onChange={(selectedOption) => {
                        if (selectedOption && selectedOption.value === "All") {
                          setResource(selectedOption);
                          // setPayload((prevPayload) => ({ ...prevPayload, resource: selectedOption.value !== "All"? selectedOption.value : " " }));
                          setPayload((prevPayload) => ({ ...prevPayload, resource: "" }));
                          totalCardsApis();
                        } 
                        else {
                          setResource(selectedOption);
                          setPayload((prevPayload) => ({ ...prevPayload, resource: selectedOption.value }));
                          totalCardsApis();
                        }

                      }}
                  />
                </Form.Group>
                </Col>

              <Col md={4} className='d-flex justify-content-end mt-0 mt-mb-0'>
              <Row style={{ width: '100%' }}>
                <Col md={6} sm ={12} className='ps-0 mb-2 mb-md-0 '>
                  <Form.Group className='w-100 THIS'>
                    <Form.Label> From </Form.Label>
                    <Form.Control type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                  </Form.Group>
                </Col>
  
                <Col md={6} sm={12} className='ps-0'>
                  <Form.Group>
                    <Form.Label> To </Form.Label>
                    <Form.Control type="date" value={endDate} min={startDate} onChange={(e) => setEndDate(e.target.value)} />
                  </Form.Group>
                </Col>
              </Row>
              </Col>
              </Row>

              {/* <DashCards totalPro={cardApiRes ? cardApiRes.totalProjects : 0} 
                        totalEst={cardApiRes ? cardApiRes.totalEstimateHrs : 0}
                        totalActTime={cardApiRes ? cardApiRes.totalActualHrs : 0} 
                        slippage={cardApiRes?.slippage ? cardApiRes?.slippage.toFixed(2) : 0}
                        totalTask={cardApiRes?.totalTasks ? cardApiRes?.totalTasks : 0} 
                        efficiency={cardApiRes?.efficiency ? cardApiRes?.efficiency : 0}
                        totalPendingTask={cardApiRes?.totalPendingTask ? cardApiRes?.totalPendingTask : 0}
                        totalCompTask={cardApiRes?.totalCompletedTask ? cardApiRes?.totalCompletedTask : 0}
                        avgQWD={isNaN(Number(cardApiRes?.avgQWD)) ? 0 : Number(cardApiRes?.avgQWD).toFixed(2)}
                        avgIDC={isNaN(Number(cardApiRes?.avgIDC)) ? 0 : Number(cardApiRes?.avgIDC).toFixed(2)}
                        totalResource={cardApiRes? cardApiRes?.totalResources: 0} 
                        totalProj={cardApiRes ? cardApiRes?.totalProjects : 0} /> */}


                      <DashCards
                          totalPro={cardApiRes?.totalProjects || 0}
                          totalEst={cardApiRes?.totalEstimatedHrs?.toFixed(2) || 0}
                          totalActTime={cardApiRes?.totalActualHrs?.toFixed(2) || 0}
                          slippage={cardApiRes?.slippage?.toFixed(2) || 0}
                          totalTask={cardApiRes?.totalTasks || 0}
                          efficiency={cardApiRes?.efficiency?.toFixed(2) || 0}
                          totalPendingTask={cardApiRes?.totalPendingTask || 0}
                          totalCompTask={cardApiRes?.totalCompletedTask || 0}
                          avgQWD={isNaN(Number(cardApiRes?.avgQWD)) ? 0 : Number(cardApiRes?.avgQWD)?.toFixed(2) || 0}
                          avgIDC={isNaN(Number(cardApiRes?.avgIDC)) ? 0 : Number(cardApiRes?.avgIDC)?.toFixed(2) || 0}
                        />
              <Row className='mt-5'>
                {projectData !== undefined && projectData !== null && projectData.length > 0 ? (
                  <MultiLineChart chartData={projectLabel} label="_id" value1 = "totalEstimateHrs" value2 = "totalActualHrs"
                    chartHeading='Project Wise Estimated Vs Actual Time Spent (In Hrs)' indexAxis = "x" chartHeight={600} color1 = {"#4E79A7"} color2 = {"#F28E2B"}/>
                )
              : ("")}
              </Row>
  
              <Row className='mt-5'>
                <Col md={6} className='px-3 mb-4 mb-md-0' >
                {categoryData !== undefined && categoryData !== null && categoryData.length > 0 ? (
                   <HorizontalChart chartData={categoryLable} chartHeading="Work Category Wise Time Spent (In Hrs)" chartHeight={600} label="_id" value="totalActualHrs" color = {"#4E79A7"} />)
                : (
                "")}
              </Col>
  
              <Col md={6} className='px-3 mb-4 mb-md-0' >
                {projectWiseTime !== undefined && projectWiseTime !== null && projectWiseTime.length > 0 ? (
                  <HorizontalChart chartData={projectTimeLable} chartHeading="Project Wise Time Spent (In Hrs)" chartHeight={600} label="_id" value="totalActualHrs" color = {"#F28E2B"} />)
                : (
                "")}
              </Col>
              </Row>

              <Row className='mt-4 mb-4'>
              <Col md={6} className='px-3 mb-4 mb-md-0' >
                {resourceSlippage !== undefined && resourceSlippage !== null && resourceSlippage.length > 0 ? (
                  <HorizontalChart chartData={resourceLable} chartHeading="Resource Wise Slippage" chartHeight={600} label="_id" value="slippage" color = {"#F28E2B"} />
                ) : (
                "")}
              </Col>

              <Col md={6} className='px-3 mb-4 mb-md-0' >
              {projectSlippage !== undefined && projectSlippage !== null && projectSlippage.length > 0 ? (
                <HorizontalChart chartData={projectSlippageLable} chartHeading="Project Wise Slippage" chartHeight={600} label="_id" value="slippage" color = {"#4E79A7"} />
              ) : (
                ""
              )}
            </Col>
            </Row>
  
            <Row className='mt-4 mb-4'>
            <Col md={6} className='px-3'>
              {resourceQwdData !== undefined && resourceQwdData !== null && resourceQwdData.length > 0 ? (
                <HorizontalChart chartData={resourceQwdLable} chartHeading="Resource Wise QWD (Quality of work delivered) Rating (1-10)" chartHeight={600} label="_id" value="avgQWD" color = {"#4E79A7"}/>
              ) : (
                ""
              )}
            </Col>

            <Col md={6} className='px-3'>
              {projectQwdData !== undefined && projectQwdData !== null && projectQwdData.length > 0 ? (
                <HorizontalChart chartData={projectQwdLable} chartHeading="Project Wise QWD (Quality of work delivered) Rating (1-10)" chartHeight={600} label="_id" value="avgQWD" color = {"#F28E2B"}/>
              ) : (
                ""
              )}
            </Col>
            </Row>

            <Row className='mt-4 mb-4'>
            <Col md={6} className='px-3'>
              {resourceIdcData !== undefined && resourceIdcData !== null && resourceIdcData.length > 0 ? (
                 <HorizontalChart chartData={resourceIdcLable} chartHeading="Resource Wise IDC (Independent Delivery Capability) Rating (1-10)" chartHeight={600} label="_id" value="avgIDC" color = {"#F28E2B"} />
              ) : (
                ""
              )}
              </Col>

              <Col md={6} className='px-3'>
              {projectIdcData !== undefined && projectIdcData !== null && projectIdcData.length > 0 ? (
                <HorizontalChart chartData={projectIdcLable} chartHeading="Project Wise IDC (Independent Delivery Capability) Rating (1-10)" chartHeight={600} label="_id" value="avgIDCs" color = {"#4E79A7"}/>
              ) : (
                ""
              )}
            </Col>
            </Row>
              </> }
              
          
              </>
            }
        </Container>
      </div>
    )
}
