import axios from 'axios'
import React, { useEffect, useState } from 'react'

const useGetAllDesignation = () => {
    const [allDesignationOptionsById, setAllDesignationOptionsById] = useState([])

    useEffect(() => {
        try {
            axios.get(process.env.REACT_APP_BASE_URL + '/emp/getAllDesignation')
                .then((res) => {
                    const filterOptionById = (res?.data?.data)?.length > 0 && (res?.data?.data)?.map((itm) => {
                        return ({ value: itm._id, label: itm.empDesignation })
                    })

                    if (filterOptionById?.length > 0) {
                        setAllDesignationOptionsById(filterOptionById)
                    }
                })
                .catch((err) => {
                    console.log("Error", err)
                })
        } catch (error) {
            console.log("Error", error)
        }
    }, [])



    return {allDesignationOptionsById }
}

export default useGetAllDesignation