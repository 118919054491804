import React from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap'

const HrReportGenerate = () => {
  return (
    <div className='TLAssignedTask outletPadding' data-aos='fade-up'>
         <Container>
            <h1>report generate</h1>
            <Row className='justify-content-between w-100 mt-2'>
                <Col md={4} className='d-flex justify-content-end mt-0 mt-mb-0'>
                    <Row style={{ width: '100%' }}>
                    <Col md={6} className='ps-0 mb-2 mb-md-0 '>
                        <Form.Group>
                            <Form.Label className='w-100 THIS'> From </Form.Label>
                            <Form.Control type="date" 
                            // value={startDate} 
                            // onChange={(e) => setStartDate(e.target.value)} 
                            />
                        </Form.Group>
                    </Col>

                    <Col md={6} className='ps-0'>
                        <Form.Group>
                        <Form.Label> To </Form.Label>
                        <Form.Control type="date" 
                        // value={endDate} min={startDate} onChange={(e) => setEndDate(e.target.value)} 
                        />
                        </Form.Group>
                    </Col>
                    </Row>
                </Col>
          </Row>
         </Container>
    </div>
  )
}

export default HrReportGenerate
