import axios from 'axios';
import React, { useState } from 'react'
import { Button, Col, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import * as Icon from "react-bootstrap-icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import { useSelector } from 'react-redux';
import useGetAllCategories from '../ReuseApi/useGetAllCategories';
import useGetAllProjects from '../ReuseApi/useGetAllProjects';
import { useNavigate } from 'react-router-dom';

export const CreateSelfTaskModel = ({show, close}) => {

    const loginUserData = useSelector((state) => state.loginData.userData);

    // for redirect
    const navigate = useNavigate();

    // for map department
    const userDepartment = (loginUserData?.department || []).map(item => ({value: item.value,label: item.label}));
  
    // for taskpriotrity
    const taskPriorityOptions = [{ value: 'normal', label: 'Normal' }, { value: 'medium', label: 'Medium' }, { value: 'immediate', label: 'Immediate' }];
    
    // data for assign by leadname
   const resultData = Array.isArray(loginUserData.lead_name) ? loginUserData.lead_name[0] : loginUserData.lead_name;
   const optionsArray = [{ value: resultData, label: resultData }];

    // use custome hook
    const { allCategoriesOptions } = useGetAllCategories()
    const { allProjectOptions, allProjectOptionsById } = useGetAllProjects()

    // for indivisual state
     const [taskpriority, setTaskpriority] = useState("")
     const [projectname, setProjectname] = useState("")
     const [categoryname, setCategoryname] = useState("")
     const[assignbyname,setAssignByName] = useState([])
    const [departmentName, setDepartmentName] = useState(userDepartment[0])


    // for usestate
     const [addnotask, setAddNoTask] = useState({
        empId: "",
        taskId: "",
        taskName: "",
        taskdescription: "",
        department_id: "",
        departmentName: "",
        AssignTask: "",
        assignBy_id: "",
        assigntoid: "",
        proposedstartdate: "",
        proposedenddate: "",
        initialEstimateHr: "",
        initialEstimateMinutes:"",
        estimatehr: "",
        logStatus: {
            resource_id: "",
            resourceName: "",
        },
    });

    // for error handling state
  const [taskNameErr, setTaskNameErr] = useState(false)
  const [taskPriorityErrr, setTaskPriorityErr] = useState(false)
  const [taskDescErr, setTaskDescErr] = useState(false)
  const [projectNameErr, setProjectNameErr] = useState(false)
  const [categoryNameError, setCategoryNameError] = useState(false)
  const[assignbyErr,setAssignByErr] = useState(false)
  const [startDateErr, setStartDateErr] = useState(false)
  const [endDateErr, setEndDateErr] = useState(false)
  const [initialEstiErr, setInitialEstiErr] = useState(false)
  const[initialminuteErr,setInitialMinuteErr] = useState(false)

    const handlerData = (e) => {
      let name,value
        name = e.target.name;
        value = e.target.value;
        setAddNoTask({ ...addnotask, [name]: value });
    };

    const AddTaskSuccess = () => {
        toast.success("Add Self-Task Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose:1000
        });
    };

    const AlreadyTaskAdd = () => {
        toast.error("Task Already Added", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose:1000
        });
    };

     const EstimateHr = () =>{
      toast.error("Initial Estimate hr can not be 0.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose:1000,
      });
  }

  const InitialHrMinute = () =>{
      toast.error("00 hours and 00 minutes at the same time are not permitted, please choose an acceptable time.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose:1000,
      });
  }


    // function for error handling
const handlerValidate = () => {
    let FormValid = true;

    if (addnotask.taskName.length === 0) {
      FormValid = false;
      setTaskNameErr(true);
    }
    else if (taskpriority.length === 0) {
      FormValid = false;
      setTaskPriorityErr(true);
    }
    else if (addnotask.taskdescription.length === 0) {
      FormValid = false;
      setTaskDescErr(true);
    }
    else if (categoryname.length === 0) {
      FormValid = false;
      setCategoryNameError(true);
    }
   
    else if (projectname.length === 0) {
      FormValid = false;
      setProjectNameErr(true);
    }

    else if (assignbyname.length === 0) {
      FormValid = false;
      setAssignByErr(true);
    }
    else if (addnotask.proposedstartdate.length === 0) {
      FormValid = false;
      setStartDateErr(true);
    }

    else if (addnotask.proposedenddate.length === 0) {
      FormValid = false;
      setEndDateErr(true);
    }
    else if (addnotask.initialEstimateHr.length === 0) {
      FormValid = false;
      setInitialEstiErr(true);
    }
    else if (addnotask.initialEstimateMinutes.length === 0) {
      FormValid = false;
      setInitialMinuteErr(true);
    }
    return FormValid;
  };
    const handleSubmit = (e) => {
      setTaskNameErr(false)
      setTaskPriorityErr(false)
      setTaskDescErr(false)
      setAssignByErr(false)
      setProjectNameErr(false)
      setCategoryNameError(false)
      setStartDateErr(false)
      setEndDateErr(false)
      setInitialEstiErr(false)
      setInitialMinuteErr(false)

      if(handlerValidate()){
        if(parseFloat(addnotask.initialEstimateHr) === 0 && parseFloat(addnotask.initialEstimateMinutes) === 0){
         InitialHrMinute();
        }else {
      const payload = {
        "empId": loginUserData?.empId,
        "taskName": addnotask?.taskName,
        "taskDescription": addnotask?.taskdescription,
        "taskPriority": taskpriority?.value,
        "department_id":departmentName.value,
        "departmentName": departmentName.label,
        "taskDepartmentId":departmentName.value,
        "taskDepartmentName":  departmentName.label,
        "projectId": projectname.value,
        "projectName": projectname.label,
        "AssignTask": "",
        "assignBy_id": loginUserData.lead_id,
        "assignBy_Name": assignbyname.value,
        "resource_id": loginUserData?.empId,
        "resourceName": `${loginUserData.firstName} ${loginUserData.lastName}`,
        "categoryOfName": categoryname.label,
        "category_id": categoryname.value,
        "proposedStartDate": addnotask.proposedstartdate ? new Date(addnotask.proposedstartdate).toISOString().split('T')[0] : '',
        "proposedEndDate": addnotask.proposedenddate ? new Date(addnotask.proposedenddate).toISOString().split('T')[0] : '',
        "initialEstimateHr": addnotask.initialEstimateHr,
        "initialEstimateMinutes":addnotask.initialEstimateMinutes,
        "estimateHr": "",
        "status": "pending",
        "logStatus": {
          "status": "pending",
          "resource_id": loginUserData?.empId,
          "resourceName": `${loginUserData.firstName} ${loginUserData.lastName}`,
        }
      }
      axios.post(process.env.REACT_APP_BASE_URL + '/task/addTask', payload)
        .then((res) => {
          setAddNoTask({
            empId: "",
            taskId: "",
            taskName: "",
            taskdescription: "",
            department_id: "",
            departmentName: "",
            AssignTask: "",
            assignBy_id: "",
            assigntoid: "",
            proposedstartdate: "",
            proposedenddate: "",
            initialEstimateHr: "",
            initialEstimateMinutes:"",
            estimatehr: "",
            logStatus: {
                resource_id: "",
                resourceName: "",
            },
          });
          setTaskpriority("");
          setProjectname("");
          setCategoryname("");
          setAssignByName("");

          // { res.data.msg !== "Add Task Successfully" ? AlreadyTaskAdd() : AddTaskSuccess(); 
          AddTaskSuccess()
          navigate(`/${loginUserData?.resourceType}/self-task`)
          close() 
        // }
        })
        .catch((err) => {
          console.log("Error", err)
        })
      }
    }
  }

        
  return (
    <>
    <ToastContainer/>
    <Modal show={show} onHide={close} size="lg" centered backdrop='static' keyboard={false}>
    <Modal.Header closeButton className="mngmodelheader">
      <Modal.Title><h5>Create Self Task</h5></Modal.Title>
    </Modal.Header>
    <Modal.Body size="lg">
      <Row className='mb-3'>
        <Col sm={12} md={6}>
          <Form.Group controlId="formBasicId">
            <Form.Label>
              Task Title
              <span className="requiredfield">*</span>
            </Form.Label>
              <Form.Control
                placeholder="Task Name"
                name="taskName"
                value = {addnotask.taskName}
                onChange={(e) => {
                    if (
                      !e.target.value.match(
                        // /[+@#$&%!~=^_:{}\[\]*|//\-/?<>,;`'""/\\]/
                        /[#$&%!~=^_{}\[\]*|//\/?<>;`/\\]/
                      ) &&
                      e.target.value.trim()
                    ) {
                      handlerData(e);
                    } else if (e.target.value.length === 0) {
                      handlerData(e);
                    }
                    addnotask.taskName.length >= 0
                      ? setTaskNameErr(false)
                      : setTaskNameErr(true);
                  }}
              />
          </Form.Group>
          {taskNameErr ? (
                <p className="errMsg">
                  <>
                    <Icon.ExclamationTriangle className="me-2" />
                  </>
                  Required Field
                </p>
              ) : null}
        </Col>
        <Col sm={12} md={6}>
          <Form.Label className="emp-form-label mt-3 fw-bold">
              Task Priority
              <span className="requiredfield">*</span>
          </Form.Label>
            <Select
                isMulti={false}
                isSearchable={true}
                value={taskpriority}
                options={taskPriorityOptions}
                onChange={(option) => {
                  setTaskpriority(option);
                  taskpriority.length < 0
                    ? setTaskPriorityErr(true)
                    : setTaskPriorityErr(false)
                }}
            />
            {taskPriorityErrr ? (
                <p className="errMsg">
                  <>
                    <Icon.ExclamationTriangle className="me-2" />
                  </>
                  Required Field
                </p>
              ) : null}
        </Col>
      </Row>

      <Row className='mb-3'>
        <Col sm={12} md={12}>
          <Form.Group className="">
            <Form.Label className="mt-3 fw-bold">
              Task Description{" "}
              <span className="requiredfield">*</span>
            </Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Task Description"
                name='taskdescription'
                value={addnotask.taskdescription}
                onChange={(e) => {
                    if (
                      e.target.value.trim()
                    ) {
                      handlerData(e);
                    } else if (e.target.value.length === 0) {
                      handlerData(e);
                    }
                    addnotask.taskdescription.length >= 0
                      ? setTaskDescErr(false)
                      : setTaskDescErr(true);
                  }}
              />
          </Form.Group>
           {taskDescErr ? (
                <p className="errMsg">
                  <>
                    <Icon.ExclamationTriangle className="me-2" />
                  </>
                  Required Field
                </p>
              ) : null}
        </Col>
      </Row>

     <Row className='mb-3'>
        <Col sm={12} md={6}>
            <Form.Group className="">
              <Form.Label>
                Category Name
                <span className="requiredfield">*</span>
              </Form.Label>
                  <Select
                      isMulti={false}
                      isSearchable={true}
                      value={categoryname}
                      options={allCategoriesOptions}
                      onChange={(option) => {
                        setCategoryname(option);
                        categoryname?.length < 0
                      ? setCategoryNameError(true)
                      : setCategoryNameError(false);
                      }}
                />
            </Form.Group>
            {categoryNameError ? (
                  <p className="errMsg">
                    <>
                      <Icon.ExclamationTriangle className="me-2" />
                    </>
                    Required Field
                  </p>
                ) : null}
        </Col>
        <Col sm={12} md={6}>
            <Form.Group className="">
              <Form.Label>
                Project Name
                <span className="requiredfield">*</span>
              </Form.Label>
              <Select
                  isMulti={false}
                  isSearchable={true}
                  value={projectname}
                  options={allProjectOptionsById}
                  onChange={(option) => {
                    setProjectname(option);
                    projectname.length < 0
                      ? setProjectNameErr(true)
                      : setProjectNameErr(false);
                  }}
                />
            </Form.Group>
            {projectNameErr ? (
                  <p className="errMsg">
                    <>
                      <Icon.ExclamationTriangle className="me-2" />
                    </>
                    Required Field
                  </p>
                ) : null}
        </Col>
      </Row>

      <Row className='mb-4'>
        <Col sm={12} md={4}>
          <Form.Group className="">
            <Form.Label className="">
              Department Name
            </Form.Label>
            <Select
                isMulti={false}
                isSearchable={true}
                placeholder="Select Department Name"
                name='departmentName'
                value={departmentName}
                options={userDepartment}
                onChange={(option) => {
                  setDepartmentName(option);
                }
                }
              />
          </Form.Group>
        </Col>

        <Col sm={12} md={4}>
          <Form.Group controlId="formBasicId">
            <Form.Label className="">
              Assign By
              <span className="requiredfield">*</span>
            </Form.Label>
           <Select
                isMulti={false}
                isSearchable={true}
                value={assignbyname}
                options={optionsArray}
                onChange={(option) => {
                  setAssignByName(option);
                  assignbyname.length < 0
                    ? setAssignByErr(true)
                    : setAssignByErr(false)
                }}
              />
          </Form.Group>
           {assignbyErr ? (
                <p className="errMsg">
                  <>
                    <Icon.ExclamationTriangle className="me-2" />
                  </>
                  Required Field
                </p>
              ) : null}
        </Col>
        
        <Col sm={12} md={4}>
          <Form.Group className="">
            <Form.Label className="mt-3 fw-bold">
              Assign To
            </Form.Label>
            <Form.Control 
              value={`${loginUserData.firstName} ${loginUserData.lastName}`}
              readOnly
            />
          </Form.Group>
        </Col>
      </Row>

       <Row className='mb-4'>
        <Col sm={12} md={3}>
          <Form.Group controlId="formBasiContact" className="w-100">
            <Form.Label>
              Proposed Start date{" "}
              <span className="requiredfield">*</span>
            </Form.Label>
            <InputGroup className="mb-2">
              <Form.Control
                className=""
                type="date"
                name="proposedstartdate"
                value={addnotask?.proposedstartdate ? new Date(addnotask?.proposedstartdate).toISOString().split("T")[0]: ""}
                //  min={new Date().toISOString().split("T")[0]}
                 onKeyDown={(event) => event.preventDefault()}
                 onChange={(e) => {handlerData(e)
                 addnotask.proposedstartdate?.length < 0
                      ? setStartDateErr(true)
                      : setStartDateErr(false);
                    }}
              />
            </InputGroup>
          </Form.Group>
           {startDateErr ? (
                <p className="errMsg">
                  <>
                    <Icon.ExclamationTriangle className="me-2" />
                  </>
                  Required Field
                </p>
              ) : null}
        </Col>

        <Col sm={12} md={3}>
          <Form.Group controlId="formBasiContact" style={{ width: "100%" }}>
            <Form.Label>
              Proposed End date{" "}
              <span className="requiredfield">*</span>
            </Form.Label>
              <Form.Control
                className=""
                type="date"
                name='proposedenddate'
                value={addnotask?.proposedenddate  ? new Date(addnotask?.proposedenddate ).toISOString().split("T")[0]: ""}
                min={addnotask.proposedstartdate}
                  disabled={
                    addnotask?.proposedstartdate?.length === 0 
                  }
                 onChange={(e) => {handlerData(e)
                 addnotask.proposedenddate.length < 0
                      ? setEndDateErr(true)
                      : setEndDateErr(false);}}
              />
          </Form.Group>
          {endDateErr ? (
                <p className="errMsg">
                  <>
                    <Icon.ExclamationTriangle className="me-2" />
                  </>
                  Required Field
                </p>
              ) : null}
        </Col>

        <Col sm={12} md={3}>
          <Form.Group controlId="formBasiContact" style={{ width: "100%" }}>
            <Form.Label>
              Initial Estimate(Hours){" "}
              <span className="requiredfield">*</span>
            </Form.Label>
              <Form.Control
                type="text"
                name="initialEstimateHr"
                value={addnotask?.initialEstimateHr}
                 onChange={(e) => {
                      if(/^[0-9]*(\.[0-9]*)?$/.test(e.target.value) && e.target.value.length <= 4){
                       handlerData(e)
                      } else if (e.target.value.length === 0) {
                        handlerData(e)
                      }
                    addnotask?.initialEstimateHr?.length < 0
                      ? setInitialEstiErr(true)
                      : setInitialEstiErr(false);
                  }}
              />
          </Form.Group>
          {initialEstiErr ? (
                <p className="errMsg">
                  <>
                    <Icon.ExclamationTriangle className="me-2" />
                  </>
                  Required Field
                </p>
              ) : null}
        </Col>

         <Col sm={12} md={3}>
          <Form.Group controlId="formBasiContact" style={{ width: "100%" }}>
            <Form.Label>
              Initial Minutes
              <span className="requiredfield">*</span>
            </Form.Label>
              <Form.Control
                type="text"
                name="initialEstimateMinutes"
                value={addnotask?.initialEstimateMinutes}
                  onChange={(e) => {
                    if(/^[0-9]{0,2}$/.test(e.target.value) && e.target.value >= 0 && e.target.value <= 59){
                        handlerData(e);
                      } else if (e.target.value > 59) {
                         toast.error('Please enter a value between 0 and 59.')}
                        else if (e.target.value.length === 0) {
                        handlerData(e);
                      }
                    addnotask?.initialEstimateMinutes?.length < 0
                      ? setInitialMinuteErr(true)
                      : setInitialMinuteErr(false);
                  }}
              />
          </Form.Group>
          {initialminuteErr ? (
                <p className="errMsg">
                  <>
                    <Icon.ExclamationTriangle className="me-2" />
                  </>
                  Required Field
                </p>
              ) : null}
        </Col>
      </Row>

    </Modal.Body>

    <Modal.Footer className="text-center mt-4">
      <Button className="primaryBtn " 
      onClick={() => { handleSubmit() }}
      >
        <Icon.PersonAdd className='me-2' /> Create Task
      </Button>

    </Modal.Footer>

    </Modal>
    </>
    
  )
}
