import React, { useState } from 'react'
import { Modal, Button, Form, Col, Row } from "react-bootstrap";
import Select from "react-select";
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Icon from "react-bootstrap-icons";

const CreateSubTaskModel = ({ show, close, result }) => {
  // for react select status dropdown
  const taskStatusOptions = [{ value: 'pending', label: 'Pending' }, { value: 'completed', label: 'Completed' }];

  // for show initial hours and minute
  const InitialHours = (typeof result?.initialEstimateHr === 'number' && !isNaN(result?.initialEstimateHr)) ? result?.initialEstimateHr : 0;
  const numericValue = parseFloat(InitialHours);

    let hours = 0;
    let minutes = 0;

    if (!isNaN(numericValue)) {
        hours = Math.floor(numericValue);
        const decimalPart = numericValue - hours;
        minutes = Math.round(decimalPart * 60);
    }
   
  // for create subtask
  const AddSubTaskSuccess = () => {
    toast.success("Added SubTask Successfully", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose:1000,
    });
  };

  const ActualHr = () =>{
      toast.error("Actual hr can not be 0.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose:1000,
      });
  }

  const ActualHrMinute = () =>{
      toast.error("00 hours and 00 minutes at the same time are not permitted, please choose an acceptable time.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose:1000,
      });
  }

  //  for  state
  const [taskstatus, setTaskStatus] = useState("")
  const [subtask, setSubTask] = useState("")
  const [actualstartdt, setActualStartDt] = useState("")
  const [actualenddt, setActualEndDt] = useState("")
  const [actualhr, setActualHr] = useState("")
  const[actualminute,setActualMinute] = useState("")
  const [remark, setRemark] = useState("")
  const [complition, setComplition] = useState("")

  // state for validation
  const [subtaskerr, setSubTaskErr] = useState(false);
  const [actualstartdterr, setActualStartdtErr] = useState(false);
  const [actualenddterr, setActualEndDtErr] = useState(false);
  const [actualhrerr, setActualHrErr] = useState(false);
  const [taskstatuserr, setTaskStatusErr] = useState(false);
  const [workComplerr, setWorkComplerr] = useState(false)
  const[actualminuteerr,setActualMinuteErr] = useState(false)

  // create function for validation
  const handlerValidate = () => {
    let FormValid = true;
    if (subtask?.length === 0) {
      FormValid = false;
      setSubTaskErr(true);
    }
    else if (actualstartdt?.length === 0) {
      FormValid = false;
      setActualStartdtErr(true);
    }
    else if (actualenddt?.length === 0) {
      FormValid = false;
      setActualEndDtErr(true);
    }
    else if (actualhr?.length === 0) {
      FormValid = false;
      setActualHrErr(true);
    }
    else if (actualminute?.length === 0) {
      FormValid = false;
      setActualMinuteErr(true);
    }
    else if (taskstatus?.length === 0) {
      FormValid = false;
      setTaskStatusErr(true);
    }
    return FormValid;
  };

  const handleActualHr = (e) => {
    e.preventDefault()
    const value = e.target.value
    const regex = /^[0-9]*(\.[0-9]*)?$/;
    if (regex.test(value) && (value.length <= 4)) {
      setActualHr(value)
    }
    actualhr?.length < 0 ?
     setActualHrErr(true) : 
     setActualHrErr(false);

  }


  // calling api
  const handleSubTask = async (e) => {
    e.preventDefault();
    setSubTaskErr(false);
    setActualStartdtErr(false);
    setActualEndDtErr(false);
    setTaskStatusErr(false);
    setActualHrErr(false);
    setActualMinuteErr(false);

    if (handlerValidate()) {
      if(parseFloat(actualhr) === 0 && parseFloat(actualminute) === 0){
        ActualHrMinute();
      }else{
      const payload = {
        "headTaskId": result.taskId,
        "headTaskName": result.taskName,
        "headTaskDescription": result.taskDescription,
        "headTaskPriority": result.taskPriority,
        "headProjectName": result.projectName,
        "taskName": subtask,
        "taskPriority": result.taskPriority,
        "projectName": result.projectName,
        "resource_id": result.resource_id,
        "resourceName": result.resourceName,
        "categoryOfName": result.categoryOfName,
        "actualStartDate": new Date(actualstartdt).toISOString().split('T')[0],
        "actualEndtDate": new Date(actualenddt).toISOString().split('T')[0],
        "actualHours": actualhr,
        "actualMinutes":actualminute,
        "remark": remark,
        "complitionByResource": taskstatus?.value === "completed" ? 100 : complition,
        "status": taskstatus?.value,
      }
      try {
        await axios
          .post(process.env.REACT_APP_BASE_URL + '/Task/addSubTask', payload)
          .then((res) => {
            AddSubTaskSuccess()
            close();
            closeSubtaskModel()
          })
      } catch (error) {
        console.log("Error", error);
      }
    }
  }
  }

  const closeSubtaskModel = () =>{
    setTaskStatus("");
    setSubTask("");
    setActualStartDt("");
    setActualEndDt("");
    setActualHr("");
    setRemark("");
    setComplition("");
    setActualMinute("")
    setSubTaskErr(false);
    setTaskStatusErr(false);
    setActualEndDtErr(false);
    setActualStartdtErr(false);
    setActualHrErr(false)
    setActualMinuteErr(false)
    close()
  }

  return (
    <>
      <ToastContainer/>
      <Modal show={show} onHide={closeSubtaskModel} size="lg" centered backdrop='static' keyboard={false}>
        <Modal.Header closeButton className="mngmodelheader">
          <Modal.Title><h5>Create Sub Task</h5></Modal.Title>
        </Modal.Header>
        <Modal.Body size="lg">
          <Row>
            <Col sm={12} md={4}>
              <Form.Group controlId="formBasicName">
                <Form.Label className="fw-bold">Task Name</Form.Label>
                <Form.Control
                  value={result?.taskName}
                  readOnly
                />
              </Form.Group>
            </Col>

            <Col sm={12} md={8}>
              <Form.Group controlId="formBasicName">
                <Form.Label className="fw-bold">
                  Sub Task{" "}
                  <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                </Form.Label>
                <Form.Control
                  placeholder="SubTask Name *"
                  value={subtask}
                  name="subtask"
                  onChange={(e) => {
                    if (
                      !e.target.value.match(
                        /[#$&%!~=^_{}\[\]*|//\/?<>;`/\\]/
                      )
                    ) {
                      setSubTask(e.target.value);
                    } else if (e.target.value.length === 0) {
                      setSubTask(e.target.value);
                    }
                    subtask.length >= 0 
                      ? setSubTaskErr(false)
                      : setSubTaskErr(true);

                  }}
                />
                {subtaskerr ? (
                  <p className="errMsg"><Icon.ExclamationTriangle className="me-2" />Required Field</p>
                ) : null}
              </Form.Group>
            </Col>

            <Col sm={12} md={4} className="mt-3">
              <Form.Group>
                <Form.Label> Actual Start date{" "}
                  <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                </Form.Label>
                <Form.Control
                  type="date"
                  value={actualstartdt}
                  name="actualstartdt"
                  min={(result?.proposedStartDate)?.split('T')[0]}
                  max={(result?.proposedEndDate)?.split('T')[0]}
                  onKeyDown={(event) => event.preventDefault()}
                  onChange={(e) => {
                    setActualStartDt(e.target.value);
                    actualstartdt.length >= 0
                      ? setActualStartdtErr(false)
                      : setActualStartdtErr(true);
                    }
                  }
                />
              </Form.Group>
              {actualstartdterr ? (
                <p className="errMsg"><Icon.ExclamationTriangle className="me-2" />Required Field</p>
              ) : null}
            </Col>

            <Col sm={12} md={4} className="mt-3">
              <Form.Group controlId="formBasiContact">
                <Form.Label className="fw-bold">
                  Actual End date{" "}
                  <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                </Form.Label>
                <Form.Control
                  type="date"
                  value={actualenddt}
                  name="actualenddt"
                  onKeyDown={(event) => event.preventDefault()}
                  min={actualstartdt}
                  max={(result?.proposedEndDate)?.split('T')[0]}
                  disabled={actualstartdt.length === 0}
                  onChange={(e) => {
                    setActualEndDt(e.target.value);
                    actualenddt.length >= 0
                      ? setActualEndDtErr(false)
                      : setActualEndDtErr(true);
                  }}
                />
              </Form.Group>
              {actualenddterr ? (
                <p className="errMsg"><Icon.ExclamationTriangle className="me-2" />Required Field</p>
              ) : null}
            </Col>

            <Col sm={12} md={4} className="mt-3">
              <Form.Group controlId="formBasiContact">
                <Form.Label className="fw-bold">
                  Initial Estimate(hours)
                </Form.Label>
                <Form.Control
                  value = {`${hours} hr ${minutes} min`}
                  // value={result?.initialEstimateHr !== undefined && result?.initialEstimateHr !== null  ? result.initialEstimateHr.toFixed(2) : ''}
                  readOnly
                />
              </Form.Group>
            </Col>

            <Col sm={12} md={3} className="mt-3">
              <Form.Group controlId="formBasiContact">
                <Form.Label className="fw-bold">
                  Actual Hours{" "}
                  <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                </Form.Label>
                <Form.Control
                  name="actualhr"
                  value={actualhr}
                  onChange={(e) => {
                    if(/^[0-9]*(\[0-9]*)?$/.test(e.target.value) && e.target.value.length <= 4){
                        setActualHr(e.target.value);
                      } else if (e.target.value.length === 0) {
                        setActualHr(e.target.value);
                      }
                    actualhr?.length < 0
                      ? setActualHrErr(true)
                      : setActualHrErr(false);
                  }}
                  // onChange={(e) => {
                  //   handleActualHr(e)
                  // }}
                />
              </Form.Group>
              {actualhrerr ? (
                <p className="errMsg"><Icon.ExclamationTriangle className="me-2" />Required Field</p>
              ) : null}

            </Col>

            <Col sm={12} md={3} className="mt-3">
              <Form.Group controlId="formBasiContact">
                <Form.Label className="fw-bold">
                  Actual Minute{" "}
                  <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                </Form.Label>
                <Form.Control
                  name="actualminute"
                  value={actualminute}
                  onChange={(e) => {
                    if(/^[0-9]{0,2}$/.test(e.target.value) && e.target.value >= 0 && e.target.value <= 59){
                        setActualMinute(e.target.value);
                      } else if (e.target.value > 59) {
                         toast.error('Please enter a value between 0 and 59.')}
                        else if (e.target.value.length === 0) {
                        setActualMinute(e.target.value);
                      }
                    actualminute?.length < 0
                      ? setActualMinuteErr(true)
                      : setActualMinuteErr(false);
                  }}
                />
              </Form.Group>
              {actualminuteerr ? (
                <p className="errMsg"><Icon.ExclamationTriangle className="me-2" />Required Field</p>
              ) : null}

            </Col>

            <Col sm={10} md={3} div className="mt-3">
              <Form.Group controlId="formBasiContact">
                <Form.Label className="fw-bold">
                  Status{" "}
                  <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                </Form.Label>
                <Select
                  isMulti={false}
                  isSearchable={true}
                  value={taskstatus}
                  options={taskStatusOptions}
                  onChange={(option) => {
                    setTaskStatus(option);
                    taskstatus.length < 0
                      ? setTaskStatusErr(true)
                      : setTaskStatusErr(false);
                  }}
                />
              </Form.Group>
              {taskstatuserr ? (
                <p className="errMsg"><Icon.ExclamationTriangle className="me-2" />Required Field</p>
              ) : null}
            </Col>

            <Col sm={12} md={3} className="mt-3">
              <Form.Group controlId="formBasicName">
                <Form.Label className="fw-bold">Work Completion(%)</Form.Label>
                <Form.Control
                  name="complition"
                  // value={complition}
                  value={taskstatus?.value === "completed"
                    ? "100"
                    : complition}
                  onChange={(e) => {
                    if (
                      (!e.target.value.match(
                        /[A-Za-z+@#$&%!~=^_:(){}\[\]*.|/\s/g/\-/?<>,;`'""/\\]/
                      ) &&
                        e.target.value === " ") ||
                      (e.target.value >= 1 && e.target.value <= 100)
                    ) {
                      setComplition(e.target.value);
                    } else if (e.target.value.length === 0) {
                      setComplition(e.target.value);
                    }
                  }}
                />
              </Form.Group>
            </Col>

            <Col sm={12} md={12} className="mt-3">
              <Form.Group controlId="exampleForm.ControlTextarea1">
                <Form.Label className="fw-bold"> Remarks </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="remark"
                  value={remark}
                  onChange={(e) => setRemark(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="text-center mt-4">
          <Button
            className="primaryBtn"
            onClick={(e) => handleSubTask(e)}
          >
            Create SubTask
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}


export default CreateSubTaskModel;
