import React, { useEffect, useState } from 'react'
import { Container, InputGroup, Table, Modal, Button, Form, Row, Col, Spinner, Nav, Tab, Image } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import * as Icon from "react-bootstrap-icons";
import { useLocation, useNavigate } from 'react-router-dom';

import normal from "../../images/gif/frame-88.png"
import medium from "../../images/gif/Ripple-6.3s-200px.gif";
import immediate from "../../images/gif/Ripple-1s-200px (1).gif"
import Badge from 'react-bootstrap/Badge';
import { getAllSelfTasks , setActiveToggle , setFilterTasksData} from './AllTaskSlice';
import Pagination from './Pagination'
import EditTaskModel from './Model/EditTaskModel';
import { CreateSelfTaskModel } from './Model/CreateSelfTaskModel';
import { FilterSelfTaskModel } from './Model/FilterSelfTaskModel';
import DisplayHoursAndMinutes from './Convertor/DisplayHoursAndMinutes';

const SelfAllTask = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const currentToggle = useSelector((state) => state?.AllTasks?.ActiveToggle);
    const filterAllTasks = useSelector((state) => state?.AllTasks?.AllFilterTasksData);
    const toggle = [{ name: 'Pending', value: 'pending' }, { name: 'Completed', value: 'completed' }];
    const roleOfUser = ["manager", "humanResource","teamlead","admin"]
    const roleOfEmployee = ["employee","contractual","intern"]

    const loginUserData = useSelector((state) => state.loginData.userData)
    const deptNameArray = (loginUserData?.department).map((itm) => itm.label)
    const allTasks = useSelector(state => state.AllTasks.AllSelfTask)

    const [show, setShow] = useState(false)
    const [showfilter, setShowFilter] = useState(false)
    const [docsPerPage, setDocsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [item, setItem] = useState();
     
    // for filter tab value reset
   const [pendingFilterValues, setPendingFilterValues] = useState({});
   const [completedFilterValues, setCompletedFilterValues] = useState({});

    // for edit task state
    const [showEdit, setShowEdit] = useState(false)

    // for Delete task state
    const [taskId, setTaskId] = useState("")

    // for show data not found
    const [showNoDataFound, setShowNoDataFound] = useState(false);

    // for no data found true false
    const handleFilterTrue = () => {
        setShowNoDataFound(true)
    }

    const handleFilterFalse = ()=>{
        setShowNoDataFound(false)
    }

    // for review modal
    const getAllSelfTaskData = () => {
         const payload = {
            "empId": loginUserData?.empId,
            "taskPriority": "",
            "taskName": "",
            "departmentName": deptNameArray,
            "projectName": "",
            "categoryOfName": "",
            "resource_id": "",
            "proposedStartDate": "",
            "proposedEndDate": "",
            "status": currentToggle,
            "idcMin": "",
            "idcMax": "",
            "qwdMin": "",
            "qwdMax": "",
            "slippage": "",
            "efficiency": "",
            "assignBy_Name": "",
            "documentsPerPage": docsPerPage,
            "page": currentPage
        }

      dispatch(getAllSelfTasks(payload))
    }

    useEffect(() => {
        getAllSelfTaskData()
    }, [currentPage, docsPerPage, currentToggle])

    // for status toggle change
  const handleToggleChange = (toggleValue) => {
    dispatch(setActiveToggle(toggleValue));
    if (toggleValue === 'pending') {
      setCompletedFilterValues({});
    } else {
      setPendingFilterValues({});
    }
  };


    // function
    const handleCloseCreateModel = () => {
        getAllSelfTaskData()
        setShow(false)
    }
    const handlerCloseFilterModel = () => setShowFilter(false)

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);

    };

    const handleDocsPerPage = (docsPageProp) => {
        setCurrentPage(1);
        setDocsPerPage(docsPageProp);

    }

    // for edit task
    const handleShowEditModal = (id) => {
        setItem(id)
        setShowEdit(id)

    }
    const handleEditModalClose = () => {
        setShowEdit(false)
        setItem("");
        getAllSelfTaskData()
    }

    return (
        <div className='TLAssignedTask outletPadding' data-aos='fade-up'>
            <Container>
                <FilterSelfTaskModel 
                    show={showfilter} 
                    close={handlerCloseFilterModel} 
                    taskStatus={currentToggle}
                    handleFilterTrue = {handleFilterTrue}
                    handlerFilterFalse = {handleFilterFalse}
                    showNoDataFound={showNoDataFound}
                    currentPage={currentPage}
                    docsPerPage={docsPerPage}
                    filterValues={
                    currentToggle === 'pending'
                    ? pendingFilterValues
                    : completedFilterValues
                } />
                <CreateSelfTaskModel show={show} close={handleCloseCreateModel} userData={loginUserData} />
                <EditTaskModel show={showEdit} close={handleEditModalClose} taskId={taskId} item={item} userData={loginUserData} />

                <Row className='d-flex justify-content-between align-items-center'>
                    <Col md={6} className="d-flex justify-content-start align-items-center">
                        {  
                            <Button className="primaryBtn btn-16 me-3" onClick={() => { setShow(true) }}>
                                <Icon.PlusCircle className="me-2" size={16} />
                                Create SelfTask
                            </Button>
                        }

                        <Button
                            className={` ${filterAllTasks?.data?.length > 0 || showNoDataFound  ? 'activeBtn': 'primaryBtn'}`}
                            onClick={() => setShowFilter(true)}
                            >
                            <Icon.Funnel className='me-2' size={17} />
                            Filter
                        </Button>
                    </Col>
                    <Col sm={12} md={6} className='d-flex justify-content-end align-items-center'>
                        <ButtonGroup aria-label="Basic example">
                        <Button
                            disabled={currentToggle === "pending" ? true : false}
                            variant={currentToggle === 'pending' ? 'warning' : 'outline-warning'} // Use 'warning' when active, 'light' otherwise
                            onClick={() => {handleToggleChange('pending');dispatch(setFilterTasksData([]));setShowNoDataFound(false) ;handleDocsPerPage(10)
                            }}
                        >
                            Pending
                        </Button>
                        <Button
                            disabled={currentToggle === "completed" ? true : false}
                            variant={currentToggle === 'completed' ? 'success' : 'outline-success'}
                            onClick={() => {handleToggleChange('completed');dispatch(setFilterTasksData([]));setShowNoDataFound(false);handleDocsPerPage(10)}}
                        >
                            Completed
                        </Button>
                        <Button
                            disabled={currentToggle === "rejected" ? true : false}
                            variant={currentToggle === 'rejected' ? 'danger' : 'outline-danger'}
                            onClick={() => {handleToggleChange('rejected');dispatch(setFilterTasksData([]));setShowNoDataFound(false);handleDocsPerPage(10)}}
                        >
                            Rejected
                        </Button>
                        </ButtonGroup>
                    </Col>
                </Row>
                <div className='outer-wrapper mx-auto'>
                    <div className='table-wrapper'>
                        <table>
                            <thead>
                                <tr className='tbl-header'>
                                    <th>Priority</th>
                                    {currentToggle === "pending" && 
                                    <th>Due(Days)</th>}
                                    <th>Project</th>
                                    <th className='taskwidth'>Task Name</th>
                                    <th>Department</th>
                                    <th className = "max-width-th">Category</th>
                                    <th className='assignwidth'>Assign By</th>
                                    <th>Date</th>
                                    <th>Initial Hr</th>
                                    {
                                        currentToggle === "completed" &&
                                        <>
                                            <th>Actual Hr</th>
                                            <th>IDC</th>
                                            <th>QWD</th>
                                            <th>Slippage</th>
                                            <th>Efficiency</th>

                                        </>
                                    }
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            {filterAllTasks?.data?.length > 0 || !showNoDataFound ? (
                            <tbody>
                                {
                                   allTasks?.data !== null &&
                                   allTasks?.data !== undefined &&
                                   allTasks?.data?.length > 0 ? (
                                    (filterAllTasks?.data?.length > 0 ? filterAllTasks?.data : allTasks?.data
                                   ).map((itm) => {
                                    const { taskName, taskPriority, departmentName, taskDepartmentName, projectName, categoryOfName, assignBy_Name, resourceName, proposedStartDate, proposedEndDate, status, idc, qwd, slippage, efficiency, taskId, isAccepted, isRejected, initialEstimateHr,actualHours } = itm
                                    
                                    // for convert hours and minute
                                    const parts = initialEstimateHr?.toFixed(2).toString().split(".") || [];
                                    const integerPart = parseInt(parts[0]);
                                    const decimalPart = parseInt(parts[1]);
                                    const hours = integerPart;
                                    const minutes = Math.round((decimalPart / 100) * 60);

                                    // for due days
                                    const currentDate = new Date();
                                    const endDate = new Date(itm.proposedEndDate);
                                    const endTimestamp = Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());
                                    const currentTimestamp = Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());

                                    let daysDifference = 0; 
                                    if (currentTimestamp > endTimestamp) {
                                        const differenceMillis = Math.abs(currentTimestamp - endTimestamp);
                                        daysDifference = Math.ceil(differenceMillis / (1000 * 60 * 60 * 24));
                                    }
                                        return (
                                            <tr key={Math.random() * 999999999}>
                                                <td>
                                                    {taskPriority === "normal" ?
                                                        <img width={50} height={50} src={normal} data-toggle="tooltip" title="Normal" />
                                                        :
                                                        taskPriority === "medium"
                                                            ?
                                                            <img width={50} height={50} src={medium} data-toggle="tooltip" title="Medium" />
                                                            :
                                                            taskPriority === "immediate"
                                                                ?
                                                                <img width={50} height={50} src={immediate} data-toggle="tooltip" title="Immediate" />
                                                                : ''
                                                    }
                                                </td>
                                                {currentToggle === "pending" && 
                                                <td>
                                                    <div className={`circle ${daysDifference < 5 ? 'green' : daysDifference < 15 ? 'orange' : 'red'}`} style = {{height:'35px',width:'35px',borderRadius:'55%',margin:'auto'}}>
                                                    <span className="number">{Math.floor(daysDifference)}</span>
                                                    </div>
                                                </td>
                                                }
                                                <td>{projectName}</td>
                                                <td>{taskName}</td>
                                                <td>{departmentName}</td>
                                                <td>{categoryOfName}</td>
                                                <td>{assignBy_Name}</td>
                                                <td>
                                                    {new Date(
                                                        proposedStartDate
                                                    ).toLocaleDateString("en-US", {
                                                        month: "long",
                                                        year: "numeric",
                                                    }) ==
                                                        new Date(proposedEndDate).toLocaleDateString("en-US", { month: "long", year: "numeric" })
                                                        ? `${new Date(
                                                            proposedStartDate
                                                        ).getDate()} - ${new Date(
                                                            proposedEndDate
                                                        ).getDate()} ${new Date(
                                                            proposedStartDate
                                                        ).toLocaleDateString("en-US", {
                                                            month: "long",
                                                            year: "numeric",
                                                        })}`
                                                        : `${new Date(
                                                            proposedStartDate
                                                        ).getDate()} ${new Date(
                                                            proposedStartDate
                                                        ).toLocaleDateString("en-US", {
                                                            month: "long",
                                                            year: "numeric",
                                                        })} -  ${new Date(
                                                            proposedEndDate
                                                        ).getDate()} ${new Date(
                                                            proposedEndDate
                                                        ).toLocaleDateString("en-US", {
                                                            month: "long",
                                                            year: "numeric",
                                                        })}`}
                                                </td>
                                                {/* <td>{initialEstimateHr ? initialEstimateHr.toFixed(2)  : 0}</td> */}
                                                {/* <td>{`${hours} hr ${minutes} min`}</td> */}
                                                <td><DisplayHoursAndMinutes value={initialEstimateHr} /></td>

                                                {
                                                    currentToggle === "completed" &&
                                                    <>
                                                        <td> <DisplayHoursAndMinutes value={actualHours} /></td>
                                                        {/* <td>{actualHours ? actualHours.toFixed(2)  : 0}</td> */}
                                                        <td>{idc !== null && idc !== undefined ? idc.toFixed(2) : 0}</td>
                                                        <td>{qwd !== null && qwd !== undefined ? qwd.toFixed(2) : 0}</td>
                                                        <td>{slippage !== null && slippage !== undefined ? slippage.toFixed(2) : 0}</td>
                                                        <td>{efficiency !== null && efficiency !== undefined ? efficiency.toFixed(2) : 0}</td>
                                                    </>
                                                }

                                                <td className='text-capitalize'>
                                                    <Badge bg={status === "completed" ? "success p-1 text-white" : "warning text-dark p-1"}>{status}</Badge>
                                                </td>

                                                <td>
                                                    <span data-toggle="tooltip" title="view">
                                                        <Icon.Eye size={22} className='me-2' onClick={() => {
                                                            navigate(`/${loginUserData?.resourceType}/self-task/view-details`, {
                                                                state: { data: itm }
                                                            })
                                                        }} />
                                                    </span>

                                                    {/* comment edit and delete button */}
                                                    {
                                                      currentToggle === "pending" &&
                                                        <>
                                                            <span
                                                                data-toggle="tooltip"
                                                                title="Edit"

                                                            >
                                                                <Icon.PencilSquare size={20} className='me-2' onClick={() => { handleShowEditModal(itm); }} />
                                                            </span>

                                                            {/* <span
                                                                data-toggle="tooltip"
                                                                title="delete"
                                                                style={{
                                                                    color: "#000000",
                                                                    fontSize: "20px",
                                                                }}
                                                            >
                                                                <Icon.Trash onClick={() => { handleShowDeleteModal(taskId) }} />
                                                            </span> */}
                                                        </>
                                                    }
                                                </td>
                                            </tr>
                                        );
                                    })
                                    ):(
                                            <tr>
                                            <td colSpan="18" className="text-center">No data available</td>
                                            </tr>
                                        )}
                            </tbody>
                            )
                                :
                                <tr>
                                <td colSpan="18" className="text-center">No Task Available</td>
                                </tr>
                                }
                        </table>
                    </div>
                </div>

                {allTasks?.data !== null || allTasks?.data?.length > 0 && filterAllTasks?.data?.length > 0 || !showNoDataFound ? (
                <div className='pagination-wrapper p-0'>
                    <Pagination
                    currentPage={currentPage}
                    totalPages={filterAllTasks?.data?.length > 0 ?filterAllTasks?.noOfPages :  allTasks?.noOfPages}
                    onPageChange={handlePageChange}
                    onDocsPerPage={handleDocsPerPage}
                    docsPerPage={docsPerPage}
                    />
                </div>
                ) : (
                ''
                )}

            </Container>
        </div>
    )
}

export default SelfAllTask;