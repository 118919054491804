import React, { useEffect, useMemo, useState } from 'react'
import { Button, Modal, Form, InputGroup } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Select from "react-select";
import { getResourceUtilizationFilterOption ,getResourceUtilization} from '../AllTaskSlice';

export const FilterUtilizationModel = ({ show, close }) => {
  const dispatch = useDispatch()

  const loginUserData = useSelector((state) => state.loginData.userData)
  const allDropdownOption = useSelector(state => state?.AllTasks?.FilterResourceUtilizationData)
  const deptNameArray = (loginUserData?.department).map((itm) => itm.label)
  const roleOfUser = ["teamlead","manager"]

  // state 
  const [empName, setEmpName] = useState("")
  const [departmentName, setDepartmentName] = useState("")
  const [designation, setDesignation] = useState("")

 const getResourceUtilizationFilterData = () =>{
       const payload = {
            "date": "",
            "empId": empName.value ? empName.value : "",
            "department": departmentName.label ? [departmentName.label] : [],
            "designation": designation.value ? designation.value : "",
            "documentsPerPage": 10,
            "page": 1
        }
      dispatch(getResourceUtilization(payload))
  }

  // for filter function call
   useEffect(() => {
    const payload = {
       "date": "",
       "department": roleOfUser.includes(loginUserData.resourceType) ? deptNameArray : "",
       "documentsPerPage": 10,
       "page": 1
    }
        dispatch(getResourceUtilizationFilterOption(payload))
    }, [])
    
    
  const handleFilterSubmit = () =>{
      getResourceUtilizationFilterData()
      close()
    }

  const clearFilter = () => {
    setEmpName("")
    setDepartmentName("")
    setDesignation("")
  }

 
  return (
    <Modal
      show={show}
      onHide={close}
      size="lg"
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title style={{ textAlign: 'center' }}><h5>Filter No-Task</h5></Modal.Title>
      </Modal.Header>
      <Modal.Body >
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <Form.Group>
              <Form.Label> Employee Name </Form.Label>
              <Select
                isMulti={false}
                options={
                  allDropdownOption !== null &&
                  allDropdownOption !== undefined &&
                  allDropdownOption?.employees
                }
                value={empName}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Select Employee "
                onChange={(option) => { setEmpName(option) }}
                isSearchable={true}
              />
            </Form.Group>
          </div>

          <div className="col-sm-12 col-md-6">
            <Form.Group>
              <Form.Label className="emp-form-label mt-3 fw-bold">
                Department Name
              </Form.Label>
              <Select
                isMulti={false}
                options={
                  allDropdownOption !== null &&
                  allDropdownOption !== undefined &&
                  allDropdownOption?.departments
                }
                value={departmentName}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Select Department "
                onChange={(option) => { setDepartmentName(option) }}
                isSearchable={true}
              />
            </Form.Group>
          </div>

          <div className="col-sm-12 col-md-12 mt-3">
            <Form.Group>
              <Form.Label className="emp-form-label mt-3 fw-bold">
                Designation
              </Form.Label>
              <Select
                isMulti={false}
                options={
                  allDropdownOption !== null &&
                  allDropdownOption !== undefined &&
                  allDropdownOption?.designations
                }
                value={designation}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Select Designation "
                onChange={(option) => { setDesignation(option) }}
                isSearchable={true}
              />
            </Form.Group>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button className="secondaryBtn" type='clear' onClick={clearFilter}>Reset</Button>
        <Button className="primaryBtn" onClick={() => { handleFilterSubmit() }}>Submit </Button>
      </Modal.Footer>
    </Modal>
  )
}
