import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import * as Icon from "react-bootstrap-icons";
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CompleteSelfSubTaskModel = (props) => {

    const { show, close, result, actualHrCount, actualAllocatedHrCount } = props

    const loginUserData = useSelector(state => state.loginData.userData);
    const departmentArray = loginUserData?.department?.map((itm) => itm.label);

    const parts = actualHrCount.toString().split(".");
    const integerPart = parseInt(parts[0] || 0);
    const decimalPart = parseInt(parts[1] || 0); // Use 0 if no decimal part
    const actualHours = integerPart;
    let actulMinutes = decimalPart ? Math.round((decimalPart / 100) * 60).toString() : NaN;
    // If actulMinutes is a string, perform formatting
    if (typeof actulMinutes === "string") {
        // If actulMinutes is "0.00", set it to "0"
        actulMinutes = actulMinutes === "0.00" ? "0" : actulMinutes;

        // Remove trailing ".00" if present
        if (actulMinutes.includes(".") && actulMinutes.endsWith("00")) {
            actulMinutes = actulMinutes.slice(0, -3);
        }
    }

    // for different department task
    const partsAllocate = Number(actualAllocatedHrCount).toString().split(".");
    const integerPartAllocate = parseInt(partsAllocate[0]);
    const decimalPartAllocate = parseInt(partsAllocate[1]);
    const actualHoursAllocate = integerPartAllocate;
    let actulMinutesAllocate = actualHoursAllocate === 0 ? "0" : (actualHoursAllocate ? Math.round((decimalPartAllocate / 100) * 60).toString() : NaN);
    // If actulMinutes is a string, perform formatting
    if (typeof actulMinutesAllocate === "string") {
        // If actulMinutesAllocate is "0.00", set it to "0"
        actulMinutesAllocate = actulMinutesAllocate === "0.00" ? "0" : actulMinutesAllocate;

        // Remove trailing ".00" if present
        if (actulMinutesAllocate.includes(".") && actulMinutesAllocate.endsWith("00")) {
            actulMinutesAllocate = actulMinutesAllocate.slice(0, -3);
        }
    }
    // const actulMinutesAllocate = actualHoursAllocate === 0 ? "0" : (actualHoursAllocate ? Math.round((decimalPartAllocate / 100) * 60).toFixed(2).slice(0, 2) : "0");

    // for state
    const [userTaskStatus, setUserTaskStatus] = useState("");
    const [actualhr, setActualHr] = useState(0)
    const [actualminute, setActualMinute] = useState("")
    const [taskcomplition, setTaskComplition] = useState("")
    const [remark, setRemark] = useState("")

    // status for validation
    const [actualhrErr, setActualHrErr] = useState(false)
    const [actualminuteErr, setActualMinuteErr] = useState(false)
    const [remarkErr, setremarkErr] = useState(false)

    // toast notification
    const ToastSuccess = () => {
        toast.success("Complete Task Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            // autoClose: 0000
        });
    };

    const ActualHrMinute = () => {
        toast.error("00 hours and 00 minutes at the same time are not permitted, please choose an acceptable time.", {
            position: toast.POSITION.TOP_RIGHT,
            // autoClose: 1000,
        });
    }

    useEffect(() => {
        setUserTaskStatus("completed");
        setTaskComplition("100%");
        setActualHr(
            departmentArray.includes(result?.departmentName) && actualHours
                ? actualHours
                : !departmentArray.includes(result?.departmentName) && actualHours
                    ? actualHours
                    : !departmentArray.includes(result?.departmentName) && actualHoursAllocate
                        ? actualHoursAllocate
                        : 0
        );

        // Set actualminute to 0 if departmentArray does not include result?.departmentName
        setActualMinute(
            departmentArray.includes(result?.departmentName) && actulMinutes
                ? actulMinutes
                : !departmentArray.includes(result?.departmentName) && actulMinutes
                    ? actulMinutes
                    : !departmentArray.includes(result?.departmentName) && actulMinutesAllocate
                        ? actulMinutesAllocate
                        : 0
        );
    }, [props]);



    // for error handling
    const handlerValidate = () => {
        let FormValid = true;
        if (actualhr.length === 0) {
            FormValid = false;
            setActualHrErr(true);
        }
        else if (actualminute.length === 0) {
            FormValid = false;
            setActualMinuteErr(true);
        }
        else if (remark.length === 0 || remark == 0) {
            FormValid = false;
            setremarkErr(true);
        }
        return FormValid;
    };


    // calling function
    const handleSubmit = async (e) => {
        e.preventDefault()
        setActualHrErr(false)
        setActualMinuteErr(false)
        setremarkErr(false)
        if (handlerValidate()) {
            if (parseFloat(actualhr) === 0 && parseFloat(actualminute) === 0) {
                ActualHrMinute();
            } else {
                const payload = {
                    "empId": result?.empId,
                    "taskId": result?.taskId,
                    "status": userTaskStatus,
                    "actualHours": actualhr,
                    "actualMinutes": actualminute,
                    "remark": remark,
                }
                await axios.post(process.env.REACT_APP_BASE_URL + '/task/updateEmployeeTssk', payload)
                    .then((res) => {
                        setActualHrErr("")
                        setActualMinuteErr("")
                        setremarkErr("")
                        close()
                        ToastSuccess();
                    })
                    .catch((err) => {
                        console.log("Error", err)
                    })
            }
        }
    }

    return (
        <>
            <ToastContainer />
            <Modal Modal show={show} onHide={close} centered>
                <Modal.Header closeButton style={{ color: 'black' }}>
                    <Modal.Title><h5>Update Task</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={12}>
                            <Form.Group controlId="formBasiContact">
                                <Form.Label className=" mt-3 fw-bold">
                                    Status
                                </Form.Label>
                                <Form.Control className="text-capitalize" value={userTaskStatus} readOnly />
                            </Form.Group>
                        </Col>

                        <Col md={12}>
                            <Form.Group controlId="formBasiContact">
                                <Form.Label className=" mt-3 fw-bold">
                                    Completion
                                </Form.Label>
                                <Form.Control value={taskcomplition} readOnly />
                            </Form.Group>
                        </Col>

                        {departmentArray.includes(result?.departmentName) ?
                            (
                                <>
                                    <Col md={6} className="mt-2">
                                        <Form.Group controlId="formBasiContact">
                                            <Form.Label className=" mt-3 fw-bold">
                                                Actual Hours <span style={{ color: "red" }}>*</span>
                                            </Form.Label>
                                            <Form.Control
                                                value={actualhr}
                                                name="actualhr"
                                                onChange={(e) => {
                                                    if (/^[0-9]*(\.[0-9]*)?$/.test(e.target.value) && e.target.value.length <= 4) {
                                                        setActualHr(e.target.value);
                                                    } else if (e.target.value.length === 0) {
                                                        setActualHr(e.target.value);
                                                    }
                                                    actualhr?.length < 0
                                                        ? setActualHrErr(true)
                                                        : setActualHrErr(false);
                                                }}
                                                readOnly={actualHours || actualHours === 0 ? true : false} />

                                            {actualhrErr ? (
                                                <p className="errMsg">
                                                    <Icon.ExclamationTriangle className="me-1" size={12} />Please enter valid actual hours
                                                </p>
                                            ) : null}
                                        </Form.Group>
                                    </Col>

                                    <Col md={6} className="mt-2">
                                        <Form.Group controlId="formBasiContact">
                                            <Form.Label className=" mt-3 fw-bold">
                                                Actual Minutes <span style={{ color: "red" }}>*</span>
                                            </Form.Label>
                                            <Form.Control
                                                // value={actualminute}
                                                value={actualminute === "0" ? "0" : actualminute}
                                                name="actualminute"
                                                onChange={(e) => {
                                                    if (/^[0-9]{0,2}$/.test(e.target.value) && e.target.value >= 0 && e.target.value <= 59) {
                                                        setActualMinute(e.target.value);
                                                    } else if (e.target.value > 59) {
                                                        toast.error('Please enter a value between 0 and 59.')
                                                    }
                                                    else if (e.target.value.length === 0) {
                                                        setActualMinute(e.target.value);
                                                    }
                                                    actualminute?.length < 0
                                                        ? setActualMinuteErr(true)
                                                        : setActualMinuteErr(false);
                                                }}
                                                readOnly={actulMinutes || actulMinutes === 0 ? true : false}
                                            />

                                            {actualminuteErr ? (
                                                <p className="errMsg">
                                                    <Icon.ExclamationTriangle className="me-1" size={12} />Please enter valid actual Minutes
                                                </p>
                                            ) : null}
                                        </Form.Group>
                                    </Col>
                                </>

                            ) :
                            (
                                <>
                                    <Col md={6} className="mt-2">
                                        <Form.Group controlId="formBasiContact">
                                            <Form.Label className=" mt-3 fw-bold">
                                                Actual Hours <span style={{ color: "red" }}>*</span>
                                            </Form.Label>
                                            <Form.Control
                                                value={actualhr}
                                                name="actualhr"
                                                onChange={(e) => {
                                                    if (/^[0-9]*(\.[0-9]*)?$/.test(e.target.value) && e.target.value.length <= 4) {
                                                        setActualHr(e.target.value);
                                                    } else if (e.target.value.length === 0) {
                                                        setActualHr(e.target.value);
                                                    }
                                                    actualhr?.length < 0
                                                        ? setActualHrErr(true)
                                                        : setActualHrErr(false);
                                                }}
                                                readOnly={actualHoursAllocate || actualHoursAllocate === 0 ? true : false} />

                                            {actualhrErr ? (
                                                <p className="errMsg">
                                                    <Icon.ExclamationTriangle className="me-1" size={12} />Please enter valid actual hours
                                                </p>
                                            ) : null}
                                        </Form.Group>
                                    </Col>

                                    <Col md={6} className="mt-2">
                                        <Form.Group controlId="formBasiContact">
                                            <Form.Label className=" mt-3 fw-bold">
                                                Actual Minute <span style={{ color: "red" }}>*</span>
                                            </Form.Label>
                                            <Form.Control
                                                value={actualminute === "0" ? "0" : actualminute}
                                                name="actualminute"
                                                onChange={(e) => {
                                                    if (/^[0-9]{0,2}$/.test(e.target.value) && e.target.value >= 0 && e.target.value <= 59) {
                                                        setActualMinute(e.target.value);
                                                    } else if (e.target.value > 59) {
                                                        toast.error('Please enter a value between 0 and 59.')
                                                    }
                                                    else if (e.target.value.length === 0) {
                                                        setActualMinute(e.target.value);
                                                    }
                                                    actualminute?.length < 0
                                                        ? setActualMinuteErr(true)
                                                        : setActualMinuteErr(false);
                                                }}
                                                readOnly={actulMinutesAllocate || actulMinutesAllocate === 0 ? true : false} />

                                            {actualhrErr ? (
                                                <p className="errMsg">
                                                    <Icon.ExclamationTriangle className="me-1" size={12} />Please enter valid actual hours
                                                </p>
                                            ) : null}
                                        </Form.Group>
                                    </Col>

                                </>
                            )
                        }


                        <Col md={12} className="mt-2">
                            <Form.Group controlId="formBasiContact">
                                <Form.Label>
                                    Remark <span style={{ color: "red" }}>*</span>
                                </Form.Label>
                                <Form.Control
                                    as="textarea"
                                    value={remark}
                                    name="remark"
                                    onChange={(e) => {
                                        if (
                                            !e.target.value.match(
                                                /[#$&%!~=^_{}\[\]*|//\/?<>;`/\\]/
                                            )
                                        ) {
                                            setRemark(e.target.value);
                                        } else if (e.target.value.length === 0) {
                                            setRemark(e.target.value);
                                        }
                                        remark.length >= 0
                                            ? setremarkErr(false)
                                            : setremarkErr(true);

                                    }}
                                />
                                {remarkErr ? (
                                    <p className="errMsg">
                                        <Icon.ExclamationTriangle className="me-1" size={12} />Please enter remark
                                    </p>
                                ) : null}
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="primaryBtn"
                        onClick={(e) => handleSubmit(e)}
                    >
                        Update Task
                    </Button>
                </Modal.Footer>
            </Modal>
        </>

    )
}

export default CompleteSelfSubTaskModel
