import axios from 'axios';
import React, { useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getAllTasks } from '../AllTaskSlice';

const DeleteTaskModel = ({ show, close, taskId }) => {

  const loginUserData = useSelector((state) => state.loginData.userData)

  // toast notiofication for delete
  const DeleteTaskToast = () => {
    toast.success("Deleted Task Successfully", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 1000
    });
  };


  useEffect(() => {
    // Create an SSE connection
    const eventSource = new EventSource(`${process.env.REACT_APP_BASE_URL}/sse`);

    // Listen for SSE messages
    eventSource.addEventListener('taskDeleted', (event) => {
      const deletedTaskId = JSON.parse(event.data);
      // Update the UI to reflect the deleted task in real-time
      // You can dispatch an action or manipulate the state here
      console.log(`Task with ID ${deletedTaskId} was deleted in real-time.`);
    });

    return () => {
      // Close the SSE connection when the component unmounts
      eventSource.close();
    };
  }, []);


  const deleteTaskData = async (e) => {
    e.preventDefault();
    let payload = {
      taskId: taskId,
    };
    try {
      await axios.post(
        process.env.REACT_APP_BASE_URL +
        (loginUserData?.resourceType === "employee"
          ? "/task/deleteSubTask"
          : "/task/deleteTask"),
        payload
      )
        .then((res) => {
          DeleteTaskToast()
          setTimeout(() => {
            close()
          }, 1000)
        })
        .catch((error) => {
          console.log("error", error);
        })
    } catch (error) {
      console.log("Error", error);
    }
  };
  return (
    <>
      <ToastContainer />
      <Modal show={show} onHide={close} centered backdrop="static" keyboard={false}>
        <Modal.Header closeButton style={{ color: "black" }}>
          <Modal.Title><h5>Delete Employee</h5></Modal.Title>
        </Modal.Header>
        <Modal.Body className='py-4'>
          <p style={{ color: "black", fontSize: "15px", margin: '0px' }}>
            Are you sure you want to delete this Records?👎
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button className='secondaryBtn' onClick={close}>
            Cancel
          </Button>
          <Button variant="danger"
            onClick={(e) => deleteTaskData(e)}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal >
    </>

  )
}

export default DeleteTaskModel