import React, { useState } from 'react';
import { Button, Card, Col, Container, Modal, Row, Form, Spinner, InputGroup } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { setLogout } from '../Auth/Login/LoginSlice';
import { setProjects } from '../Auth/Login/ProjectSlice';
import { setAllStateEmpty } from '../Employee/EmpSlice';
import { setActiveKey } from './ActiveKeySlice';
import { setActiveDashToggle, setActiveToggle } from './AllTaskSlice';
import { useNavigate } from 'react-router-dom';

const Profile = () => {
    const userState = useSelector((state) => state.loginData.userData);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [show, setShow] = useState(false);
    const [loader, setLoader] = useState(false);

    const [oldPass, setOldPass] = useState();
    const [newPass, setNewPass] = useState();
    const [confirmPass, setConfirmPass] = useState();

    const [showOldPass, setShowOldPass] = useState(false);
    const [showNewPass, setShowNewPass] = useState(false);
    const [showConfPass, setShowConfPass] = useState(false);

    const [oldPassErr, setOldPassErr] = useState(false);
    const [newPassErr, setNewPassErr] = useState(false);
    const [confirmPassErr, setConfirmPassErr] = useState(false);

    const closeModal = () => {
        setOldPass('');
        setNewPass('');
        setConfirmPass('');

        setOldPassErr(false);
        setNewPassErr(false);
        setConfirmPassErr(false);

        setShow(false);
    }


    const ifValidate = () => {

        let isValid = true;
        if (!oldPass) {
            isValid = false;
            setOldPassErr(true);
        }
        else if (!newPass) {
            isValid = false;
            setNewPassErr(true);
        }
        else if (!confirmPass) {
            isValid = false;
            setConfirmPassErr(true);
        }
        else if (newPass !== confirmPass) {
            isValid = false;
            toast.error('Sorry, New & Confirm password doesnt matched');
        }
        else if (oldPass === newPass) {
            isValid = false;
            toast.error('Sorry, old & new password cant be same');

        }
        return isValid;
    }

    const updatePass = async () => {
        setLoader(true);
        if (ifValidate()) {
            await fetch(process.env.REACT_APP_BASE_URL + '/emp/changePassword', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ empId: userState?.empId, password: oldPass, newPassword: newPass, })
            }).then((res) => res.json()).then((response) => {
                if (response.message === 'User not found') {
                    setLoader(false);
                    toast.error('Sorry, employee not found');
                }
                if (response.message === 'Invalid password') {
                    setLoader(false);
                    toast.error('Sorry, Old password is incorrect');
                }
                if (response.message === 'Password changed successfully') {
                    setLoader(false);
                    toast.success('Password updated successfully');
                    setShow(false);
                    setTimeout(() => {
                        dispatch(setLogout(null));
                        dispatch(setProjects(null));
                        dispatch(setAllStateEmpty([]));
                        dispatch(setActiveKey(1));
                        dispatch(setActiveToggle('pending'));
                        dispatch(setActiveDashToggle('Self Dashboard'));
                        navigate('/');
                    }, 2000);
                }
            }).catch((err) => {
                console.log("Err while updating pass", err);
            })
        }
        else {
            setLoader(false);
        }
    }
    return (
        <div className='profile outletPadding'>
            <ToastContainer />
            <Container>
                <Row>
                    <Col md={4}>
                        <Button className='primaryBtn me-3' onClick={() => window.history.back()}>
                            <Icon.ArrowLeftShort className='me-1' size={18} />
                            Back
                        </Button>
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <div className='d-flex justify-content-between'>
                        <h2><Icon.PersonFill className='me-1 w-auto' />Profile</h2>
                        <Button className='primaryBtn w-auto' onClick={() => setShow(true)}><Icon.Lock className='me-1' />Update Password</Button>
                    </div>
                </Row>
                <Row className='mt-3'>
                    <Col md={8}>
                        <h5>Personal Details</h5>
                        <Card>
                            <div>
                                <h6><Icon.PersonFill className='me-2' />First Name -</h6>
                                <p>{userState?.firstName}</p>
                            </div>

                            <div>
                                <h6><Icon.PersonFill className='me-2' />Last Name -</h6>
                                <p>{userState?.lastName}</p>
                            </div>

                            <div>
                                <h6><Icon.PersonFill className='me-2' />Emp ID - </h6>
                                <p>{userState?.empId}</p>
                            </div>

                            <div>
                                <h6>{userState?.gender === 'male' ? <Icon.GenderMale className='me-2' /> : <Icon.GenderFemale className='me-2' />}Gender - </h6>
                                <p>{userState?.gender}</p>
                            </div>

                            <div>
                                <h6><Icon.BriefcaseFill className='me-2' />Designation - </h6>
                                <p>{userState?.designation}</p>
                            </div>

                            <div>
                                <h6><Icon.TelephoneFill className='me-2' />Mobile - </h6>
                                <p>{userState?.contactNo}</p>
                            </div>

                            <div>
                                <h6><Icon.EnvelopeFill className='me-2' />Email - </h6>
                                <p>{userState?.workEmail}</p>
                            </div>

                            <div className='d-flex w-100'>
                                <h6><Icon.PersonFill className='me-2' />Department - </h6>
                                {/* <p>{userState?.department.map((row) => row.label)}</p> */}
                                <p>{userState?.department.map((row, index) => {
                                    if (index === userState.department.length - 1) {
                                        return row.label;
                                    } else {
                                        return row.label + ', ';
                                    }
                                })}</p>
                            </div>
                        </Card>
                    </Col>
                </Row>

                <Row className='mt-4 mb-4'>
                    <Col md={8}>
                        <h5>Technical Details</h5>
                        <Card>
                            <div className='d-flex flex-column align-items-start w-100'>
                                <div className='d-flex'> <Icon.AwardFill className='me-2' /> <h6>Skills - </h6> </div>
                                <p>{userState?.empSkillName.map((row) => (row.value)).join(', ')}</p>
                            </div>

                            <div className='d-flex flex-column align-items-start w-100'>
                                <div className='d-flex'> <Icon.AwardFill className='me-2' /> <h6>Leads - </h6> </div>
                                <p>{userState?.lead_name.map((row) => row).join(', ')}</p>
                            </div>
                        </Card>
                    </Col>
                </Row>




            </Container>
            <Modal show={show} onHide={closeModal} backdrop="static" keyboard={false} centered>
                <Modal.Header closeButton>
                    <h5>Update Password</h5>
                </Modal.Header>
                <Modal.Body>

                    <Form.Group className='mb-3'>
                        <Form.Label className='noselect'>Old Password</Form.Label>
                        <InputGroup>
                            <Form.Control placeholder="Enter Old Password" value={oldPass} type={showOldPass ? "text" : "password"}
                                onChange={(e) => {
                                    setOldPass(e.target.value);
                                    e.target.value.length > 0 ? setOldPassErr(false) : setOldPassErr(true)
                                }} />
                            <InputGroup.Text onClick={() => setShowOldPass(!showOldPass)}>
                                {showOldPass ? <Icon.EyeSlash size={20} style={{ cursor: 'pointer' }} onClick={() => setShowOldPass(!showOldPass)} /> :
                                    <Icon.Eye size={20} style={{ cursor: 'pointer' }} />}
                            </InputGroup.Text>
                        </InputGroup>
                        {oldPassErr ? <p className='errMsg'>Please enter valid old password</p> : ''}
                    </Form.Group>

                    <Form.Group className='mb-3'>
                        <Form.Label className='noselect'>New Password</Form.Label>
                        <InputGroup>
                            <Form.Control placeholder="Enter New Password" value={newPass} type={showNewPass ? "text" : "password"}
                                onChange={(e) => {
                                    setNewPass(e.target.value);
                                    e.target.value.length > 0 ? setNewPassErr(false) : setNewPassErr(true)
                                }} />
                            <InputGroup.Text onClick={() => setShowNewPass(!showNewPass)}>
                                {showNewPass ? <Icon.EyeSlash size={20} style={{ cursor: 'pointer' }} onClick={() => setShowNewPass(!showNewPass)} /> :
                                    <Icon.Eye size={20} style={{ cursor: 'pointer' }} />}
                            </InputGroup.Text>
                        </InputGroup>

                        {newPassErr ? <p className='errMsg'>Please enter valid new password</p> : ''}
                    </Form.Group>

                    <Form.Group>
                        <Form.Label className='noselect'>Confirm New Password</Form.Label>
                        <InputGroup>
                            <Form.Control placeholder="Enter Confirm Password" type={showConfPass ? "text" : "password"} value={confirmPass}
                                onChange={(e) => {
                                    setConfirmPass(e.target.value);
                                    e.target.value.length > 0 ? setConfirmPassErr(false) : setConfirmPassErr(true)
                                }} />
                            <InputGroup.Text onClick={() => setShowConfPass(!showConfPass)}>
                                {showConfPass ? <Icon.EyeSlash size={20} style={{ cursor: 'pointer' }} onClick={() => setShowConfPass(!showConfPass)} /> :
                                    <Icon.Eye size={20} style={{ cursor: 'pointer' }} />}
                            </InputGroup.Text>
                        </InputGroup>

                        {confirmPassErr ? <p className='errMsg'>Please enter valid confirm password</p> : ''}
                    </Form.Group>


                </Modal.Body>
                <Modal.Footer>
                    {
                        loader ? <Spinner /> : <>
                            <Button className="secondaryBtn" onClick={closeModal} > Cancel </Button>
                            <Button className='primaryBtn' onClick={updatePass}> Update </Button></>
                    }

                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Profile;