import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getEmployee } from './MngSlice'
import Pagination from '../Common/Pagination'
import { Container, Row, Table, Button, Form, Col } from 'react-bootstrap'
import { ToastContainer } from 'react-toastify'
import * as Icon from "react-bootstrap-icons";
import { useNavigate } from 'react-router-dom'
import FilterEmpDetailsModel from '../HR/Model/FilterEmpDetailsModel'

const MngManageEmp = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const loginUserData = useSelector((state) => state.loginData.userData)
    const manageEmp = useSelector(state => state?.mngData?.mngManageEmp)

    const [docsPerPage, setDocsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [show, setShow] = useState(false);
    const [showFilter, setShowFilter] = useState(false)
   

    useEffect(() => {
        const endPoint = "/emp/getParticularTlEmployee1"
          const  payload = {
            empIdTL: loginUserData?.empId,
            empId: "",
            name: "",
            label: "",
            designation: "",
            workEmail: "",
            isApproved: "",
            resourceType:"",
            documentsPerPage: docsPerPage,
            page: currentPage
        }

        dispatch(getEmployee({ endPoint, payload }))

    }, [currentPage, docsPerPage])

    const handlerCloseModel = () => setShow(false)
    const handlerCloseFilterModel = () => setShowFilter(false)




    // function for pagination
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);

    };
    const handleDocsPerPage = (docsPageProp) => {
        setCurrentPage(1);
        setDocsPerPage(docsPageProp);
    }

    return (
        <div className="outletPadding mx-auto" data-aos="fade-up">
            <Container>
                <ToastContainer />
                <FilterEmpDetailsModel show={showFilter} close={handlerCloseFilterModel} />
                <Row className='m-3'>
                    <Col md={12} className="d-flex justify-content-end">
                        <Button
                            className="primaryBtn btn-16 px-3 d-flex align-items-center"
                            onClick={() => { setShowFilter(true) }}
                        >
                            <Icon.Funnel className='me-2' size={17} />Filter

                        </Button>
                    </Col>
                </Row>

                <div className='outer-wrapper mx-auto'>
                    <div className='table-wrapper'>
                        <table>
                            <thead>
                                <tr className='tbl-header'>
                                    <th>Sr. No.</th>
                                    <th className='assignwidth1'>Name</th>
                                    <th className='assignwidth1'>Designation</th>
                                    <th>Contact No</th>
                                    <th className='assignwidth1'>Email</th>
                                    <th>IDC</th>
                                    <th>QWD</th>
                                    <th>Slippage</th>
                                    <th>Efficiency</th>
                                    <th>Action</th>
                                </tr>

                            </thead>

                            <tbody>
                                {manageEmp?.data !== null &&
                                    manageEmp?.data !== undefined &&
                                    manageEmp?.data?.length > 0
                                    ? manageEmp?.data?.map((values, index) => {
                                        return (
                                            <>
                                                <tr>
                                                    {currentPage !== 1 ? (<td> {index + 1 + 10 * (currentPage - 1)}</td>) : <td>{index + 1}</td>}
                                                    <td>{`${values?.firstName} ${values?.lastName}`}</td>
                                                    <td>{values?.designation}</td>
                                                    <td>{values?.contactNo}</td>
                                                    <td>{values?.workEmail}</td>
                                                    <td>
                                                        {values?.idc ? values?.idc?.toFixed(2) : 0}
                                                    </td>
                                                    <td>
                                                        {values?.qwd ? values.qwd?.toFixed(2) : 0}
                                                    </td>

                                                    <td>
                                                        {values?.efficiency ? values?.efficiency?.toFixed(2) : 0}
                                                    </td>

                                                    <td>
                                                        {values?.slippage ? values?.slippage?.toFixed(2) : 0}
                                                    </td>

                                                    <td>
                                                        <span
                                                            data-toggle="tooltip"
                                                            title="view"
                                                            style={{
                                                                color: "#000000",
                                                                fontSize: "20px",
                                                            }}
                                                        >
                                                            <Icon.Eye
                                                                onClick={() =>
                                                                    navigate(
                                                                        "employee-details",
                                                                        {
                                                                            state: { data: values },
                                                                        }
                                                                    )
                                                                }
                                                            />
                                                        </span>

                                                    </td>
                                                </tr>
                                            </>
                                        );
                                    })
                                    : <tr><td colSpan={8}>
                                        No Data available
                                    </td></tr>}
                            </tbody>

                        </table>
                    </div>
                </div>
                <div className="pagination-wrapper p-0">
                    <Pagination currentPage={currentPage} totalPages={manageEmp?.noOfPages}
                        onPageChange={handlePageChange} onDocsPerPage={handleDocsPerPage} />
                </div>
            </Container>
        </div>
    )
}

export default MngManageEmp