import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const initialState = {
    EmployeeAllTask: [],
    EmpSelfReview: [],
    getFilterDropdown:[],
    getSelfReviewFilterOption:[],
    loading: 'idle', // idle, pending, failed
};

// ------------ New changes 18-07-2023 -------------  //

export const getEmployeeAllTask = createAsyncThunk("getEmployeeAllTaskPromise", async (data, { rejectWithValue }) => {
    const response = await fetch(process.env.REACT_APP_NEW_BASE_URL + '/getAllTasks', {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data)
    })
    try {
        const result = await response.json()
        return result
    } catch (error) {
        return rejectWithValue(error);
    }
}
)

export const getSelfReview = createAsyncThunk("getSelfReviewPromise", async (data, { rejectWithValue }) => {
    const response = await fetch(process.env.REACT_APP_NEW_BASE_URL + '/selfReview', {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data)
    })
    try {
        const result = await response.json()
        return result
    } catch (error) {
        return rejectWithValue(error);
    }
}
)

export const getFilterOptionData = createAsyncThunk("getFilterOptionDataPromise", async (data, { rejectWithValue }) => {
    const response = await fetch(process.env.REACT_APP_NEW_BASE_URL + '/uniqueFilterDetails', {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data)
    })
    try {
        const result = await response.json()
        return result
    } catch (error) {
        return rejectWithValue(error);
    }
}
)

export const getSelfReviewFilterOptionData = createAsyncThunk("getSelfReviewFilterOptionDataPromise", async (data, { rejectWithValue }) => {
    const response = await fetch(process.env.REACT_APP_NEW_BASE_URL + '/selfReviewFilters', {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data)
    })
    try {
        const result = await response.json()
        return result
    } catch (error) {
        return rejectWithValue(error);
    }
}
)

const EmpSlice = createSlice({
    name: 'employee',
    initialState,
    reducers: {
        setAllStateEmpty: (s, a) => {
            s.EmployeeAllTask = a.payload
            s.EmpSelfReview = a.payload
        },
    },
    extraReducers: builder => {
        builder
            //  ----new chnages 18-07-2023

            .addCase(getEmployeeAllTask.pending, (s, a) => {
                s.loading = "pending";
            })
            .addCase(getEmployeeAllTask.rejected, (s, a) => {
                s.loading = "idle";
            })
            .addCase(getEmployeeAllTask.fulfilled, (s, a) => {
                s.EmployeeAllTask = a.payload;
                s.loading = 'success';
            })

            // self reviews
            .addCase(getSelfReview.pending, (s, a) => {
                s.loading = "pending";
            })
            .addCase(getSelfReview.rejected, (s, a) => {
                s.loading = "idle";
            })
            .addCase(getSelfReview.fulfilled, (s, a) => {
                s.EmpSelfReview = a.payload;
                s.loading = 'success';
            })


            .addCase(getFilterOptionData.pending, (s, a) => {
                s.loading = "pending";
            })
            .addCase(getFilterOptionData.rejected, (s, a) => {
                s.loading = "idle";
            })
            .addCase(getFilterOptionData.fulfilled, (s, a) => {
                s.getFilterDropdown = a.payload;
                s.loading = 'success';
            })

            // selfreview filter
            .addCase(getSelfReviewFilterOptionData.pending, (s, a) => {
                s.loading = "pending";
            })
            .addCase(getSelfReviewFilterOptionData.rejected, (s, a) => {
                s.loading = "idle";
            })
            .addCase(getSelfReviewFilterOptionData.fulfilled, (s, a) => {
                s.getSelfReviewFilterOption = a.payload;
                s.loading = 'success';
            });
    },
});


export const { setAllStateEmpty } = EmpSlice.actions;
export default EmpSlice.reducer;