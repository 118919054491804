import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const initialState = {
    AllTask: [],
    FilterAllTaskData:[],
    FilterSelfTaskData:[],
    FilterReviewData: [],
    FilterAssignTaskData:[],
    FilterAssignTaskFilterData:[],
    FilterNoTaskData:[],
    FilterAllocatedTaskData:[],
    FilterResourceUtilizationData:[],
    AssignTask: [],
    EmployeeTask: [],
    AllocatedTask: [],
    Review: [],
    NoTask: [],
    ResourceUtilization:[],
    AllSelfTask:[],
    AllFilterTasksData:[],
    ActiveToggle:'pending',
    ActiveDashToggle:'Self Dashboard',
    ActiveHoursToggle:'ActualHours',
    loading: 'idle', // idle, pending, failed
};

export const getAllTasks = createAsyncThunk("getAllTasksPromise", async (data, { rejectWithValue }) => {
    const response = await fetch(process.env.REACT_APP_NEW_BASE_URL + '/getAllTasks', {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data)
    })
    try {
        const result = await response.json()
        return result
    } catch (error) {
        return rejectWithValue(error);
    }
}
)


export const getFilterAllTaskData = createAsyncThunk("getFilterAllTaskDataPromise", async (data, { rejectWithValue }) => {
    const response = await fetch(process.env.REACT_APP_NEW_BASE_URL + '/getAllTasks', {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data)
    })
    try {
        const result = await response.json()
        return result
    } catch (error) {
        return rejectWithValue(error);
    }
}
)

// for view self task
export const getAllSelfTasks = createAsyncThunk("getAllSelfTasksPromise", async (data, { rejectWithValue }) => {
    const response = await fetch(process.env.REACT_APP_NEW_BASE_URL + '/selfTask', {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data)
    })
    try {
        const result = await response.json()
        return result
    } catch (error) {
        return rejectWithValue(error);
    }
}
)

export const getSelfTaskFilterOptions = createAsyncThunk("getSelfFilterDataPromise", async (data, { rejectWithValue }) => {
    const response = await fetch(process.env.REACT_APP_NEW_BASE_URL + '/selfTaskFilters', {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data)
    })
    try {
        const result = await response.json()
        return result
    } catch (error) {
        return rejectWithValue(error);
    }
}
)

export const geAllocatedTaskFilterOptions = createAsyncThunk("getAllocatedFilterDataPromise", async (data, { rejectWithValue }) => {
    const response = await fetch(process.env.REACT_APP_NEW_BASE_URL + '/allocatedFromOtherDeptTaskFilter', {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data)
    })
    try {
        const result = await response.json()
        return result
    } catch (error) {
        return rejectWithValue(error);
    }
}
)

export const getAssignTaskFilterOptions = createAsyncThunk("getAssignFilterDataPromise", async (data, { rejectWithValue }) => {
    const response = await fetch(process.env.REACT_APP_NEW_BASE_URL + "/assignedToOtherDeptTaskFilter", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data)
    })
    try {
        const result = await response.json()
        return result
    } catch (error) {
        return rejectWithValue(error);
    }
}
)

export const getAllAssignTask = createAsyncThunk("getAllAssignTaskPromise", async (data, { rejectWithValue }) => {
    const response = await fetch(process.env.REACT_APP_NEW_BASE_URL + '/assignedToOtherDeptTask', {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data)
    })
    try {
        const result = await response.json()
        return result
    } catch (error) {
        return rejectWithValue(error);
    }
}
)

export const getAllEmployeeTasks = createAsyncThunk("getAllEmployeeTasksPromise", async (data, { rejectWithValue }) => {
    const response = await fetch(process.env.REACT_APP_NEW_BASE_URL + '/getTLAssignedEmpTask', {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data)
    })
    try {
        const result = await response.json()
        return result
    } catch (error) {
        return rejectWithValue(error);
    }
}
)

export const getFilterAssignTask = createAsyncThunk("getFilterAssignTaskPromise", async (data, { rejectWithValue }) => {
    const response = await fetch(process.env.REACT_APP_NEW_BASE_URL + '/assignedToOtherDeptTask', {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data)
    })
    try {
        const result = await response.json()
        return result
    } catch (error) {
        return rejectWithValue(error);
    }
}
)

// for allocated task
export const getAllAllocatedTask = createAsyncThunk("getAllAllocatedTaskPromise", async (data, { rejectWithValue }) => {
    const response = await fetch(process.env.REACT_APP_NEW_BASE_URL + '/allocatedFromOtherDeptTask', {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data)
    })
    try {
        const result = await response.json()
        return result
    } catch (error) {
        return rejectWithValue(error);
    }
}
)

export const getAllReview = createAsyncThunk("getAllReviewPromise", async (data, { rejectWithValue }) => {
    const response = await fetch(process.env.REACT_APP_NEW_BASE_URL + '/reviewTask', {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data)
    })
    try {
        const result = await response.json()
        return result
    } catch (error) {
        return rejectWithValue(error);
    }
}
)

export const getFilterReviewTask = createAsyncThunk("getFilterReviewTaskPromise", async (data, { rejectWithValue }) => {
    const response = await fetch(process.env.REACT_APP_NEW_BASE_URL + '/reviewTask', {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data)
    })
    try {
        const result = await response.json()
        return result
    } catch (error) {
        return rejectWithValue(error);
    }
}
)

// krupa change 09-07-2023
export const getNoTask = createAsyncThunk("getNoTasksPromise", async (data, { rejectWithValue }) => {
    const response = await fetch(process.env.REACT_APP_BASE_URL + '/emp/noAssignedTaskEmp1', {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data)
    })
    try {
        const result = await response.json()
        return result
    } catch (error) {
        return rejectWithValue(error);
    }
})

// for NoTask filter
export const getNoTaskFilterOption = createAsyncThunk("getFilterNoTaskPromise", async (data, { rejectWithValue }) => {
    const response = await fetch(process.env.REACT_APP_NEW_BASE_URL + '/noTaskFilters', {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data)
    })
    try {
        const result = await response.json()
        return result
    } catch (error) {
        return rejectWithValue(error);
    }
}
)
// for resource utilization
export const getResourceUtilization = createAsyncThunk("getResourceUtilizationPromise", async (data, { rejectWithValue }) => {
    const response = await fetch(process.env.REACT_APP_NEW_BASE_URL + '/resourceUtilization', {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data)
    })
    try {
        const result = await response.json()
        return result
    } catch (error) {
        return rejectWithValue(error);
    }
})

// for utilization filter
export const getResourceUtilizationFilterOption = createAsyncThunk("getFilterUtilizationPromise", async (data, { rejectWithValue }) => {
    const response = await fetch(process.env.REACT_APP_NEW_BASE_URL + '/resourceUtilizationFilters', {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data)
    })
    try {
        const result = await response.json()
        return result
    } catch (error) {
        return rejectWithValue(error);
    }
}
)

const AllTaskSlice = createSlice({
    name: 'allTasks',
    initialState,
    reducers:{
        setActiveToggle(state, action){
          state.ActiveToggle = action.payload
        },
        setActiveDashToggle(state, action){
          state.ActiveDashToggle = action.payload
        },
        setActiveHoursToggle(state,action){
            state.ActiveHoursToggle = action.payload
        },
        setFilterTasksData(state,action){
            state.AllFilterTasksData = action.payload
        }
      },
    extraReducers: {
        [getAllTasks.pending]: (s, a) => {
            s.loading = 'pending';
        },
        [getAllTasks.rejected]: (s, a) => {
            s.loading = 'idle';
        },
        [getAllTasks.fulfilled]: (s, a) => {
            s.AllTask = a.payload;
            s.loading = 'success';
        },

        [getAllSelfTasks.pending]: (s, a) => {
            s.loading = 'pending';
        },
        [getAllSelfTasks.rejected]: (s, a) => {
            s.loading = 'idle';
        },
        [getAllSelfTasks.fulfilled]: (s, a) => {
            s.AllSelfTask = a.payload;
            s.loading = 'success';
        },

        [getSelfTaskFilterOptions.pending]: (s, a) => {
            s.loading = 'pending';
        },
        [getSelfTaskFilterOptions.rejected]: (s, a) => {
            s.loading = 'idle';
        },
        [getSelfTaskFilterOptions.fulfilled]: (s, a) => {
            s.FilterSelfTaskData = a.payload;
            s.loading = 'success';
        },

        [getFilterAllTaskData.pending]: (s, a) => {
            s.loading = 'pending';
        },
        [getFilterAllTaskData.rejected]: (s, a) => {
            s.loading = 'idle';
        },
        [getFilterAllTaskData.fulfilled]: (s, a) => {
            s.FilterAllTaskData = a.payload;
            s.loading = 'success';
        },

        [getAllAssignTask.pending]: (s, a) => {
            s.loading = 'pending';
        },
        [getAllAssignTask.rejected]: (s, a) => {
            s.loading = 'idle';
        },
        [getAllAssignTask.fulfilled]: (s, a) => {
            s.AssignTask = a.payload;
            s.loading = 'success';
        },
       
         [getAllEmployeeTasks.pending]: (s, a) => {
            s.loading = 'pending';
        },
        [getAllEmployeeTasks.rejected]: (s, a) => {
            s.loading = 'idle';
        },
        [getAllEmployeeTasks.fulfilled]: (s, a) => {
            s.EmployeeTask = a.payload;
            s.loading = 'success';
        },

        [getFilterAssignTask.pending]: (s, a) => {
            s.loading = 'pending';
        },
        [getFilterAssignTask.rejected]: (s, a) => {
            s.loading = 'idle';
        },
        [getFilterAssignTask.fulfilled]: (s, a) => {
            s.FilterAssignTaskData = a.payload;
            s.loading = 'success';
        },

        [getAllAllocatedTask.pending]: (s, a) => {
            s.loading = 'pending';
        },
        [getAllAllocatedTask.rejected]: (s, a) => {
            s.loading = 'idle';
        },
        [getAllAllocatedTask.fulfilled]: (s, a) => {
            s.AllocatedTask = a.payload;
            s.loading = 'success';
        },

        [getNoTask.pending]: (s, a) => {
            s.loading = 'pending';
        },
        [getNoTask.rejected]: (s, a) => {
            s.loading = 'idle';
        },
        [getNoTask.fulfilled]: (s, a) => {
            s.NoTask = a.payload;
            s.loading = 'success';
        },

        [getResourceUtilization.pending]: (s, a) => {
            s.loading = 'pending';
        },
        [getResourceUtilization.rejected]: (s, a) => {
            s.loading = 'idle';
        },
        [getResourceUtilization.fulfilled]: (s, a) => {
            s.ResourceUtilization = a.payload;
            s.loading = 'success';
        },

         [getResourceUtilizationFilterOption.pending]: (s, a) => {
            s.loading = 'pending';
        },
        [getResourceUtilizationFilterOption.rejected]: (s, a) => {
            s.loading = 'idle';
        },
        [getResourceUtilizationFilterOption.fulfilled]: (s, a) => {
            s.FilterResourceUtilizationData = a.payload;
            s.loading = 'success';
        },


        [getAllReview.pending]: (s, a) => {
            s.loading = 'pending';
        },
        [getAllReview.rejected]: (s, a) => {
            s.loading = 'idle';
        },
        [getAllReview.fulfilled]: (s, a) => {
            s.Review = a.payload;
            s.loading = 'success';
        },

        [getFilterReviewTask.pending]: (s, a) => {
            s.loading = 'pending';
        },
        [getFilterReviewTask.rejected]: (s, a) => {
            s.loading = 'idle';
        },
        [getFilterReviewTask.fulfilled]: (s, a) => {
            s.FilterReviewData = a.payload;
            s.loading = 'success';
        },

        [getNoTaskFilterOption.pending]: (s, a) => {
            s.loading = 'pending';
        },
        [getNoTaskFilterOption.rejected]: (s, a) => {
            s.loading = 'idle';
        },
        [getNoTaskFilterOption.fulfilled]: (s, a) => {
            s.FilterNoTaskData = a.payload;
            s.loading = 'success';
        },

        [geAllocatedTaskFilterOptions.pending]: (s, a) => {
            s.loading = 'pending';
        },
        [geAllocatedTaskFilterOptions.rejected]: (s, a) => {
            s.loading = 'idle';
        },
        [geAllocatedTaskFilterOptions.fulfilled]: (s, a) => {
            s.FilterAllocatedTaskData = a.payload;
            s.loading = 'success';
        },

        [getAssignTaskFilterOptions.pending]: (s, a) => {
            s.loading = 'pending';
        },
        [getAssignTaskFilterOptions.rejected]: (s, a) => {
            s.loading = 'idle';
        },
        [getAssignTaskFilterOptions.fulfilled]: (s, a) => {
            s.FilterAssignTaskFilterData = a.payload;
            s.loading = 'success';
        },
    },
});

export const {setActiveToggle,setActiveDashToggle,setActiveHoursToggle,setFilterTasksData} = AllTaskSlice.actions;
export default AllTaskSlice.reducer;
