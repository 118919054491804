import React, { useEffect, useState } from "react";
import {Container, Button, Row, Col, ButtonGroup, ToggleButton} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import * as Icon from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";

import normal from "../../images/gif/frame-88.png";
import medium from "../../images/gif/Ripple-6.3s-200px.gif";
import immediate from "../../images/gif/Ripple-1s-200px (1).gif";
import Badge from "react-bootstrap/Badge";
import CreateTaskModel from "./Model/CreateTaskModel";
import { getAllEmployeeTasks, setActiveToggle ,setFilterTasksData} from "./AllTaskSlice";
import Pagination from "./Pagination";
import DeleteTaskModel from "./Model/DeleteTaskModel";
import EditTaskModel from "./Model/EditTaskModel";
import ReviewModel from "./Model/AddReviewModel";
import { CreateAdminTaskModel } from "../Admin/Model/CreateAdminTaskModel";
import FilterEmployeeTaskModel from "./Model/FilterEmployeeTaskModel";
import DisplayHoursAndMinutes from "./Convertor/DisplayHoursAndMinutes";

const EmployeeTasks = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loginUserData = useSelector((state) => state.loginData.userData);
  const employeeTaskData = useSelector((state) => state?.AllTasks?.EmployeeTask);
  const filterAllTasks = useSelector((state) => state?.AllTasks?.AllFilterTasksData);
  const currentToggle = useSelector((state) => state?.AllTasks?.ActiveToggle);

  // for status toggle  button
  const toggle = [{ name: 'Pending', value: 'pending' }, { name: 'Completed', value: 'completed' }];

  const [docsPerPage, setDocsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [show, setShow] = useState(false);
  const [showdeletemodal, setShowDeleteModal] = useState(false);
  const [showfilter, setShowFilter] = useState(false);
  const [taskId, setTaskId] = useState("");
  const [showreview, setShowReview] = useState(false)
  const [item, setItem] = useState();
  const [showEdit, setShowEdit] = useState(false);

  // for filter tab value reset
  const [pendingFilterValues, setPendingFilterValues] = useState({});
  const [completedFilterValues, setCompletedFilterValues] = useState({});

  // for show data not found
  const [showNoDataFound, setShowNoDataFound] = useState(false);

  // for no data found true false
  const handleFilterTrue = () => {
    setShowNoDataFound(true)
  }

  const handleFilterFalse = ()=>{
    setShowNoDataFound(false)
  }

  // for status toggle change
  const handleToggleChange = (toggleValue) => {
    dispatch(setActiveToggle(toggleValue));
    if (toggleValue === 'pending') {
      setCompletedFilterValues({});
    } else {
      setPendingFilterValues({});
    }
  };

  const getEmployeeTask = () => {
    const payload = {
      "empId": loginUserData.empId,
      "status": currentToggle,
      "taskPriority":"",
      "projectName":"",
      "categoryOfName":"",
      "resource_id":"",
      "proposedStartDate":"",
      "proposedEndDate":"",
      "idcMin": "",
      "idcMax": "",
      "qwdMin": "",
      "qwdMax": "",
      "slippage": "",
      "efficiency": "",
      "documentsPerPage": docsPerPage,
      "page": currentPage,
    };
    dispatch(getAllEmployeeTasks(payload))
  }

  useEffect(() => {
    getEmployeeTask()
  }, [currentPage, docsPerPage, currentToggle]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleDocsPerPage = (docsPageProp) => {
    setCurrentPage(1);
    setDocsPerPage(docsPageProp);
  };
  const handlerCloseFilterModel = () => setShowFilter(false)

  // function for create task model
  const handleCloseCreateModel = () => { getEmployeeTask(); setShow(false) };

  // for delete modal
  const handleShowDeleteModal = (id) => {
    setTaskId(id);
    setShowDeleteModal(true);
  };

  const handleDeleteModalClose = () => {
    getEmployeeTask()
    setShowDeleteModal(false);
    setTaskId("");
  };

  // for review function
  const handleShowReviewModal = (id) => {
    setTaskId(id)
    setShowReview(true)
  }

  const handleReviewModalClose = () => {
    setShowReview(false)
    setTaskId("")
    getEmployeeTask()
  }

  // for edit open and close modal
  const handleShowEditModal = (id) => {
    setItem(id);
    setShowEdit(id);
  };
  const handlaEditModalClose = (id) => {
    setShowEdit(false);
    setItem("");
    getEmployeeTask()
  };

  return (
    <div className="AssignTask outletPadding" data-aos="fade-up">
     <FilterEmployeeTaskModel 
          show={showfilter} 
          close={handlerCloseFilterModel} 
          handleFilterTrue = {handleFilterTrue}
          handlerFilterFalse = {handleFilterFalse}
          showNoDataFound={showNoDataFound}
          taskStatus={currentToggle} 
          currentPage={currentPage}
          docsPerPage={docsPerPage}
          filterValues={
            currentToggle === 'pending'
              ? pendingFilterValues
              : completedFilterValues
          }/>

    {loginUserData?.resourceType === "admin" ? 
      show &&
      <CreateAdminTaskModel show={show} close={handleCloseCreateModel} userData={loginUserData}/> :
      show &&
      <CreateTaskModel show={show} close={handleCloseCreateModel} userData={loginUserData} />
    }

    {
      showreview &&
      <ReviewModel show={showreview} close={handleReviewModalClose} taskId={taskId} />
    }

      {
        showdeletemodal &&
        <DeleteTaskModel show={showdeletemodal} close={handleDeleteModalClose} taskId={taskId} />
      }

      {
        showEdit &&
        <EditTaskModel show={showEdit} close={handlaEditModalClose} taskId={taskId} item={item} userData={loginUserData} />
      }

      <Container>
        <Row className='d-flex justify-content-between align-items-center'>
          <Col md={6} className="d-flex justify-content-start align-items-center">
            <Button
              className="primaryBtn me-3"
                onClick={() => { setShow(true) }}
            >
              <Icon.PlusCircle className="me-2" size={16} />
              Create Task
            </Button>
            <Button
              className={` ${filterAllTasks?.data?.length > 0 || showNoDataFound  ? 'activeBtn': 'primaryBtn'}`}
              onClick={() => setShowFilter(true)}
            >
              <Icon.Funnel className='me-2' size={17} />
              Filter
            </Button>
          </Col>

          <Col sm={12} md={6} className='d-flex justify-content-end align-items-center '>
             <ButtonGroup aria-label="Basic example">
              <Button
                disabled={currentToggle === "pending" ? true : false}
                variant={currentToggle === 'pending' ? 'warning' : 'outline-warning'} // Use 'warning' when active, 'light' otherwise
                onClick={() => {handleToggleChange('pending');dispatch(setFilterTasksData([]));setShowNoDataFound(false)
                }}
              >
                Pending
              </Button>
              <Button
                disabled={currentToggle === "completed" ? true : false}
                variant={currentToggle === 'completed' ? 'success' : 'outline-success'}
                onClick={() => {handleToggleChange('completed');dispatch(setFilterTasksData([]));setShowNoDataFound(false)}}
              >
                Completed
              </Button>
            </ButtonGroup>
          </Col>
          {/* <Col sm={12} md={6} className='d-flex justify-content-end align-items-center mt-3 mt-md-0'>
            <ButtonGroup>
              {toggle.map((radio, idx) => (
                <ToggleButton
                  key={idx}
                  id={`radio-${idx}`}
                  type="radio"
                  variant={idx % 2 ? 'outline-success' : 'outline-warning'}
                  name="radio"
                  value={radio.value}
                  checked={currentToggle === radio.value}
                  onChange={(e) => { handleToggleChange(radio.value); handleDocsPerPage(10) }}
                >
                  {radio.name}
                </ToggleButton>
              ))}
            </ButtonGroup>
          </Col> */}
        </Row>
        <Row className="mt-4">
          <Col md={12}>
            <h5>* Assign Employee By You</h5>
          </Col>
        </Row>

        <div className="outer-wrapper mt-1 mx-auto">
          <div className="table-wrapper">
            <table>
              <thead>
                <tr>
                  <th>Priority</th>
                  {currentToggle === "pending" && 
                  <th>Due(Days)</th>}
                  <th className="taskwidth">Task Name</th>
                  <th>Department</th>
                  <th>Project</th>
                  <th className = "max-width-th">Category</th>
                  <th className="assignwidth">Assign To</th>
                  <th>Date</th>
                  <th>Initial Hr</th>
                  {
                    currentToggle === "completed" &&
                    <>
                      <th>Actual Hr</th>
                      <th>IDC</th>
                      <th>QWD</th>
                      <th>Slippage</th>
                      <th>Efficiency</th>
                      <th>Review</th>
                    </>}
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              {filterAllTasks?.data?.length > 0 || !showNoDataFound ? (
              <tbody>
                {employeeTaskData?.data !== null &&
                  employeeTaskData?.data !== undefined &&
                  employeeTaskData?.data.length > 0 ? (
                    (filterAllTasks?.data?.length > 0 
                      ? filterAllTasks?.data
                      : employeeTaskData?.data
                    )?.map((itm,index) => {
                      const {
                        taskName,
                        taskPriority,
                        taskDepartmentName,
                        projectName,
                        isRejected,
                        resourceName,
                        proposedStartDate,
                        proposedEndDate,
                        status,
                        idc,
                        qwd,
                        slippage,
                        efficiency,
                        taskId,
                        categoryOfName,
                        initialEstimateHr,
                        actualHours
                      } = itm;

                      // for due days
                      const currentDate = new Date();
                      const endDate = new Date(itm.proposedEndDate);
                      const endTimestamp = Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());
                      const currentTimestamp = Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());

                      let daysDifference = 0; 
                      if (currentTimestamp > endTimestamp) {
                        const differenceMillis = Math.abs(currentTimestamp - endTimestamp);
                        daysDifference = Math.ceil(differenceMillis / (1000 * 60 * 60 * 24));
                      }
                      
                      return (
                        <tr key={Math.random() * 999999999}>
                          <td>
                            {taskPriority === "normal" ? (
                              <img
                                width={50}
                                height={50}
                                src={normal}
                                data-toggle="tooltip"
                                title="Normal"
                                alt="Normal"
                              />
                            ) : taskPriority === "medium" ? (
                              <img
                                width={50}
                                height={50}
                                src={medium}
                                data-toggle="tooltip"
                                title="Medium"
                                alt="Medium"
                              />
                            ) : taskPriority === "immediate" ? (
                              <img
                                width={50}
                                height={50}
                                src={immediate}
                                data-toggle="tooltip"
                                title="Immediate"
                                alt="Immediate"
                              />
                            ) : (
                              ""
                            )}
                          </td>
                          {currentToggle === "pending" && 
                        <td>
                            <div className={`circle ${daysDifference < 5 ? 'green' : daysDifference < 15 ? 'orange' : 'red'}`} style = {{height:'35px',width:'35px',borderRadius:'55%',margin:'auto'}}>
                               <span className="number">{Math.floor(daysDifference)}</span>
                            </div>
                        </td>
                        }
                          <td >{taskName}</td>
                          <td>{taskDepartmentName}</td>
                          <td>{projectName}</td>
                          <td>{categoryOfName}</td>
                          <td>{resourceName}</td>
                          {/* <td>{initialEstimateHr ? initialEstimateHr.toFixed(2) : 0}</td> */}
                          <td>
                            {new Date(proposedStartDate).toLocaleDateString(
                              "en-US",
                              {
                                month: "long",
                                year: "numeric",
                              }
                            ) ===
                              new Date(proposedEndDate).toLocaleDateString(
                                "en-US",
                                { month: "long", year: "numeric" }
                              )
                              ? `${new Date(
                                proposedStartDate
                              ).getDate()} - ${new Date(
                                proposedEndDate
                              ).getDate()} ${new Date(
                                proposedStartDate
                              ).toLocaleDateString("en-US", {
                                month: "long",
                                year: "numeric",
                              })}`
                              : `${new Date(
                                proposedStartDate
                              ).getDate()} ${new Date(
                                proposedStartDate
                              ).toLocaleDateString("en-US", {
                                month: "long",
                                year: "numeric",
                              })} -  ${new Date(
                                proposedEndDate
                              ).getDate()} ${new Date(
                                proposedEndDate
                              ).toLocaleDateString("en-US", {
                                month: "long",
                                year: "numeric",
                              })}`}
                          </td>
                          <td><DisplayHoursAndMinutes value = {initialEstimateHr}/></td>
                          {
                            currentToggle === "completed" &&
                            <>
                              <td><DisplayHoursAndMinutes value = {actualHours}/></td>
                              <td>{idc ?? 0}</td>
                              <td>{qwd ?? 0}</td>
                              <td>{slippage !== null && slippage !== undefined ? slippage.toFixed(2) : 0}</td>
                              <td>{efficiency !== null && efficiency !== undefined ? efficiency.toFixed(2) : 0}</td>
                                <td>
                                  {
                                    currentToggle === "completed" ?
                                      <>
                                        {!idc && !qwd ? <div data-toggle="modal" style={{ color: "#292929" }}>
                                          <Button className="primaryBtn" style={{ padding: '4px 12px' }} onClick={() => { handleShowReviewModal(taskId) }}>Review</Button>
                                        </div> :
                                       <span data-toggle="tooltip" title="Reviewed" aria-disabled style={{display: 'flex', justifyContent: 'center', // Center horizontally
                                        alignItems: 'center'}}><Icon.CheckSquareFill color = "green" fontSize={23}/></span>
                                          }
                                      </>
                                      : "___"}
                                </td>
                            </>
                          }
                          <td className="text-capitalize">
                            <Badge bg={status === "completed" ? "success p-1 text-white" : "warning text-dark p-1"}>{status}</Badge>
                          </td>
                          <td>
                            <span
                              data-toggle="tooltip"
                              className="me-2"
                              title="view"
                              style={{
                                color: "#000000",
                                fontSize: "20px",
                              }}
                            >
                              <Icon.Eye
                                onClick={() => {
                                  navigate(
                                    `/${loginUserData?.resourceType}/all-task/view-details`,
                                    {
                                      state: { data: itm },
                                    }
                                  );
                                }}
                              />
                            </span>
                            {currentToggle === "pending" &&
                            <>
                             <span
                              className="me-2"
                              data-toggle="tooltip"
                              title="edit"
                              style={{
                                color: "#000000",
                                fontSize: "20px",
                              }}
                            >
                              <Icon.PencilSquare
                                onClick={() => {
                                  handleShowEditModal(itm);
                                }}
                              />
                            </span>
                            <span
                              className="me-2"
                              data-toggle="tooltip"
                              title="delete"
                              style={{
                                color: "#000000",
                                fontSize: "20px",
                              }}
                            >
                              <Icon.Trash
                                onClick={() => {
                                  handleShowDeleteModal(taskId);
                                }}
                              />
                            </span> 
                            </>
                           
                            }
                            
                          </td>
                        </tr>
                      );
                    })
                  ):(
                        <tr>
                          <td colSpan="18" className="text-center">No data available</td>
                        </tr>
                  )}
              </tbody>
              ): 
                <tr>
                  <td colSpan="18" className="text-center">No Task Available</td>
                </tr>
              }
            </table>
          </div>
        </div>


       {
          employeeTaskData?.data !== null || employeeTaskData?.data?.length > 0 ?
            <div className="pagination-wrapper p-0">
              <Pagination 
                currentPage={currentPage} 
                totalPages={filterAllTasks?.data?.length > 0 ? filterAllTasks?.noOfPages : employeeTaskData?.noOfPages}
                onPageChange={handlePageChange} 
                onDocsPerPage={handleDocsPerPage} 
                docsPerPage={docsPerPage}
              />
           </div>
            : ''
                }
      </Container>
    </div>
  );
};

export default EmployeeTasks;
