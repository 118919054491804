import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import * as Icon from 'react-bootstrap-icons';
import { Button, Spinner, Accordion, OverlayTrigger, Popover, Nav, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setLogout } from '../Auth/Login/LoginSlice';
import { setActiveKey } from '../Common/ActiveKeySlice';
import { setProjects } from '../Auth/Login/ProjectSlice';
import { setAllStateEmpty } from '../Employee/EmpSlice';
import { getAllTasks, setActiveDashToggle, setActiveToggle } from './AllTaskSlice';

const Menubar = () => {

  const userState = useSelector(state => state.loginData.userData);
  const activeKeyRedux = useSelector(state => state.activeData.activeNumberKey);
  const roleOfUser = ["employee", "contractual", "intern"]

  function setnav() {
    const mobilenav = document.querySelector(".mobile-nav");
    const toggle = document.querySelector(".mobile-nav-toggle");

    const visibility = mobilenav.getAttribute('data-visible');
    if (visibility === "false") {
      mobilenav.setAttribute("data-visible", true);
      toggle.setAttribute("aria-expanded", true);
      document.querySelector("body").style.overflow = 'hidden';
    }
    else if (visibility === "true") {
      mobilenav.setAttribute("data-visible", false);
      toggle.setAttribute("aria-expanded", false);
      document.querySelector("body").style.overflow = 'auto';
    }
  }


  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleLogout = () => {
    setLoader(true);
    dispatch(setLogout(null));
    dispatch(setProjects(null));
    dispatch(setAllStateEmpty([]))
    dispatch(setActiveKey(1));
    dispatch(setActiveToggle('pending'));
    dispatch(setActiveDashToggle('Self Dashboard'));
    dispatch(getAllTasks(null));
    navigate('/');
    localStorage.removeItem('persist:root')
    setLoader(false);
  }

  const entering = (e) => {
    e.children[0].style.borderTopColor = 'white';
    e.children[1].style.backgroundColor = 'white';
    e.children[0].style.borderRadius = '12px';
    e.children[1].style.borderRadius = '12px';
  };

  const handleAccordionChange = (activeKey, subActiveKey, tabName) => {
    dispatch(setActiveKey({ activeKey: activeKey, subActiveKey: subActiveKey, activeTab: tabName }));
  };

  const Popup = (
    <Popover>
      <Popover.Body>
        <div className='PopupData'>
          <div>
            <Icon.Person className='me-1' size={18} /><p><strong>Name - </strong>{userState?.firstName + " " + userState?.lastName}</p>
          </div>

          <div>
            <Icon.Briefcase className='me-2' size={14} /><p><strong>Role - </strong>{userState.designation}</p>
          </div>
          <div>
            <Icon.Award className='me-2' size={15} /><p><strong>Dept. - </strong>{userState.department[0].label}</p>
          </div>
          <div>
            <Icon.Mortarboard className='me-2' size={15} /><p><strong>Exp. - </strong>{userState.experience}</p>
          </div>
          <div>
            <Icon.Envelope className='me-2' size={15} /><p><strong>Email - </strong>{userState.workEmail}</p>
          </div>
          <div>
            {userState.isApproved ? <Icon.ToggleOn className='me-2' size={15} /> : <Icon.ToggleOff className='me-2' size={15} />}
            <p><strong>Status - </strong>{userState.isApproved ? "Approved" : "Pending"}</p>
          </div>
        </div>
      </Popover.Body>
    </Popover>
  )

  return (

    <nav>
      <Link className='d-flex align-items-center'>
        <h5 className='mb-0 ms-2'>{activeKeyRedux.activeTab}</h5>
      </Link>

      <div className='d-flex align-items-center'>
        <Icon.PersonFill className='me-2 d-block d-lg-none' size={35} style={{ cursor: 'pointer' }}
          onClick={() => { navigate(`/${userState?.resourceType}/profile`); dispatch(setActiveKey({ activeKey: activeKeyRedux.activeKey, activeTab: 'Profile' })); }} />
        <div className="mobile-nav-toggle" aria-controls='selectNav' aria-expanded="false" onClick={setnav}>
          <div className='menu-btn__burger'></div>
        </div>
      </div>


      <ul className="lg-nav">
        <OverlayTrigger key='bottom' placement='bottom' overlay={Popup} onEntering={entering}>
          <div className='d-flex' style={{ border: 'none', cursor: 'pointer' }} onClick={() => { navigate(`/${userState?.resourceType}/profile`); dispatch(setActiveKey({ activeKey: activeKeyRedux.activeKey, activeTab: 'Profile' })); }}>
            <Icon.PersonFill className='me-2' size={35} />
            <div>
              <p style={{ fontWeight: '600', fontSize: '15px', marginBottom: '-4px' }}>{userState?.firstName + " " + userState?.lastName}</p>
              <p style={{ fontSize: '12px', marginBottom: '0px' }}>{userState?.designation}</p>
            </div>
          </div>
        </OverlayTrigger>

        {/* <Button className='btn btn-logout ms-3' onClick={handleLogout}>{ loader ? <Spinner animation="border" variant="light" className='spinner'/> :<><Icon.BoxArrowLeft className='me-2'/>Logout</>}</Button> */}
      </ul>

      <ul id="selectNav" className="mobile-nav" data-visible="false">

        <div className="d-flex justify-content-between flex-column h-100" >
          {/* for employee */}
          {
            roleOfUser.includes(userState?.resourceType) &&
            <Accordion flush>

              <Accordion.Item eventKey="1" onClick={() => { handleAccordionChange(1, 1, "Home"); navigate(`/${userState?.resourceType}`); setnav(); }} className={activeKeyRedux?.activeKey === 1 ? " activeMenu" : ""}>
                <Accordion.Header >{activeKeyRedux?.activeKey === 1 ? <Icon.HouseFill className='me-2' /> : <Icon.House className='me-2 icon' />}Home</Accordion.Header>
              </Accordion.Item>

              <Accordion.Item eventKey="2" onClick={() => { handleAccordionChange(2, 2, "Dashboard"); navigate("Dashboard"); setnav(); }} className={activeKeyRedux?.activeKey === 2 ? "activeMenu" : ""}>
                <Accordion.Header >{activeKeyRedux?.activeKey === 2 ? <Icon.BarChartFill className='me-2' /> : <Icon.BarChart className='me-2 icon' />}Dashboard</Accordion.Header>
              </Accordion.Item>

              <Accordion.Item eventKey="3" onClick={() => { handleAccordionChange(3, 3, "Reviews"); navigate("review"); setnav(); }} className={activeKeyRedux?.activeKey === 3 ? "activeMenu" : ""}>
                <Accordion.Header >{activeKeyRedux?.activeKey === 3 ? <Icon.ChatLeftTextFill className='me-2' /> : <Icon.ChatLeftText className='me-2 icon' />}Reviews</Accordion.Header>
              </Accordion.Item>

            </Accordion>
          }

          {/* for manager */}
          {
            userState.resourceType === "manager" &&
            <Accordion flush defaultActiveKey={activeKeyRedux?.activeKey}>

              <Accordion.Item eventKey={1} onClick={() => { handleAccordionChange(1, 1, "Home"); navigate("/manager"); setnav(); }} className={activeKeyRedux?.activeKey === 1 ? "activeMenu" : ""}>
                <Accordion.Header >{activeKeyRedux?.activeKey === 1 ? <Icon.HouseFill className='me-2' /> : <Icon.House className='me-2 icon' />}Home</Accordion.Header>
              </Accordion.Item>

              <Accordion.Item eventKey={2} onClick={() => { handleAccordionChange(2, 2, "Dashboard"); navigate("dashboard"); setnav(); }} className={activeKeyRedux?.activeKey === 2 ? "activeMenu" : ""}>
                <Accordion.Header >{activeKeyRedux?.activeKey === 2 ? <Icon.BarChartFill className='me-2' /> : <Icon.BarChart className='me-2 icon' />}Dashboard</Accordion.Header>
              </Accordion.Item>


              <Accordion.Item eventKey={3} className={activeKeyRedux.activeKey === 3 ? "activeMenu dropDown" : "dropDown"}>
                <Accordion.Header onClick={() => { handleAccordionChange(3, activeKeyRedux.subActiveKey, activeKeyRedux.activeTab); }}>
                  {activeKeyRedux?.activeKey === 3 ? <Icon.PeopleFill className='me-2' /> : <Icon.People className='me-2 icon' />}Manage Employee</Accordion.Header>

                <Accordion.Body className="d-flex flex-column">
                  <Button onClick={() => { navigate("employee"); handleAccordionChange(3, 3.1, "View Employee"); setnav(); }} className={activeKeyRedux?.subActiveKey === 3.1 ? "accordionNavActive mb-3 w-100" : "accordionNav mb-3 w-100"}>
                    {activeKeyRedux?.subActiveKey === 3.1 ? <Icon.EyeFill className="me-2" /> : <Icon.Eye className="me-2" />} View Employee
                  </Button>
                  <Button onClick={() => { navigate("employee-task"); handleAccordionChange(3, 3.2, "Employee Task"); setnav(); }} className={activeKeyRedux?.subActiveKey === 3.2 ? "accordionNavActive mb-3 w-100" : "accordionNav mb-3 w-100"}>
                    {activeKeyRedux?.subActiveKey === 3.2 ? <Icon.PersonFillAdd className="me-2" /> : <Icon.PersonAdd className="me-2" />} Employee Task
                  </Button>
                  <Button onClick={() => { navigate("no-tasks"); handleAccordionChange(3, 3.3, "No Task"); setnav(); }} className={activeKeyRedux?.subActiveKey === 3.3 ? "accordionNavActive mb-3 w-100" : "accordionNav mb-3 w-100"}>
                    {activeKeyRedux?.subActiveKey === 3.3 ? <Icon.PersonFillSlash className="me-2" /> : <Icon.PersonSlash className="me-2" />} No Task
                  </Button>
                  <Button onClick={() => { navigate("resourceutilize"); handleAccordionChange(3, 3.4, "Resource Utilization"); setnav(); }} className={activeKeyRedux?.subActiveKey === 3.4 ? "accordionNavActive mb-3 w-100" : "accordionNav mb-3 w-100"}>
                    {activeKeyRedux?.subActiveKey === 3.4 ? <Icon.ColumnsGap className="me-2" /> : <Icon.ColumnsGap className="me-2" />} Resource Utilization
                  </Button>
                  <Button onClick={() => { navigate("review"); handleAccordionChange(3, 3.5, "Employee Reviews"); setnav(); }} className={activeKeyRedux?.subActiveKey === 3.5 ? "accordionNavActive w-100" : "accordionNav w-100"}>
                    {activeKeyRedux?.subActiveKey === 3.5 ? <Icon.PersonVcardFill className="me-2" /> : <Icon.PersonVcard className="me-2" />}Employee Reviews
                  </Button>
                </Accordion.Body>
              </Accordion.Item>


              {/* <Form.Label>Self</Form.Label> */}

              <Accordion.Item eventKey={4} className={activeKeyRedux?.activeKey === 4 ? "activeMenu dropDown" : "dropDown"}>
                <Accordion.Header onClick={() => { handleAccordionChange(4, activeKeyRedux.subActiveKey, activeKeyRedux.activeTab); }} >{activeKeyRedux?.activeKey === 4 ?
                  <Icon.Incognito className='me-2' /> : <Icon.Incognito className='me-2 icon' />}Manage Self Tasks</Accordion.Header>

                <Accordion.Body>
                  <Button onClick={() => { navigate("assign-task"); handleAccordionChange(4, 4.1, "Other Department Task"); setnav(); }} className={activeKeyRedux?.subActiveKey === 4.1 ? "accordionNavActive mb-3 w-100" : "accordionNav mb-3 w-100"}>
                    {activeKeyRedux?.subActiveKey === 4.1 ? <Icon.PersonUp className="me-2" /> : <Icon.PersonUp className="me-2" />} Other Dept Task
                  </Button>
                  <Button onClick={() => { navigate("allocated-task"); handleAccordionChange(4, 4.2, "Allocated Task"); setnav(); }} className={activeKeyRedux?.subActiveKey === 4.2 ? "accordionNavActive mb-3 w-100" : "accordionNav mb-3 w-100"}>
                    {activeKeyRedux?.subActiveKey === 4.2 ? <Icon.PersonFillAdd className="me-2" /> : <Icon.PersonAdd className="me-2" />} Allocated Task
                  </Button>
                  <Button onClick={() => { navigate("self-task"); handleAccordionChange(4, 4.3, "Self Task"); setnav(); }} className={activeKeyRedux?.subActiveKey === 4.3 ? "accordionNavActive mb-3 w-100" : "accordionNav mb-3 w-100"}>
                    {activeKeyRedux?.subActiveKey === 4.3 ? <Icon.PersonVcardFill className="me-2" /> : <Icon.PersonVcard className="me-2" />} Self Task
                  </Button>
                  <Button onClick={() => { navigate("self-review"); handleAccordionChange(4, 4.4, "Self Review"); setnav(); }} className={activeKeyRedux?.subActiveKey === 4.4 ? "accordionNavActive w-100" : "accordionNav w-100"}>
                    {activeKeyRedux?.subActiveKey === 4.4 ? <Icon.ChatLeftDotsFill className="me-2" /> : <Icon.ChatLeftDots className="me-2" />} Self Reviews
                  </Button>
                </Accordion.Body>

              </Accordion.Item>

            </Accordion>
          }

          {/* for teamlead */}
          {
            userState.resourceType === "teamlead" &&
            <Accordion flush defaultActiveKey={activeKeyRedux?.activeKey}>

              <Accordion.Item eventKey={1} onClick={() => { handleAccordionChange(1, 1, "Home"); navigate("/teamlead"); setnav(); }} className={activeKeyRedux?.activeKey === 1 ? "activeMenu" : ""}>
                <Accordion.Header >{activeKeyRedux?.activeKey === 1 ? <Icon.HouseFill className='me-2' /> : <Icon.House className='me-2 icon' />}Home</Accordion.Header>
              </Accordion.Item>

              <Accordion.Item eventKey={2} onClick={() => { handleAccordionChange(2, 2, "Dashboard"); navigate("dashboard"); setnav(); }} className={activeKeyRedux?.activeKey === 2 ? "activeMenu" : ""}>
                <Accordion.Header >{activeKeyRedux?.activeKey === 2 ? <Icon.BarChartFill className='me-2' /> : <Icon.BarChart className='me-2 icon' />}Dashboard</Accordion.Header>
              </Accordion.Item>


              <Accordion.Item eventKey={3} className={activeKeyRedux.activeKey === 3 ? "activeMenu dropDown" : "dropDown"}>
                <Accordion.Header onClick={() => { handleAccordionChange(3, activeKeyRedux.subActiveKey, activeKeyRedux.activeTab); }}>
                  {activeKeyRedux?.activeKey === 3 ? <Icon.PeopleFill className='me-2' /> : <Icon.People className='me-2 icon' />}Manage Employee</Accordion.Header>

                <Accordion.Body className="d-flex flex-column">
                  <Button onClick={() => { navigate("employee"); handleAccordionChange(3, 3.1, "View Employee"); setnav(); }} className={activeKeyRedux?.subActiveKey === 3.1 ? "accordionNavActive mb-3 w-100" : "accordionNav mb-3 w-100"}>
                    {activeKeyRedux?.subActiveKey === 3.1 ? <Icon.EyeFill className="me-2" /> : <Icon.Eye className="me-2" />} View Employee
                  </Button>
                  <Button onClick={() => { navigate("employee-task"); handleAccordionChange(3, 3.2, "Employee Task"); setnav(); }} className={activeKeyRedux?.subActiveKey === 3.2 ? "accordionNavActive mb-3 w-100" : "accordionNav mb-3 w-100"}>
                    {activeKeyRedux?.subActiveKey === 3.2 ? <Icon.PersonFillAdd className="me-2" /> : <Icon.PersonAdd className="me-2" />} Employee Task
                  </Button>
                  <Button onClick={() => { navigate("no-tasks"); handleAccordionChange(3, 3.3, "No Task"); setnav(); }} className={activeKeyRedux?.subActiveKey === 3.3 ? "accordionNavActive mb-3 w-100" : "accordionNav mb-3 w-100"}>
                    {activeKeyRedux?.subActiveKey === 3.3 ? <Icon.PersonFillSlash className="me-2" /> : <Icon.PersonSlash className="me-2" />} No Task
                  </Button>
                  <Button onClick={() => { navigate("resourceutilize"); handleAccordionChange(3, 3.4, "Resource Utilization"); setnav(); }} className={activeKeyRedux?.subActiveKey === 3.4 ? "accordionNavActive mb-3 w-100" : "accordionNav mb-3 w-100"}>
                    {activeKeyRedux?.subActiveKey === 3.4 ? <Icon.ColumnsGap className="me-2" /> : <Icon.ColumnsGap className="me-2" />} Resource Utilization
                  </Button>
                  <Button onClick={() => { navigate("review"); handleAccordionChange(3, 3.5, "Employee Reviews"); setnav(); }} className={activeKeyRedux?.subActiveKey === 3.5 ? "accordionNavActive w-100" : "accordionNav w-100"}>
                    {activeKeyRedux?.subActiveKey === 3.5 ? <Icon.PersonVcardFill className="me-2" /> : <Icon.PersonVcard className="me-2" />}Employee Reviews
                  </Button>
                </Accordion.Body>
              </Accordion.Item>


              {/* <Form.Label>Self</Form.Label> */}

              <Accordion.Item eventKey={4} className={activeKeyRedux?.activeKey === 4 ? "activeMenu dropDown" : "dropDown"}>
                <Accordion.Header onClick={() => { handleAccordionChange(4, activeKeyRedux.subActiveKey, activeKeyRedux.activeTab); }} >{activeKeyRedux?.activeKey === 4 ?
                  <Icon.Incognito className='me-2' /> : <Icon.Incognito className='me-2 icon' />}Manage Self Tasks</Accordion.Header>

                <Accordion.Body>
                  <Button onClick={() => { navigate("assign-task"); handleAccordionChange(4, 4.1, "Other Department Task"); setnav(); }} className={activeKeyRedux?.subActiveKey === 4.1 ? "accordionNavActive mb-3 w-100" : "accordionNav mb-3 w-100"}>
                    {activeKeyRedux?.subActiveKey === 4.1 ? <Icon.PersonUp className="me-2" /> : <Icon.PersonUp className="me-2" />} Other Dept Task
                  </Button>
                  <Button onClick={() => { navigate("allocated-task"); handleAccordionChange(4, 4.2, "Allocated Task"); setnav(); }} className={activeKeyRedux?.subActiveKey === 4.2 ? "accordionNavActive mb-3 w-100" : "accordionNav mb-3 w-100"}>
                    {activeKeyRedux?.subActiveKey === 4.2 ? <Icon.PersonFillAdd className="me-2" /> : <Icon.PersonAdd className="me-2" />} Allocated Task
                  </Button>
                  <Button onClick={() => { navigate("self-task"); handleAccordionChange(4, 4.3, "Self Task"); setnav(); }} className={activeKeyRedux?.subActiveKey === 4.3 ? "accordionNavActive mb-3 w-100" : "accordionNav mb-3 w-100"}>
                    {activeKeyRedux?.subActiveKey === 4.3 ? <Icon.PersonVcardFill className="me-2" /> : <Icon.PersonVcard className="me-2" />} Self Task
                  </Button>
                  <Button onClick={() => { navigate("self-review"); handleAccordionChange(4, 4.4, "Self Review"); setnav(); }} className={activeKeyRedux?.subActiveKey === 4.4 ? "accordionNavActive w-100" : "accordionNav w-100"}>
                    {activeKeyRedux?.subActiveKey === 4.4 ? <Icon.ChatLeftDotsFill className="me-2" /> : <Icon.ChatLeftDots className="me-2" />} Self Reviews
                  </Button>
                </Accordion.Body>

              </Accordion.Item>

            </Accordion>
          }

          {/* for humanresource */}
          {
            userState.resourceType === "humanResource" &&
            <Accordion flush defaultActiveKey={activeKeyRedux?.activeKey}>

              <Accordion.Item eventKey={1} onClick={() => { handleAccordionChange(1, 1, "Home"); navigate("/humanResource"); setnav(); }} className={activeKeyRedux?.activeKey === 1 ? "activeMenu" : ""}>
                <Accordion.Header >{activeKeyRedux?.activeKey === 1 ? <Icon.HouseFill className='me-2' /> : <Icon.House className='me-2 icon' />}Home</Accordion.Header>
              </Accordion.Item>

              <Accordion.Item eventKey={2} onClick={() => { handleAccordionChange(2, 2, "Dashboard"); navigate("dashboard"); setnav(); }} className={activeKeyRedux.activeKey === 2 ? "activeMenu" : ""}>
                <Accordion.Header >{activeKeyRedux?.activeKey === 2 ? <Icon.BarChartFill className='me-2' /> : <Icon.BarChart className='me-2 icon' />}Dashboard</Accordion.Header>
              </Accordion.Item>

              <Accordion.Item eventKey={3} className={activeKeyRedux.activeKey === 3 ? "activeMenu dropDown" : "dropDown"}>
                <Accordion.Header onClick={() => { handleAccordionChange(3, activeKeyRedux.subActiveKey, activeKeyRedux.activeTab); }}>
                  {activeKeyRedux?.activeKey === 3 ? <Icon.PeopleFill className='me-2' /> : <Icon.People className='me-2 icon' />}Manage Employee</Accordion.Header>

                <Accordion.Body className="d-flex flex-column">
                  <Button onClick={() => { navigate("employee"); handleAccordionChange(3, 3.1, "View Employee"); setnav(); }} className={activeKeyRedux?.subActiveKey === 3.1 ? "accordionNavActive mb-3 w-100" : "accordionNav mb-3 w-100"}>
                    {activeKeyRedux?.subActiveKey === 3.1 ? <Icon.EyeFill className="me-2" /> : <Icon.Eye className="me-2" />} View Employee
                  </Button>
                  <Button onClick={() => { navigate("employee-task"); handleAccordionChange(3, 3.2, "Employee Task"); setnav(); }} className={activeKeyRedux?.subActiveKey === 3.2 ? "accordionNavActive mb-3 w-100" : "accordionNav mb-3 w-100"}>
                    {activeKeyRedux?.subActiveKey === 3.2 ? <Icon.PersonFillAdd className="me-2" /> : <Icon.PersonAdd className="me-2" />} Employee Task
                  </Button>
                  <Button onClick={() => { navigate("resourceutilize"); handleAccordionChange(3, 3.3, "Resource Utilization"); setnav(); }} className={activeKeyRedux?.subActiveKey === 3.3 ? "accordionNavActive mb-3 w-100" : "accordionNav mb-3 w-100"}>
                    {activeKeyRedux?.subActiveKey === 3.3 ? <Icon.ColumnsGap className="me-2" /> : <Icon.ColumnsGap className="me-2" />} Resource Utilization
                  </Button>
                  <Button onClick={() => { navigate("review"); handleAccordionChange(3, 3.4, "Employee Reviews"); setnav(); }} className={activeKeyRedux?.subActiveKey === 3.4 ? "accordionNavActive mb-3 w-100" : "accordionNav mb-3 w-100"}>
                    {activeKeyRedux?.subActiveKey === 3.4 ? <Icon.PersonVcardFill className="me-2" /> : <Icon.PersonVcard className="me-2" />}Employee Reviews
                  </Button>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey={4} className={activeKeyRedux?.activeKey === 4 ? "activeMenu dropDown" : "dropDown"}>
                <Accordion.Header onClick={() => { handleAccordionChange(4, activeKeyRedux.subActiveKey, activeKeyRedux.activeTab); }} >{activeKeyRedux?.activeKey === 4 ?
                  <Icon.Incognito className='me-2' /> : <Icon.Incognito className='me-2 icon' />}Manage Self Tasks</Accordion.Header>

                <Accordion.Body>
                  <Button onClick={() => { navigate("assign-task"); handleAccordionChange(4, 4.1, "Other Department Task"); setnav(); }} className={activeKeyRedux?.subActiveKey === 4.1 ? "accordionNavActive mb-3 w-100" : "accordionNav mb-3 w-100"}>
                    {activeKeyRedux?.subActiveKey === 4.1 ? <Icon.PersonUp className="me-2" /> : <Icon.PersonUp className="me-2" />} Other Dept Task
                  </Button>
                  <Button onClick={() => { navigate("allocated-task"); handleAccordionChange(4, 4.2, "Allocated Task"); setnav(); }} className={activeKeyRedux?.subActiveKey === 4.2 ? "accordionNavActive mb-3 w-100" : "accordionNav mb-3 w-100"}>
                    {activeKeyRedux?.subActiveKey === 4.2 ? <Icon.PersonFillAdd className="me-2" /> : <Icon.PersonAdd className="me-2" />} Allocated Task
                  </Button>
                  <Button onClick={() => { navigate("self-task"); handleAccordionChange(4, 4.3, "Self Task"); setnav(); }} className={activeKeyRedux?.subActiveKey === 4.3 ? "accordionNavActive mb-3 w-100" : "accordionNav mb-3 w-100"}>
                    {activeKeyRedux?.subActiveKey === 4.3 ? <Icon.PersonVcardFill className="me-2" /> : <Icon.PersonVcard className="me-2" />} Self Task
                  </Button>
                  <Button onClick={() => { navigate("self-review"); handleAccordionChange(4, 4.4, "Self Review"); setnav(); }} className={activeKeyRedux?.subActiveKey === 4.4 ? "accordionNavActive w-100" : "accordionNav w-100"}>
                    {activeKeyRedux?.subActiveKey === 4.4 ? <Icon.ChatLeftDotsFill className="me-2" /> : <Icon.ChatLeftDots className="me-2" />} Self Reviews
                  </Button>
                </Accordion.Body>

              </Accordion.Item>

            </Accordion>
          }

          {/* for admin */}
          {
            userState.resourceType === "admin" &&
            <Accordion flush defaultActiveKey={activeKeyRedux?.activeKey}>

              <Accordion.Item eventKey={1} onClick={() => { handleAccordionChange(1, 1, "Home"); navigate("/admin"); setnav(); }} className={activeKeyRedux?.activeKey === 1 ? "activeMenu" : ""}>
                <Accordion.Header >{activeKeyRedux?.activeKey === 1 ? <Icon.HouseFill className='me-2' /> : <Icon.House className='me-2 icon' />}Home</Accordion.Header>
              </Accordion.Item>

              <Accordion.Item eventKey={2} onClick={() => { handleAccordionChange(2, 2, "Dashboard"); navigate("dashboard"); setnav(); }} className={activeKeyRedux.activeKey === 2 ? "activeMenu" : ""}>
                <Accordion.Header >{activeKeyRedux?.activeKey === 2 ? <Icon.BarChartFill className='me-2' /> : <Icon.BarChart className='me-2 icon' />}Dashboard</Accordion.Header>
              </Accordion.Item>

              <Accordion.Item eventKey={3} onClick={() => { handleAccordionChange(3, 3, "Project"); navigate("project"); setnav(); }} className={activeKeyRedux.activeKey === 3 ? "activeMenu" : ""}>
                <Accordion.Header >{activeKeyRedux?.activeKey === 3 ? <Icon.Sliders2 className='me-2' /> : <Icon.Sliders2 className='me-2 icon' />}Manage Project</Accordion.Header>
              </Accordion.Item>

              <Accordion.Item eventKey={4} onClick={() => { handleAccordionChange(4, 4, "Client"); navigate("client"); setnav(); }} className={activeKeyRedux.activeKey === 4 ? "activeMenu" : ""}>
                <Accordion.Header >{activeKeyRedux?.activeKey === 4 ? <Icon.PersonLinesFill className='me-2' /> : <Icon.PersonLinesFill className='me-2 icon' />}Manage Client</Accordion.Header>
              </Accordion.Item>

              <Accordion.Item eventKey={5} className={activeKeyRedux.activeKey === 5 ? "activeMenu dropDown" : "dropDown"}>
                <Accordion.Header onClick={() => { handleAccordionChange(5, activeKeyRedux.subActiveKey, activeKeyRedux.activeTab); }}>
                  {activeKeyRedux?.activeKey === 5 ? <Icon.PeopleFill className='me-2' /> : <Icon.People className='me-2 icon' />}Manage Employee</Accordion.Header>

                <Accordion.Body className="d-flex flex-column">
                  <Button onClick={() => { navigate("employee"); handleAccordionChange(5, 5.1, "View Employee"); setnav(); }} className={activeKeyRedux?.subActiveKey === 5.1 ? "accordionNavActive mb-3 w-100" : "accordionNav mb-3 w-100"}>
                    {activeKeyRedux?.subActiveKey === 5.1 ? <Icon.EyeFill className="me-2" /> : <Icon.Eye className="me-2" />} View Employee
                  </Button>
                  <Button onClick={() => { navigate("employee-task"); handleAccordionChange(5, 5.2, "Employee Task"); setnav(); }} className={activeKeyRedux?.subActiveKey === 5.2 ? "accordionNavActive mb-3 w-100" : "accordionNav mb-3 w-100"}>
                    {activeKeyRedux?.subActiveKey === 5.2 ? <Icon.PersonFillAdd className="me-2" /> : <Icon.PersonAdd className="me-2" />} Employee Task
                  </Button>
                  <Button onClick={() => { navigate("resourceutilize"); handleAccordionChange(3, 5.3, "Resource Utilization"); setnav(); }} className={activeKeyRedux?.subActiveKey === 5.3 ? "accordionNavActive mb-3 w-100" : "accordionNav mb-3 w-100"}>
                    {activeKeyRedux?.subActiveKey === 5.3 ? <Icon.ColumnsGap className="me-2" /> : <Icon.ColumnsGap className="me-2" />} Resource Utilization
                  </Button>
                  <Button onClick={() => { navigate("review"); handleAccordionChange(5, 5.4, "Employee Reviews"); setnav(); }} className={activeKeyRedux?.subActiveKey === 5.4 ? "accordionNavActive mb-3 w-100" : "accordionNav mb-3 w-100"}>
                    {activeKeyRedux?.subActiveKey === 5.4 ? <Icon.PersonVcardFill className="me-2" /> : <Icon.PersonVcard className="me-2" />}Employee Reviews
                  </Button>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey={6} className={activeKeyRedux?.activeKey === 6 ? "activeMenu dropDown" : "dropDown"}>
                <Accordion.Header onClick={() => { handleAccordionChange(6, activeKeyRedux.subActiveKey, activeKeyRedux.activeTab); }} >{activeKeyRedux?.activeKey === 6 ?
                  <Icon.Incognito className='me-2' /> : <Icon.Incognito className='me-2 icon' />}Manage Self Tasks</Accordion.Header>

                <Accordion.Body>
                  <Button onClick={() => { navigate("self-task"); handleAccordionChange(6, 6.1, "Self Task"); setnav(); }} className={activeKeyRedux?.subActiveKey === 6.1 ? "accordionNavActive mb-3 w-100" : "accordionNav mb-3 w-100"}>
                    {activeKeyRedux?.subActiveKey === 6.1 ? <Icon.PersonVcardFill className="me-2" /> : <Icon.PersonVcard className="me-2" />} Self Task
                  </Button>
                  <Button onClick={() => { navigate("self-review"); handleAccordionChange(6, 6.2, "Self Review"); setnav(); }} className={activeKeyRedux?.subActiveKey === 6.2 ? "accordionNavActive w-100" : "accordionNav w-100"}>
                    {activeKeyRedux?.subActiveKey === 6.2 ? <Icon.ChatLeftDotsFill className="me-2" /> : <Icon.ChatLeftDots className="me-2" />} Self Reviews
                  </Button>
                </Accordion.Body>

              </Accordion.Item>

            </Accordion>
          }

          <div className='px-2'>
            <Button className='LogoutBtn w-100' onClick={handleLogout}>{loader ? <Spinner animation="border" variant="light" className='spinner' /> : <><Icon.BoxArrowLeft className='me-2' />Logout</>}</Button>
          </div>

        </div>
      </ul>

    </nav>
  )
}

export default Menubar;