import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ReviewModel = ({ show, close, taskId }) => {
  const [idc, setIDC] = useState('');
  const [qwd, setQWD] = useState('');

  // state for error
  const [idcerr, setIDCErr] = useState(false);
  const [qwderr, SetQWDErr] = useState(false);

  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleDateString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });

  
  // for add Review
  const AddReviewToast = () => {
    toast.success("Added Review Successfully", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose:1000
    });
  };
  // function for validation
  const handlerValidate = () => {
    let FormValid = true;
    if (idc.length === 0) {
      FormValid = false;
      setIDCErr(true);
    }
    if (qwd.length === 0) {
      FormValid = false;
      SetQWDErr(true);
    }
    return FormValid;
  };

  // call review api
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIDCErr(false);
    SetQWDErr(false);
    if (handlerValidate()) {
      const payload = {
        taskId: taskId,
        reviewDate: formattedDate,
        idc: idc,
        qwd: qwd,
      };

      axios
        .put(process.env.REACT_APP_BASE_URL + '/task/updateReviewTask', payload)
        .then((res) => {
          {res?.data?.msg === "Review update Successfully" && AddReviewToast() }
          close();
          setQWD("");
          setIDC("");
        })
        .catch((err) => {
          console.log('Error', err);
        });
    }
  };

  return (
    <>
    <ToastContainer/>
      <Modal Modal show={show} onHide={close} backdrop="static" keyboard={false} centered>
        <Modal.Header closeButton style={{ color: 'black' }}>
          <Modal.Title><h5>Fill Up Review</h5></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12}>
              <Form.Group controlId="formBasiContact">
                <Form.Label className=" mt-3 fw-bold">
                  IDC Rating(1 to 10){' '}
                  <span style={{ color: 'red', fontWeight: 'bold' }}>*</span>
                </Form.Label>
                <Form.Control
                  name="idc"
                  value={idc}
                  onChange={(e) => {
                    if (
                      (!e.target.value.match(
                        /[A-Za-z+@#$&%!~=^_:(){}\[\]*|/\s/g/\-/?<>,;`'""/\\]/
                      ) &&
                        e.target.value === ' ') ||
                      (e.target.value >= 1 && e.target.value <= 10)
                    ) {
                      setIDC(e.target.value);
                    } else if (e.target.value.length === 0) {
                      setIDC(e.target.value);
                    }
                    idc.length >= 0 ? setIDCErr(false) : setIDCErr(true);
                  }}
                />

                {idcerr ? (
                  <p className="error-padding" style={{ color: 'red' }}>
                    <>
                      <Icon.ExclamationTriangle className="me-2" />
                    </>
                    Required Field
                  </p>
                ) : null}
              </Form.Group>
            </Col>

            <Col md={12} className="mt-2">
              <Form.Group controlId="formBasiContact">
                <Form.Label className=" mt-3 fw-bold">
                  QWD Rating(1 to 10){' '}
                  <span style={{ color: 'red', fontWeight: 'bold' }}>*</span>
                </Form.Label>
                <Form.Control
                  name="qwd"
                  value={qwd}
                  onChange={(e) => {
                    if (
                      (!e.target.value.match(
                        /[A-Za-z+@#$&%!~=^_:(){}\[\]*|/\s/g/\-/?<>,;`'""/\\]/
                      ) &&
                        e.target.value === ' ') ||
                      (e.target.value >= 1 && e.target.value <= 10)
                    ) {
                      setQWD(e.target.value);
                    } else if (e.target.value.length === 0) {
                      setQWD(e.target.value);
                    }
                    qwd.length >= 0 ? SetQWDErr(false) : SetQWDErr(true);
                  }}
                />
              </Form.Group>
              {qwderr ? (
                <p className="error-padding" style={{ color: 'red' }}>
                  <>
                    <Icon.ExclamationTriangle className="me-2" />
                  </>
                  Required Field
                </p>
              ) : null}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button className="primaryBtn" onClick={(e) => handleSubmit(e)}>
            Add Review
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ReviewModel;


