import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Container,Row} from 'react-bootstrap'
import * as Icon from "react-bootstrap-icons";
import CreateToDoListModal from './modal/CreateToDoListModal';
import DeleteToDOListModal from './modal/DeleteToDoListModal';
import EditToDOListModal from './modal/EditToDoListModal';
import ViewToDOListModal from './modal/ViewToDoListModal';
import AssignToDoModel from './modal/AssignToDoModel';
import { useSelector } from 'react-redux';
import axios from 'axios';

const ToDoList = () => {

  // login userdata
   const loginUserData = useSelector((state) => state?.loginData?.userData);

  // state for get all to do list
  const[alltododata,setAllToDoData] = useState()

  // Define state variables
  const [createtodolistmodal, setcreateToDoListModal] = useState(false);
  const [showdeletemodal, setShowDeleteModal] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showView, setShowView] = useState(false);
  const[showcreatemodal,setShowCreateModal] = useState(false);
  const[todoid,setToDoID] = useState('')


  // for button
  const [showToDoListCard, setShowToDoListCard] = useState(true);
  const [showCompletedCard, setShowCompletedCard] = useState(false);
  
  // showing for current date
  const currentDate = new Date();

  // Extract the date components (day, month, and year)
  const day = currentDate.getDate();
  const month = currentDate.getMonth() + 1; // Months are zero-based, so add 1
  const year = currentDate.getFullYear();

  // function for create task model
   const handleShowCreateModal = (id) => {
    setToDoID(id);
    setShowCreateModal(id);
  };
  const handleCloseCreateModel = (id) => {
    setShowCreateModal(false);
    setToDoID("");
  };

  // function for crearte todo list modal
  const handleCloseToDOListModel = () => { setcreateToDoListModal(false); getAllToDoData();};

  // for edit open and close modal
  const handleShowEditModal = (id) => {
    setToDoID(id);
    setShowEdit(id);
  };
  const handlaEditModalClose = (id) => {
    setShowEdit(false);
    setToDoID("");
    getAllToDoData()
  };

   // for delete modal
  const handleShowDeleteModal = (id) => {
    setToDoID(id);
    setShowDeleteModal(true);
  };

   // function for delete todo list modal
  const handleDeleteModalClose = () => {
    getAllToDoData()
    setShowDeleteModal(false);
    setToDoID("");
  };

   // for view open and close modal
  const handleShowViewModal = (id) => {
    setToDoID(id);
    setShowView(id);
  };
  const handlaViewModalClose = (id) => {
    setShowView(false);
    setToDoID("");
    getAllToDoData()
  };
    
  // Format the date as a string (e.g., "MM/DD/YYYY")
  const formattedDate = `${day}/${month}/${year}`;

  useEffect(() => {
      getAllToDoData();
  },[])

  const getAllToDoData = async() => {
    const payload = {
      "empId":loginUserData?.empId
    }
    await axios.post(process.env.REACT_APP_BASE_URL + "/toDoList/getAllToDoList" , payload)
    .then((res) => {
      setAllToDoData(res?.data?.data)
    })
    .catch((err) => {
        console.log("Error:",err)
    })
  }


  return (
    <div className="AssignTask outletPadding" data-aos="fade-up">
      <Container> 
        {showcreatemodal && <AssignToDoModel show={showcreatemodal} close={handleCloseCreateModel} item = {todoid} fetchData = {getAllToDoData}/>}
        {createtodolistmodal && (<CreateToDoListModal show={createtodolistmodal} close={handleCloseToDOListModel} />)}
        {showEdit && <EditToDOListModal show={showEdit} close={handlaEditModalClose} item = {todoid}/>}
        {showView && <ViewToDOListModal show={showView} close={handlaViewModalClose} item = {todoid}/>}
        {showdeletemodal &&  <DeleteToDOListModal show={showdeletemodal} close={handleDeleteModalClose} todoId={todoid}/>}

         <Row className='d-flex justify-content-end align-items-center'>
          {/* <Col xs={12} sm={6} md={6} className="d-flex justify-content-start align-items-center">
            <Button className="primaryBtn me-3" onClick={() => {setShowToDoListCard(true);setShowCompletedCard(false);}}>
              To Do List
            </Button>
            <Button className="primaryBtn me-3" onClick={() => {setShowToDoListCard(false); setShowCompletedCard(true);}}>
              Completed
            </Button>
          </Col> */}

          <Col xs={12} sm={6} md={6} className='d-flex justify-content-end align-items-center'>
             <Button className="activeBtn me-3" onClick={() => { setcreateToDoListModal(true) }}>
              <Icon.PersonAdd className="me-2" size={16}/>
              Create List
            </Button>
          </Col>
        </Row>



        <Row className='mt-4'>

          {/* for to do list */}
          {showToDoListCard && (
            <>
             {/* for To do card */}
             {alltododata !== null && alltododata !== undefined && alltododata.length > 0 ? 
                alltododata?.map((itm) => {
                  const {title,description,toDoListId} = itm
                  return(
                    <>
                       <Col xs={12} sm={6} md={4} lg={4} xl={3}>
                        <Card className= 'my-3' style={{ borderRadius: '10px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
                          <Card.Body style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%'}} >
                            <div className="d-flex justify-content-between align-items-center">
                              <div className='title'>
                                <h6 className="m-0">{title.length > 30 ? title.substring(0, 30) + "..." : title}</h6>
                              </div>
                              <div className="d-flex">
                                  <Icon.Eye className="icon me-3" size={18} color = '#203759' data-toggle="tooltip" title="View" onClick={() => {handleShowViewModal(itm);}} />
                                  <Icon.Pencil className="icon me-3" size={18} color = "#47AB64" data-toggle="tooltip" title="Edit"  onClick={() => {handleShowEditModal(itm);}}/>
                                  <Icon.Trash className="icon" size={18} color = "#FF0808" data-toggle="tooltip" title="Delete" onClick = {() => handleShowDeleteModal(toDoListId)}/>
                              </div>
                            </div>

                            <div className="description" style={{ fontSize: '14px', color: '#333', marginTop: '15px' }}>
                              <p>
                                {description.length > 200 ? description.substring(0, 100) + "..." : description}
                              </p>
                            </div>

                            <div className="d-flex justify-content-between align-items-center mt-3">
                              <div className="date mt-3" style={{ fontSize: '12px', color: '#777' }}>{formattedDate}</div>
                              <Button className="mt-3 primaryBtn" onClick={() => { handleShowCreateModal(itm) }}>
                                Assign
                              </Button>
                          </div>
                          </Card.Body>
                        </Card>
                    </Col>
                    </>
                  )
                })
             :(
               <tr>
                  <td colSpan="18" className="text-center">No data available</td>
               </tr>
             )}
            </>
          )}

        </Row>
      </Container>
    </div>
  )
}

export default ToDoList
