import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import Select from 'react-select';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Icon from "react-bootstrap-icons";

const EditSubTaskModel = ({ show, close, data, result }) => {
    const taskStatusOptions = [{ value: 'pending', label: 'Pending' }, { value: 'completed', label: 'Completed' }];

    const actualHours = (typeof data?.actualHours === 'number' && !isNaN(data?.actualHours)) ? data?.actualHours : 0;
    const numericValue = parseFloat(actualHours);

    let hours = 0;
    let minutes = 0;

    if (!isNaN(numericValue)) {
        hours = Math.floor(numericValue);
        const decimalPart = numericValue - hours;
        minutes = Math.round(decimalPart * 60);
    }

    const [taskstatus, setTaskStatus] = useState("")
    const [updatesubtask, setUpdateSubTask] = useState({
        taskId: "",
        taskName: "",
        actualHours: "",
        actualMinutes: "",
        actualStartDate: "",
        actualEndtDate: "",
        remark: "",
        status: "",
        complitionByResource: "",
    });

    // for validation
    const [subtaskerror, setSubTaskError] = useState(false);
    const [actualhrerror, setActualHrError] = useState(false);
    const [actualminuteerr, setActualMinuteErr] = useState(false)

    useEffect(() => {
        setUpdateSubTask({
            taskId: data?.taskId,
            taskName: data?.taskName,
            actualHours: hours,
            actualMinutes: minutes,
            actualStartDate: data?.actualStartDate,
            actualEndtDate: data?.actualEndtDate,
            remark: data?.remark,
            complitionByResource: data?.complitionByResource,
        });
        setTaskStatus({ label: data?.status, value: data?.status })

    }, [data])

    // for handler validate
    const handlerValidate = () => {
        let FormValid = true;
        if (updatesubtask?.taskName?.length === 0) {
            FormValid = false;
            setSubTaskError(true);
        }
        else if (updatesubtask?.actualHours?.length === 0) {
            FormValid = false;
            setActualHrError(true);
        }
        else if (updatesubtask?.actualMinutes?.length === 0) {
            FormValid = false;
            setActualMinuteErr(true);
        }
        return FormValid;
    };

    const handleUpdate = (e) => {
        let name, value
        name = e.target.name;
        value = e.target.value;
        setUpdateSubTask({ ...updatesubtask, [name]: value });
    };
    // for toast notification
    const AddActualHoursError = () => {
        toast.error("Please Insert less than initial Estimate Hours", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
        });
    };

    // for toast notification
    const updateSubTaskSuccess = () => {
        toast.success("Updated Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
        });
    };

    const ActualHr = () => {
        toast.error("Actual hr can not be 0.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
        });
    }

    const ActualHrMinute = () => {
        toast.error("00 hours and 00 minutes at the same time are not permitted, please choose an acceptable time.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
        });
    }

    const updateEmployeeSubTask = async (e) => {
        e.preventDefault();
        setSubTaskError(false);
        setActualHrError(false);
        if (handlerValidate()) {
            if (parseFloat(updatesubtask.actualHours) === 0 && parseFloat(updatesubtask.actualMinutes) === 0) {
                ActualHrMinute();
            } else {
                const updatedPayload = {
                    taskId: updatesubtask?.taskId,
                    taskName: updatesubtask?.taskName,
                    actualHours: updatesubtask?.actualHours,
                    actualMinutes: updatesubtask?.actualMinutes,
                    actualStartDate: updatesubtask?.actualStartDate,
                    actualEndtDate: updatesubtask?.actualEndtDate,
                    remark: updatesubtask?.remark,
                    complitionByResource:
                        taskstatus?.value === "completed" ? 100 : updatesubtask?.complitionByResource,
                    status: taskstatus?.value,
                };
                await axios
                    .put(process.env.REACT_APP_BASE_URL + "/task/updateSubTask", updatedPayload)
                    .then((response) => {
                        updateSubTaskSuccess();
                        close();
                    })
                    .catch((error) => console.log(error));
            };
        }
    }

    return (
        <>
            <ToastContainer />
            <Modal show={show} onHide={close} size="lg" centered backdrop='static' keyboard={false}>
                <Modal.Header closeButton className="mngmodelheader">
                    <Modal.Title>
                        <h5>Edit Sub Task</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body size="lg">
                    <Row>
                        <Col sm={12} md={12}>
                            <Form.Group>
                                <Form.Label className="fw-bold">
                                    Sub Task{" "}
                                    <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                </Form.Label>
                                <Form.Control
                                    value={updatesubtask?.taskName}
                                    name="taskName"
                                    onChange={(e) => {
                                        if (
                                            !e.target.value.match(
                                                /[#$&%!~=^_(){}\[\]*|//\/?<>;`/\\]/
                                            )
                                        ) {
                                            handleUpdate(e);
                                        } else if (e.target.value.length === 0) {
                                            handleUpdate(e);
                                        }
                                        updatesubtask.taskName.length <= 0
                                            ? setSubTaskError(true)
                                            : setSubTaskError(false);
                                    }}
                                />
                            </Form.Group>
                            {subtaskerror ? (
                                <p className="errMsg">
                                    <>
                                        <Icon.ExclamationTriangle className="me-2" />
                                    </>
                                    Required Field
                                </p>
                            ) : null}
                        </Col>

                        <Col sm={12} md={6} className="mt-3">
                            <Form.Group controlId="formBasiContact">
                                <Form.Label className="fw-bold">
                                    Actual Start date{" "}
                                    <span style={{ color: "red", fontWeight: "bold" }}>
                                        *</span>
                                </Form.Label>
                                <Form.Control
                                    type="date"
                                    name="actualStartDate"
                                    min={(result?.proposedStartDate)?.split('T')[0]}
                                    max={(result?.proposedEndDate)?.split('T')[0]}
                                    onKeyDown={(event) => event.preventDefault()}
                                    value={
                                        updatesubtask?.actualStartDate
                                            ? new Date(updatesubtask.actualStartDate).toISOString().split("T")[0]
                                            : ""
                                    }
                                    onChange={(e) => {
                                        setUpdateSubTask({
                                            ...updatesubtask,
                                            actualStartDate: new Date(e.target.value).toISOString().split("T")[0]
                                        })
                                    }}
                                />

                            </Form.Group>
                        </Col>

                        <Col sm={12} md={6} className="mt-3">
                            <Form.Group controlId="formBasiContact">
                                <Form.Label className="fw-bold">
                                    Actual End date{" "}
                                    <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                </Form.Label>
                                <Form.Control
                                    type="date"
                                    value={updatesubtask?.actualEndtDate
                                        ? new Date(updatesubtask?.actualEndtDate).toISOString().split("T")[0]
                                        : ""}
                                    min={(updatesubtask?.actualStartDate)?.split('T')[0]}
                                    max={(result?.proposedEndDate)?.split('T')[0]}
                                    onKeyDown={(event) => event.preventDefault()}
                                    onChange={(e) => {
                                        setUpdateSubTask({
                                            ...updatesubtask,
                                            actualEndtDate: new Date(e.target.value).toISOString().split("T")[0]
                                        })
                                    }}
                                />
                            </Form.Group>
                        </Col>

                        <Col sm={12} md={3} className="mt-3">
                            <Form.Group controlId="formBasiContact">
                                <Form.Label className="fw-bold">
                                    Actual Hours{" "}
                                    <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="actualHours"
                                    value={updatesubtask?.actualHours}
                                    onChange={(e) => {
                                        if (/^[0-9]*(\[0-9]*)?$/.test(e.target.value) && e.target.value.length <= 4) {
                                            handleUpdate(e);
                                        } else if (e.target.value.length === 0) {
                                            handleUpdate(e);
                                        }
                                        updatesubtask?.actualHours?.length < 0
                                            ? setActualHrError(true)
                                            : setActualHrError(false);
                                    }}
                                />
                            </Form.Group>
                            {actualhrerror ? (
                                <p className="errMsg">
                                    <>
                                        <Icon.ExclamationTriangle className="me-2" />
                                    </>
                                    Required Field
                                </p>
                            ) : null}
                        </Col>

                        <Col sm={12} md={3} className="mt-3">
                            <Form.Group controlId="formBasiContact">
                                <Form.Label className="fw-bold">
                                    Actual Minutes{" "}
                                    <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="actualMinutes"
                                    value={updatesubtask?.actualMinutes}
                                    onChange={(e) => {
                                        if (/^[0-9]{0,2}$/.test(e.target.value) && e.target.value >= 0 && e.target.value <= 59) {
                                            handleUpdate(e);
                                        } else if (e.target.value > 59) {
                                            toast.error('Please enter a value between 0 and 59.')
                                        }
                                        else if (e.target.value.length === 0) {
                                            handleUpdate(e);
                                        }
                                        updatesubtask?.actualMinutes?.length < 0
                                            ? setActualMinuteErr(true)
                                            : setActualMinuteErr(false);
                                    }}
                                />
                            </Form.Group>
                            {actualhrerror ? (
                                <p className="errMsg">
                                    <>
                                        <Icon.ExclamationTriangle className="me-2" />
                                    </>
                                    Required Field
                                </p>
                            ) : null}
                        </Col>

                        <Col sm={10} md={3} div className="mt-3">
                            <Form.Group controlId="formBasiContact">
                                <Form.Label className="fw-bold">
                                    Status{" "}
                                    <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                </Form.Label>
                                <Select
                                    isMulti={false}
                                    isSearchable={true}
                                    value={taskstatus}
                                    options={taskStatusOptions}
                                    onChange={(option) => {
                                        setTaskStatus(option);
                                    }}
                                />
                            </Form.Group>
                        </Col>

                        <Col sm={12} md={3} className="mt-3">
                            <Form.Group controlId="formBasicName">
                                <Form.Label className="fw-bold">Work Completion(%)</Form.Label>
                                <Form.Control
                                    className="input-field emp-inputgroup"
                                    name="complitionByResource"
                                    value={
                                        taskstatus?.value === "completed"
                                            ? "100"
                                            : updatesubtask?.complitionByResource
                                    }
                                    onChange={(e) => {
                                        if (
                                            (!e.target.value.match(
                                                /[A-Za-z+@#$&%!~=^_:(){}\[\]*.|/\s/g/\-/?<>,;`'""/\\]/
                                            ) &&
                                                e.target.value === " ") ||
                                            (e.target.value >= 1 && e.target.value <= 100)
                                        ) {
                                            handleUpdate(e);
                                        } else if (e.target.value.length === 0) {
                                            handleUpdate(e);
                                        }
                                    }}
                                />
                            </Form.Group>
                        </Col>

                        <Col sm={12} md={12} className="mt-3">
                            <Form.Group controlId="exampleForm.ControlTextarea1">
                                <Form.Label className="fw-bold">Remarks </Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    value={updatesubtask?.remark}
                                    name="remark"
                                    onChange={(e) => {
                                        handleUpdate(e);
                                    }}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className="text-center mt-4">
                    <Button
                        className="primaryBtn"
                        onClick={(e) => updateEmployeeSubTask(e)}
                    >
                        Update SubTask
                    </Button>
                </Modal.Footer>
            </Modal>
        </>

    )
}

export default EditSubTaskModel
