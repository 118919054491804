import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, Button, InputGroup } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import Spinner from 'react-bootstrap/Spinner';
import * as Icon from 'react-bootstrap-icons';

import { useDispatch, useSelector } from 'react-redux';
import { getUserData } from './LoginSlice';
import { getProjects } from './ProjectSlice';

import foxberry from '../../../Assets/Foxberry.svg';
import Track from '../../../Assets/logintrack.svg';
import LoginImg from '../../../Assets/loginSVG.svg';
import { setActiveKey } from '../../Common/ActiveKeySlice';


const Login = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userState = useSelector(state => state.loginData.userData);

  // for backbutton
  useEffect(() => {
    if (userState) {
      navigate("/")
    }
  }, []);

  useEffect(() => {
    if (userState) {
      if (userState.message === "Wrong Password") {
        setPassErr(true);
        setLoader(false);
      }
      else if (userState.message === 'No Employee found') {
        toast.error("Invalid credentials, No user found");
        setLoader(false);
      }
      else if (userState.resourceType === "employee") {
        setLoader(false);
        navigate('/employee');
      }
      else if (userState.resourceType === "intern") {
        setLoader(false);
        navigate('/intern');
      }
      else if (userState.resourceType === "contractual") {
        setLoader(false);
        navigate('/contractual');
      }

      else if (userState.resourceType === "admin") {
        setLoader(false);
        navigate('/admin');
      }
      else if (userState.resourceType === "teamlead") {
        setLoader(false);
        navigate('/teamlead');
      }
      else if (userState.resourceType === "manager") {
        setLoader(false);
        navigate('/manager');
      }
      else if (userState.resourceType === "superAdmin") {
        setLoader(false);
        navigate('/superAdmin');
      }
      else if (userState.resourceType === "humanResource") {
        setLoader(false);
        navigate('/humanResource');
      }
    }
  }, [userState])




  /// --- Data States -------
  const [id, setID] = useState('');
  const [pass, setPass] = useState('');

  // --- Err States ---------
  const [idErr, setIDErr] = useState(false);
  const [passErr, setPassErr] = useState(false);


  const [loader, setLoader] = useState(false);
  const [showPass, setShowPass] = useState(false);


  const validate = () => {
    let FormValid = true;

    if (!id) {
      FormValid = false;
      setIDErr(true);
    }
    else if (!pass) {
      FormValid = false;
      setPassErr(true);
    }
    return FormValid;
  }

  const handlerSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    if (validate()) {
      dispatch(getUserData({ empId: id, password: pass }));
      dispatch(setActiveKey({ activeKey: 1, activeTab: 'Home' }));
      dispatch(getProjects({ empId: id }));
    } else {
      setLoader(false);
    }
  }


  return (

    <div className='login'>
      <ToastContainer/>
      <Container fluid className='h-100'>
        <Row className='parent'>
          <Col md={6} className='left p-3 d-flex align-items-center'>
            <div className='w-100'>
              <div data-aos='fade-up' data-aos-delay="100">
                <h3 className='text-center'>Welcome to the Foxberry</h3>
                <p className='text-center subHeading'><Icon.BoxArrowInRight className="me-2" />Please login to your account</p>
              </div>

              <Form onSubmit={handlerSubmit}>
                <Form.Group className="mb-3" data-aos="fade-up" data-aos-delay="100">
                  <Form.Label>Employee ID<span> *</span></Form.Label>
                  <InputGroup>
                    <InputGroup.Text><Icon.PersonCheck size={25} /></InputGroup.Text>
                    <Form.Control name="ID" placeholder="Enter Employee ID" value={id}
                      onChange={(e) => {
                        if (!e.target.value.match(
                          /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{10,}$/g) && e.target.value.trim()) {
                          setID(e.target.value);
                        } else if (e.target.value.length === 0) {
                          setID(e.target.value);
                        }
                        id.length >= 0 ? setIDErr(false) : setIDErr(true);
                      }} />
                  </InputGroup>
                  {idErr ? <p className='errMsg'> Please enter valid EMP ID </p> : null}
                </Form.Group>

                <Form.Group className="mb-4" data-aos="fade-up" data-aos-delay="200">
                  <Form.Label> Password<span> *</span>
                  </Form.Label>
                  <InputGroup>
                    <InputGroup.Text onClick={() => setShowPass(!showPass)}>
                      {showPass ? <Icon.EyeSlash size={20} style={{ cursor: 'pointer' }} onClick={() => setShowPass(!showPass)} /> :
                        <Icon.Eye size={20} style={{ cursor: 'pointer' }} />}
                    </InputGroup.Text>

                    <Form.Control type={showPass ? "text" : "password"} placeholder="Enter password" name="pass"
                      value={pass} onChange={(e) => {
                        if (!e.target.value.match(
                          /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{10,}$/g) && e.target.value.trim()
                        ) {
                          setPass(e.target.value);
                        } else if (e.target.value.length === 0) {
                          setPass(e.target.value);
                        }
                        pass.length >= 0 ? setPassErr(false) : setPassErr(true);
                      }} />
                  </InputGroup>
                  {passErr ? <p className="errMsg">Password enter valid password</p> : null}
                </Form.Group>

                <div data-aos="fade-up" data-aos-delay="300">
                  {
                    loader ? <center><Spinner animation="border" variant="warning" /></center> :
                      <Button type='submit' className='loginBtn w-100'>
                        <Icon.BoxArrowInRight size={18} className='me-2' /> Log In
                      </Button>
                  }
                  <p className='text-end mt-2' style={{ fontSize: '12px', textDecoration: 'underline', cursor: 'pointer' }}
                    onClick={() => navigate('/forgot-pasword')}>Forgot password ?</p>

                  <p className="mt-4 mb-1" style={{ fontSize: '12px' }}> Don't have an account? </p>
                  <Button className="secondaryBtn w-100" onClick={() => navigate("/register")} disabled={loader}>
                    <Icon.PersonAdd className='me-2' />Create Account
                  </Button>
                </div>
              </Form>
            </div>

          </Col>

          <Col md={6} className='right py-2 py-md-0'>
            <img src={foxberry} alt='Foxberry' className='img-fluid mb-2' width={400} />
            <img src={Track} alt='Track' className='img-fluid mb-4' width={200} />
            <img src={LoginImg} alt='Logo' className='img-fluid' />
          </Col>
        </Row>
      </Container>
    </div>

  )
}

export default Login;