import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Container, Button, Form, Row, Col,} from "react-bootstrap";
import { getNoTask } from './AllTaskSlice';
import Pagination from './Pagination'
import { AssignNoTaskModel } from './Model/AssignNoTaskModel';
import * as Icon from 'react-bootstrap-icons';
import { FilterNoTaskModel } from './Model/FilterNoTaskModel';

const NoTask = () => {

    const dispatch = useDispatch()
    const loginUserData = useSelector((state) => state.loginData.userData);
    const NoTaskData = useSelector((state) => state.AllTasks.NoTask);

    // for notaskmodel
    const [shownotaskmodal, setShowNoTaskModal] = useState(false)
    const[notaskvalue,setNoTaskValue] = useState("")

    // for filter
     const [showFilter, setShowFilter] = useState(false)

    // for pagination state
    const [docsPerPage, setDocsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);

    const getNoTaskData = ()=>{
        const payload = {
            "taskDate": "",
            "lead": `${loginUserData?.firstName} ${loginUserData?.lastName}`,
            "empId":"",
            "name":"",
            "department":"",
            "designation":"",
            "documentsPerPage": docsPerPage,
            "page": currentPage
        }
         dispatch(getNoTask(payload))

    }
     useEffect(() => {
        getNoTaskData()
    }, [currentPage,docsPerPage])

    // for filter
    const handleCloseFilter = () => {
    setShowFilter(false)
  }

    //----- Pagination ---------------
   
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleDocsPerPage = (docsPageProp) => {
        setCurrentPage(1)
        setDocsPerPage(docsPageProp);
    }

    // for model open and close function
    const handleShowNoTaskModal = (id) => {
        setNoTaskValue(id)
        setShowNoTaskModal(true)
    }
    const handleNoTaskModalClose = () => {
        setShowNoTaskModal(false)
        setNoTaskValue("")
        getNoTaskData()
        }
    return (
        <div className='noTask outletPadding' data-aos="fade-up">
            <Container>
                <AssignNoTaskModel show={shownotaskmodal} close={handleNoTaskModalClose} data={notaskvalue} userData={loginUserData} />
                 <FilterNoTaskModel show={showFilter} close={handleCloseFilter}/>
                <Row className='d-flex justify-content-end'>
                        <Col md={2} className="d-flex justify-content-end align-items-center">
                            <Button className="primaryBtn d-flex align-items-center" onClick={() => { setShowFilter(true) }} >
                                <Icon.Funnel className='me-2' size={17} />Filter
                            </Button>
                        </Col>
                    </Row>

                <div className="outer-wrapper mx-auto">
                    <div className="table-wrapper">
                    <table>
                        <thead>
                            <tr >
                                <th>Sr No</th>
                                <th className='assignwidth1'>Employee ID</th>
                                <th className='assignwidth1'>Name</th>
                                <th className='assignwidth1'>Work Email</th>
                                <th className='assignwidth1'>Designation</th>
                                <th className='assignwidth1'>Department</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                NoTaskData?.data !== null &&
                                NoTaskData?.data !== undefined &&
                                NoTaskData?.data?.length > 0 ?
                                NoTaskData?.data?.map((itm,index) => {
                                    const { firstName, lastName, workEmail,designation,department,contactNo,empId} = itm
                                    return (
                                        <tr key={Math.random() * 999999999}>
                                            <td>{currentPage !== 1 ? (<> {index + 1 + 10 * (currentPage - 1)}</>) : <>{index + 1}</>}</td>
                                            <td>{empId}</td>
                                            <td>{firstName} {lastName}</td>
                                            <td>{workEmail}</td>
                                            <td>{designation}</td>
                                          <td>
                                             {department && Array.isArray(department) && department.map((row, index) => {
                                                if (index === department.length - 1) {
                                                    return row.label;
                                                } else {
                                                    return row.label + ', ';
                                                }
                                            })}
                                            {/* {department && Array.isArray(department) && department.map((itm) => itm?.label)} */}
                                            </td>
                                          <td>
                                                <div
                                                    data-toggle="modal">
                                                   <Button
                                                        className="primaryBtn my-1" style={{padding:'4px 12px'}}
                                                        onClick={() => { handleShowNoTaskModal(itm) }}>
                                                        <Icon.PersonAdd className='me-2'/>Assign
                                                    </Button>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                                :
                                <tr><td colSpan="18" className="text-center">No Data Available</td></tr>
                            }

                        </tbody>
                        </table>
                      </div>
                    </div>
          
                <Row className='justify-content-end'>
                    <Pagination currentPage={currentPage} totalPages={NoTaskData?.noOfPages}
                    onPageChange={handlePageChange} onDocsPerPage={handleDocsPerPage} />
                </Row>
            </Container>

        </div>
    )
}

export default NoTask