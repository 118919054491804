import React, { useEffect, useState } from 'react'
import { Table, Badge, Button, Row, Col } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';
import { useSelector } from 'react-redux';
import * as Icon from "react-bootstrap-icons";
import DeleteTaskModel from './Model/DeleteTaskModel';
import CreateSubTaskModel from './Model/CreateSubTaskModel';
import CompleteSubTaskModel from './Model/CompleteSubTaskModel';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EditSubTaskModel from './Model/EditSubTaskModel';
import Pagination from './Pagination';
import DisplayHoursAndMinutes from './Convertor/DisplayHoursAndMinutes';
import CompleteSelfSubTaskModel from './Model/CompleteSelfSubTaskModel';

const ViewSelfTaskDetails = () => {
    const location = useLocation();

    const loginUserData = useSelector((state) => state.loginData.userData)
    const deptNameArray = (loginUserData?.department).map((itm) => itm.label)
    const mainTaskId = location?.state?.data;
    const roleOfUser = ["employee","intern","contractual"]

    const [subTaskData, setSubTaskData] = useState([])
    const[allocatedsubTaskData,setAllocatedSubTaskData] = useState([])
    const [showdeletemodal, setShowDeleteModal] = useState(false);
    const [taskId, setTaskId] = useState("")
    const [showcreatemodal, setShowCreateModal] = useState(false)
    const [showcompletetaskmodal, setShowCompleteTaskModal] = useState(false);
    const [completetaskvalue, setCompleteTaskValue] = useState("")
    const [result, setResult] = useState('');
    const [showeditmodal, setShowEditModal] = useState("")
    const [editvalue, setEditValue] = useState("")

    // for complete main task modal
    const toastUpdate = () => {
        toast.error("Please Complete Your Subtask Status", {
            position: toast.POSITION.TOP_RIGHT,
        });
    };

    // status for pagination
    const [docsPerPage, setDocsPerPage] = useState(5);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState();

    useEffect(() => {
        getAllSubTaskData();
    }, [mainTaskId?.taskId, currentPage, docsPerPage,])

    useEffect(() => {
        mainTaskData()
        getAllAllocatedSubTaskData()
    }, [])

    // for display main task data 
    const mainTaskData = () => {
        const payload = {
            taskId: mainTaskId?.taskId
        }
        axios.post(process.env.REACT_APP_BASE_URL + "/task/getSingleTask", payload)
            .then((res) => {
                setResult(res?.data?.data)
            })
            .catch((error) => {
                console.log("Error:", error)
            })
    }
    // for display subtask data
    const getAllSubTaskData = () => {
        const payload = {
            headTaskId: mainTaskId?.taskId,
            documentsPerPage: docsPerPage,
            page: currentPage
        }

        if (mainTaskId?.taskId) {
            axios.post(process.env.REACT_APP_BASE_URL + '/task/particularTaskSubTask1', payload)
                .then((res) => {
                    setSubTaskData(res?.data);
                    setTotalPages(res?.data?.noOfPages);
                })
                .catch((err) => {
                    console.log("Error", err)
                })
        }
    }

    // subtask details for allocated task
    const getAllAllocatedSubTaskData = () => {
        const payload = {
            "taskId": mainTaskId?.taskId,
            "documentsPerPage": docsPerPage,
            "page": currentPage
        }
        if (mainTaskId?.taskId){
           axios.post(process.env.REACT_APP_NEW_BASE_URL + '/getAssignTasksByHeadTask', payload)
            .then((res) => {
                setAllocatedSubTaskData(res?.data);
            })
            .catch((err) => {
                console.log("Error", err)
            })
        }
    }

    // function for pagination
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleDocsPerPage = (docsPageProp) => {
        setCurrentPage(1);
        setDocsPerPage(docsPageProp);
    }

    // for open create subtask modal
    const handleShowCreateModal = () => {
        setShowCreateModal(true)
    }

    // for close create subtask model
    const handleCloseCreateModel = () => {
        setShowCreateModal(false)
        getAllSubTaskData();
    }

    // for complete main task modal
    const handleShowCompleteModal = (values) => {
        setCompleteTaskValue(values)
        setShowCompleteTaskModal(true)
    }

    const handleShowCompleteModalClose = () => {
        mainTaskData()
        setShowCompleteTaskModal(false)
        setCompleteTaskValue("")
    }

    // for edit modal
    const handleShowEditModal = (values) => {
        setEditValue(values)
        setShowEditModal(true)
    }

    const handleEditModalClose = () => {
        getAllSubTaskData();
        setShowEditModal(false)
        setEditValue("")
    }

    // for delete modal
    const handleShowDeleteModal = (id) => {
        setTaskId(id)
        setShowDeleteModal(true)
    }

    const handleDeleteModalClose = () => {
        getAllSubTaskData();
        setShowDeleteModal(false)
        setTaskId("")
    }

    // for status allocated filter 
    const filterAllocatedData = allocatedsubTaskData?.data?.filter((itm) => itm.status === "pending");
    const statusAllocatedArray = filterAllocatedData && filterAllocatedData?.length > 0 ? filterAllocatedData?.map((item) => item.status) : [];

     // for status selftask filter 
    const filterSelfData = subTaskData?.data?.filter((itm) => itm.status === "pending");
    const statusSelfArray = filterSelfData && filterSelfData?.length > 0 ? filterSelfData?.map((item) => item.status) : [];

    // sum for actual hours
   const formattedActualHrCount = subTaskData?.data !== null &&
    subTaskData?.data !== undefined &&
    subTaskData?.data?.length > 0 &&
    subTaskData?.data?.reduce(function (prev, current) {
        return prev + +current.actualHours;
    }, 0);

    const actualHrCount = typeof formattedActualHrCount === 'number' && !isNaN(formattedActualHrCount)
        ? formattedActualHrCount.toFixed(2)
        : formattedActualHrCount;
        
    // sum for allocated actual hours
    const formattedActualAllocatedHrCount = allocatedsubTaskData?.data !== null &&
    allocatedsubTaskData?.data !== undefined &&
    allocatedsubTaskData?.data?.length > 0 &&
    allocatedsubTaskData?.data?.reduce(function (prev, current) {
        return prev + +current.actualHours;
    }, 0);

    const actualAllocatedHrCount = typeof formattedActualAllocatedHrCount === 'number' && !isNaN(formattedActualAllocatedHrCount)
        ? formattedActualAllocatedHrCount.toFixed(2)
        : formattedActualAllocatedHrCount;

    return (
        <div className='ViewAllTask outletPadding'>
            <div className="m-3">
                 <Row>
                    <Col md={4}>
                       <Button className='primaryBtn me-3' 
                       onClick={() => window.history.back()}
                       >
                            <Icon.ArrowLeftShort className='me-1' size={18} />
                            Back
                        </Button>
                    </Col>
                </Row>
                <Row className='mt-2'>
                    <Col md={12} className="ViewTaskHeading">
                        <h4>Task Details</h4>
                    </Col>
                </Row>
                <CreateSubTaskModel show={showcreatemodal} close={handleCloseCreateModel} result={result} />
                <DeleteTaskModel show={showdeletemodal} close={handleDeleteModalClose} taskId={taskId} />
                <CompleteSelfSubTaskModel show={showcompletetaskmodal} close={handleShowCompleteModalClose} completetaskvalue={completetaskvalue} result={result} actualHrCount={actualHrCount} actualAllocatedHrCount = {actualAllocatedHrCount}/>
                {/* <CompleteSubTaskModel show={showcompletetaskmodal} close={handleShowCompleteModalClose} completetaskvalue={completetaskvalue} result={result} actualHrCount={actualHrCount} actualAllocatedHrCount = {actualAllocatedHrCount}/> */}
                <EditSubTaskModel show={showeditmodal} close={handleEditModalClose} data={editvalue} result={result} />

                <div style={{ border: "1px solid black" }} className="mt-2">
                    <Table responsive="sm" bordered>
                        <tbody className="mt-4">
                            <tr>
                                <th colSpan={2}>Task Assign Project</th>
                                <td>{result?.projectName}</td>
                                <th >Task Title</th>
                                <td>{result?.taskName}</td>

                            </tr>
                            <tr>
                                <th colSpan={2}>Task Priority</th>
                                <td>{result?.taskPriority}</td>
                                {roleOfUser.includes(loginUserData.resourceType) &&
                                    <>
                                        <th >Task Assign By</th>
                                        <td>{result?.assignBy_Name}</td>
                                    </>
                                }

                               { roleOfUser.includes(loginUserData.resourceType) &&
                                    <>
                                        <th >Task Assign To</th>
                                        <td>{result?.resourceName}</td>
                                    </>
                                }

                            </tr>
                            <tr>
                                <th colSpan={2}>Task Description</th>
                                <td colSpan={3} style={{ whiteSpace: "pre-wrap" }}>{result?.taskDescription}</td>
                            </tr>
                            <tr>
                                <th colSpan={2}>Task Department</th>
                                <td>{result?.departmentName}</td>
                                <th>Task Category</th>
                                <td>{result?.categoryOfName}</td>
                            </tr>

                            <tr>
                                <th colSpan={2}>Task Assign date</th>
                                <td>
                                    {new Date(result?.proposedStartDate).toLocaleDateString('en-GB')}
                                </td>
                                <th>Task Finish Date</th>
                                <td>
                                    {new Date(result?.proposedEndDate).toLocaleDateString('en-GB')}
                                </td>
                            </tr>

                            <tr>
                                <th colSpan={2}>Task Created date</th>
                                <td>
                                    {new Date(result?.createdAt).toLocaleDateString('en-GB')}
                                </td>
                                <th>Task Initial Estimate(Hours)</th>
                                <td><DisplayHoursAndMinutes value={result?.initialEstimateHr}/></td>
                            </tr>

                            <tr>
                                <th colSpan={2}>Actual Hours</th>
                                <td>
                                     {result?.status === "completed" ? <DisplayHoursAndMinutes value = {result?.actualHours}/> : 0 }
                                </td>
                            </tr>

                            <tr>
                                <th colSpan={2}>IDC</th>
                                <td>{result?.idc ? result?.idc.toFixed(2) : 0}</td>
                                <th >QWD</th>
                                <td>
                                    {result?.qwd ?result?.qwd.toFixed(2) : 0}
                                </td>
                            </tr>

                            <tr>
                                <th colSpan={2}>Efficiency</th>
                                <td >{result?.efficiency !== null && result?.efficiency !== undefined ? result?.efficiency.toFixed(2) : 0}</td>
                                <th >Slippage</th>
                                <td >{result?.slippage !== null && result?.slippage !== undefined ? result?.slippage.toFixed(2) : 0}</td>
                            </tr>
                             <tr>
                                <th colSpan={2} >Status</th>
                                <td className='text-capitalize'>{result?.status}</td>
                                <th >Remark</th>
                                <td >{result?.remark ? result?.remark : "Not Remark Update Yet..."}</td>
                            </tr>
                            {/* <tr>
                                <th colSpan={2}>{result?.isRejected ? "Rejected Reason" : "Task Final Status"}</th>
                                <td colSpan={3}>{result?.isRejected ? result?.rejectReason : result?.status}</td>
                            </tr>

                            <tr>
                                {!result?.isRejected && 
                                <>
                                 <th colSpan={2}>Remark</th>
                                <td colSpan={3}>{result?.remark ? result?.remark : "Not Remark Update Yet..."}</td>
                                </>
                                }
                            </tr> */}
                        </tbody>
                    </Table>
                </div>

                {
                    (
                        <>
                     <Button
                        className='primaryBtn ms-auto mt-4 mb-4'
                        onClick={() => {
                            if (
                            (roleOfUser.includes(loginUserData?.resourceType) ||
                                deptNameArray.some(item =>
                                mainTaskId?.departmentName?.split(',').includes(item)
                                )) &&
                            statusSelfArray?.length === 0
                            ) {
                            handleShowCompleteModal();
                            } else if (
                            (!deptNameArray ||
                                !mainTaskId?.departmentName ||
                                !deptNameArray.some(item =>
                                mainTaskId.departmentName.split(',').includes(item)
                                )) &&
                            statusAllocatedArray?.length === 0
                            ) {
                            handleShowCompleteModal();
                            } else {
                            toastUpdate();
                            }
                        }}
                        disabled={result?.status === "completed"}
                        >
                        Complete Task
                    </Button>
                    <Row className='mt-4 mb-2'>
                            <Col md={12} className="ViewTaskHeading">
                                <h4>SubTask Details</h4>
                            </Col>
                    </Row>
                        
                    <div className='outer-wrapper mx-auto'>
                             <div className='table-wrapper'>
                                <table>
                                    <thead>
                                            <th>Sr No</th>
                                            <th className='assignwidth'>Sub Task</th>
                                            {!deptNameArray ||
                                            !mainTaskId?.departmentName ||
                                            !deptNameArray.some(item => mainTaskId.departmentName.split(',').includes(item)) && <th>Assign To</th>}
                                            <th>Actual Start Date</th>
                                            <th>Actual End Date</th>
                                            <th>Estimate Hours</th>
                                            <th>Actual Hours</th>
                                            <th>Task Completion</th>
                                            <th>Remarks</th>
                                            <th>Status</th>
                                            <th>Created Date</th>
                                            {roleOfUser.includes(loginUserData?.resourceType) ||
                                            deptNameArray.some(item => mainTaskId?.departmentName?.split(',').includes(item)) &&
                                            <th>Action</th>
                                            }
                                    </thead>

                                <tbody>
                                    {roleOfUser.includes(loginUserData?.resourceType) ||
                                    deptNameArray.some(item => mainTaskId?.departmentName?.split(',').includes(item)) && 
                                    subTaskData?.data !== null &&
                                    subTaskData?.data !== undefined &&
                                    subTaskData?.data?.length > 0 ? (
                                        subTaskData?.data?.map((values, index) => {
                                        return (
                                            <>
                                                        <tr
                                                            style={{
                                                                textAlign: "center",
                                                                minWidth: "100px",
                                                            }}
                                                        >
                                                            <td>{index + 1}</td>
                                                            <td>{values?.taskName}</td>

                                                            <td>
                                                                {new Date(values?.actualStartDate).toLocaleDateString('en-GB')}
                                                            </td>

                                                            <td>
                                                                {new Date(values?.actualEndtDate).toLocaleDateString('en-GB')}
                                                            </td>

                                                             <td><DisplayHoursAndMinutes value={result?.initialEstimateHr}/></td>
                                                             <td><DisplayHoursAndMinutes value={values?.actualHours}/></td>
                                                            <td>
                                                                {values?.complitionByResource ? values?.complitionByResource : 0}
                                                                {"%"}
                                                            </td>
                                                            <td>{values?.remark}</td>
                                                            <td>
                                                                <Badge bg={values?.status === "pending" ? "warning p-1" : "success p-1 text-white"} text="dark">{values?.status}</Badge>

                                                            </td>
                                                            <td>{new Date(values?.createdAt).toLocaleDateString('en-GB')}</td>
                                                            {
                                                                loginUserData?.resourceType === "employee" ||
                                                                deptNameArray.some(item => mainTaskId?.departmentName?.split(',').includes(item)) &&

                                                                <td className="">
                                                                    <span
                                                                        style={{
                                                                            color: "#000000",
                                                                            fontSize: "20px",
                                                                            marginRight: "5px",
                                                                        }}
                                                                        data-toggle="tooltip"
                                                                        title="edit"
                                                                    >

                                                                        <Button style={{
                                                                            background: 'none', padding: '0px',
                                                                            border: 'none', color: 'black', fontSize: '20px'
                                                                        }}
                                                                            disabled={result?.status === 'completed' ? true : false}>
                                                                            <Icon.PencilSquare onClick={() => { handleShowEditModal(values) }}
                                                                            />
                                                                        </Button>
                                                                    </span>
                                                                    <span
                                                                        style={{
                                                                            color: "#000000",
                                                                            fontSize: "20px",
                                                                            marginLeft: "3px",
                                                                        }}
                                                                        data-toggle="tooltip"
                                                                        title="delete"
                                                                    >
                                                                        <Button
                                                                            style={{
                                                                                background: 'none',
                                                                                padding: '0px',
                                                                                border: 'none',
                                                                                color: 'black',
                                                                                fontSize: '20px'
                                                                            }}
                                                                        >
                                                                            <Icon.Trash onClick={() => { handleShowDeleteModal(values?.taskId) }} />
                                                                        </Button>
                                                                    </span>
                                                                </td>
                                                            }
                                                        </tr>
                                                    </>
                                        );
                                        })
                                    ) : (
                                        !deptNameArray ||
                                        !mainTaskId?.departmentName ||
                                        !deptNameArray.some(item => mainTaskId.departmentName.split(',').includes(item)) 
                                        && allocatedsubTaskData !== null &&
                                        allocatedsubTaskData !== undefined &&
                                        allocatedsubTaskData?.length > 0 ? (
                                        allocatedsubTaskData.map((values, index) => {
                                            return (
                                            <>
                                                        <tr>
                                                            <td>{index + 1}</td>
                                                            <td>{values?.taskName}</td>
                                                            <td>{values?.resourceName}</td>

                                                            <td>
                                                                {new Date(values?.proposedStartDate).toLocaleDateString('en-GB')}
                                                            </td>

                                                            <td>
                                                                {new Date(values?.proposedEndDate).toLocaleDateString('en-GB')}
                                                            </td>

                                                           <td><DisplayHoursAndMinutes value={result?.initialEstimateHr}/></td>
                                                           <td><DisplayHoursAndMinutes value={values?.actualHours}/></td>
                                                            <td>
                                                                {values?.complitionByResource ? values?.complitionByResource : 0}
                                                                {"%"}
                                                            </td>
                                                            <td>{values?.remark}</td>
                                                            <td>
                                                                <Badge bg={values?.status === "pending" ? "warning p-1" : "success p-1 text-white"} text="dark">{values?.status}</Badge>

                                                            </td>
                                                            <td>{new Date(values?.createdAt).toLocaleDateString('en-GB')}</td>
                                                        </tr>
                                                    </>
                                            );
                                        })
                                        ) : (
                                        <tr>
                                            <td colSpan={13} className='text-center'>
                                            No task available
                                            </td>
                                        </tr>
                                        )
                                    )}
                                </tbody>
                                </table>
                             </div>

                        {
                            deptNameArray.some(item => result?.departmentName?.split(',').includes(item))
                            &&
                            <div className='text-center mt-4 mb-4'>
                                <Button className='primaryBtn mx-auto' onClick={handleShowCreateModal}
                                    disabled={result?.status === "completed"}>
                                    Create SubTask
                                </Button>
                            </div>
                        }
                    </div>

                    <Pagination currentPage={currentPage} totalPages={subTaskData?.noOfPages}
                    onPageChange={handlePageChange} onDocsPerPage={handleDocsPerPage} docsPerPage={docsPerPage} />
                    </>
                )
                }
                    

            </div>
        </div >
    )
}

export default ViewSelfTaskDetails