import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Container, Form, Image, Row } from 'react-bootstrap';
import moment from "moment";
import { ToastContainer } from 'react-toastify';
import * as Icon from "react-bootstrap-icons";
import Pagination from '../Common/Pagination';
import { getSelfReview } from './EmpSlice';
import { EmpReviewModel } from './Model/EmpReviewModel';

import Lottie from 'react-lottie';
import * as animationData from '../../Assets/NoDataFound.json'

const EmpSelfReview = () => {

  const dispatch = useDispatch()

  const loginUserData = useSelector((state) => state.loginData.userData)
  const selfReviewData = useSelector(state => state?.employee?.EmpSelfReview)
  const filterAllTasks = useSelector((state) => state?.AllTasks?.AllFilterTasksData);

  const [docsPerPage, setDocsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [showFilter, setShowFilter] = useState(false)

  // for show data not found
  const [showNoDataFound, setShowNoDataFound] = useState(false);

  // for no data found true false
  const handleFilterTrue = () => {
    setShowNoDataFound(true)
  }

  const handleFilterFalse = ()=>{
    setShowNoDataFound(false)
  }

  const getReviewData = () => {

    const empPayload = {
      "empId": loginUserData?.empId,
      "taskName": "",
      "projectName": "",
      "resource_id": "",
      "departments": "",
      "idcMin": "",
      "idcMax": "",
      "qwdMin": "",
      "qwdMax": "",
      "slippage": "",
      "efficiency": "",
      "reviewDate": "",
      "documentsPerPage": docsPerPage,
      "page": currentPage
    }
    dispatch(getSelfReview(empPayload))
  }

  useEffect(() => {
    getReviewData()
  }, [currentPage, docsPerPage])

  const handlerCloseFilterModel = () => setShowFilter(false)

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);

  };

  const handleDocsPerPage = (docsPageProp) => {
    setCurrentPage(1)
    setDocsPerPage(docsPageProp);
  }

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  }

  return (
    <>
      <div className='outletPadding' data-aos="fade-up">
        <Container>
          <EmpReviewModel 
             show={showFilter} 
             close={handlerCloseFilterModel} 
             allReviewData={selfReviewData} 
             handleFilterTrue = {handleFilterTrue}
             handlerFilterFalse = {handleFilterFalse}
             showNoDataFound={showNoDataFound}
             currentPage={currentPage}
             docsPerPage={docsPerPage}
             />
          <ToastContainer />
          <Row className='d-flex justify-content-end'>
            {/* <Col md={3} className="mt-3 d-flex justify-content-start align-items-center">
              <Form.Control type="text" placeholder="search..." onChange={(e) => setSearchTerm(e.target.value)} />
            </Col> */}
            { selfReviewData?.data !== null || selfReviewData?.data?.length > 0 ?
            <Col md={2} className="d-flex justify-content-end align-items-center">
              <Button 
              // className="primaryBtn d-flex align-items-center" 
              className={` ${filterAllTasks?.data?.length > 0 || showNoDataFound  ? 'activeBtn': 'primaryBtn'}`}
              onClick={() => { setShowFilter(true) }}>
                <Icon.Funnel className='me-2' size={17} />Filter
              </Button>
            </Col> : '' }
            
          </Row>

          <div className='outer-wrapper mx-auto'>
            {/* krupa change */}
            {selfReviewData?.data === null || selfReviewData?.data?.length == 0 ?
              <Lottie options={defaultOptions} weight={300} width={300} className='img-fluid' style={{ overflow: 'hidden' }} /> :
              <div className='table-wrapper'>
                <table>
                  <thead>
                    <tr>
                      <th>Sr.No</th>
                      <th>Project Name</th>
                      <th className='taskwidth'>Task Name</th>
                      <th className='assignwidth'>Assign By</th>
                      <th>Department</th>
                      <th>IDC</th>
                      <th>QWD</th>
                      <th>Slippage</th>
                      <th>Efficiency</th>
                      <th>Review Date</th>
                    </tr>
                  </thead>
     
                  {filterAllTasks?.data?.length > 0 || !showNoDataFound ? (
                  <tbody>
                    {
                      selfReviewData?.data !== null &&
                      selfReviewData?.data !== undefined &&
                      selfReviewData?.data?.length > 0 && (
                      (filterAllTasks?.data?.length > 0 
                      ? filterAllTasks?.data
                      : selfReviewData?.data
                        ).map((itm, index) => {
                          const { taskName, reviewDate, departmentName, taskDepartmentName, projectName,
                            categoryOfName, assignBy_Name, resourceName,
                            idc, qwd, slippage, efficiency } = itm
                          return (
                            <tr key={Math.random() * 999999999}>
                              <td> {currentPage !== 1 ? (
                                <>
                                  {index + 1 + docsPerPage * (currentPage - 1)}
                                </>
                              ) : (
                                <>{index + 1}</>
                              )}</td>
                              <td>{projectName}</td>
                              <td style={{ flexWrap: "wrap" }}>{taskName}</td>
                              <td>{assignBy_Name}</td>
                              <td>{taskDepartmentName}</td>
                              <td>{idc !== null && idc !== undefined ? idc.toFixed(2) : 0}</td>
                              <td>{qwd !== null && qwd !== undefined ? qwd.toFixed(2) : 0}</td>
                              <td>{slippage !== null && slippage !== undefined ? slippage.toFixed(2) : 0}</td>
                              <td>{efficiency !== null && efficiency !== undefined ? efficiency.toFixed(2) : 0}</td>
                              <td>{moment(reviewDate).format("DD-MM-YYYY")}</td>
                            </tr>
                          )
                        })
                      )}
                  </tbody>
                  )
                  :
                 <tr>
                  <td colSpan="16" className="text-center">No Task Available</td>
                </tr>
                }
                </table>
              </div>
            }
          </div>

        </Container>
        {
          selfReviewData?.data !== null || selfReviewData?.data?.length > 0 ?
          <div className="pagination-wrapper p-0">
            <Pagination 
               currentPage={currentPage} 
               totalPages={filterAllTasks?.data?.length > 0 ?filterAllTasks?.noOfPages : selfReviewData?.noOfPages}
               onPageChange={handlePageChange} 
               onDocsPerPage={handleDocsPerPage} 
               docsPerPage={docsPerPage}
            />
          </div>
          : ''
                }
      </div>
    </>
  )
}

export default EmpSelfReview