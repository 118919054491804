import { combineReducers, configureStore } from '@reduxjs/toolkit';
import LoginReducer from '../Features/Auth/Login/LoginSlice';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import sidebarReducer from '../Features/Common/ActiveKeySlice';
import ProjectReducer from '../Features/Auth/Login/ProjectSlice';
import MngReducer from "../Features/Manager/MngSlice";
import TLReducer from '../Features/TeamLead/TLSlice';
import AllTaskReducer from '../Features/Common/AllTaskSlice';
import employeeReducer from '../Features/HR/HrSlice'
import EmpReducer from '../Features/Employee/EmpSlice';
import AdminReducer from '../Features/Admin/AdminSlice'

const reducers = combineReducers({
  loginData: LoginReducer,
  activeData : sidebarReducer,
  projectData : ProjectReducer,
  mngData : MngReducer,
  TLAssignedData: TLReducer,
  AllTasks:AllTaskReducer,
  employeeDetails : employeeReducer,
  employee:EmpReducer,
  admin:AdminReducer
})

const persistConfig = {
  key: 'root',
  storage
}

// for empty state after logout
const rootReducer = (state,action) =>{
  if(action.type === "userData/setLogout"){
    state = undefined
  }
  return reducers(state,action)
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

export const persistor = persistStore(store);
