import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Container, InputGroup, Table, Modal, Button, Form, Row, Col, Spinner, Nav, Tab, Image } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import * as Icon from "react-bootstrap-icons";
import { useLocation, useNavigate } from 'react-router-dom';
import normal from "../../images/gif/frame-88.png"
import medium from "../../images/gif/Ripple-6.3s-200px.gif";
import immediate from "../../images/gif/Ripple-1s-200px (1).gif"
import Badge from 'react-bootstrap/Badge';
import { getAllAllocatedTask,setActiveToggle,setFilterTasksData} from './AllTaskSlice';
import Pagination from './Pagination'
import FilterTaskModel from './Model/FilterTaskModel';
import { AcceptModel } from './Model/AcceptModel';
import { RejectModel } from './Model/RejectModel';
import { AssignAllocatedTaskModel } from './Model/AssignAllocatedTaskModel';
import { FilterSelfTaskModel } from './Model/FilterSelfTaskModel';
import { FilterAllocatedTaskModel } from './Model/Filter AllocatedTaskModel';
import DisplayHoursAndMinutes from './Convertor/DisplayHoursAndMinutes';

const AllocatedTask = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const currentToggle = useSelector((state) => state?.AllTasks?.ActiveToggle);
    const loginUserData = useSelector((state) => state.loginData.userData)
    const deptNameArray = (loginUserData?.department).map((itm) => itm.label)
    const allAllocatedTasks = useSelector(state => state?.AllTasks?.AllocatedTask)
    const filterAllTasks = useSelector((state) => state?.AllTasks?.AllFilterTasksData);

    const [show, setShow] = useState(false)
    const [showfilter, setShowFilter] = useState(false)
    const [docsPerPage, setDocsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [item, setItem] = useState();
     
    // for filter tab value reset
   const [pendingFilterValues, setPendingFilterValues] = useState({});
   const [completedFilterValues, setCompletedFilterValues] = useState({});
   const[rejectedFilterValues,setRejectedFilterValues] = useState({})

    const [taskId, setTaskId] = useState("")
    const [showAssignModel, setShowAssignModel] = useState(false)
    const [showAcceptModel, setShowAcceptModel] = useState(false)
    const [showRejectModel, setShowRejectModel] = useState(false)

    // for show data not found
    const [showNoDataFound, setShowNoDataFound] = useState(false);

    // for no data found true false
    const handleFilterTrue = () => {
        setShowNoDataFound(true)
    }

    const handleFilterFalse = ()=>{
        setShowNoDataFound(false)
    }

    // for Allocated modal
    const getAllAllocatedTaskData = () => {
         const payload = {
            "empId": loginUserData?.empId,
            "taskPriority": "",
            "taskName": "",
            "department": "",
            "projectName": "",
            "resource_id": "",
            "proposedStartDate": "",
            "proposedEndDate": "",
            "status": currentToggle,
            "idcMin": "",
            "idcMax": "",
            "qwdMin": "",
            "qwdMax": "",
            "slippage": "",
            "efficiency": "",
            "assignBy_Name": "",
            "documentsPerPage": docsPerPage,
            "page": currentPage
        }
      dispatch(getAllAllocatedTask(payload))
    }

    useEffect(() => {
        getAllAllocatedTaskData()
    }, [currentPage, docsPerPage, currentToggle])

    
// for status toggle change
  const handleToggleChange = (toggleValue) => {
    dispatch(setActiveToggle(toggleValue));
    if (toggleValue === 'pending') {
      setCompletedFilterValues({});
    } else if(toggleValue === "completed") {
      setPendingFilterValues({});
    }else{
      setRejectedFilterValues({});
    }
  };

    
    const handlerCloseFilterModel = () => setShowFilter(false)

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);

    };

    const handleDocsPerPage = (docsPageProp) => {
        setCurrentPage(1);
        setDocsPerPage(docsPageProp);

    }

   // for accept model
    const handleAcceptModel = (id) => {
        setTaskId(id);
        setShowAcceptModel(true)
    }

    // for delete model
    const handleRejectModel = (id) => {
        setShowRejectModel(true)
        setTaskId(id);
    }

    const handleAssignModelOpen = (id) => {
        setTaskId(id);
        setShowAssignModel(true)
    }

    const handleAssignModelClose = (id) => {
        setTaskId(id);
        setShowAssignModel(false)
    }

    const handleCloseAcptModel = () => { getAllAllocatedTaskData(); setShowAcceptModel(false) }
    const handleCloseRjctModel = () => { getAllAllocatedTaskData(); setShowRejectModel(false) }
   
    return (
        <div className='TLAssignedTask outletPadding' data-aos='fade-up'>
            <Container>
                <FilterAllocatedTaskModel 
                    show={showfilter} 
                    close={handlerCloseFilterModel} 
                    taskStatus={currentToggle}
                    handleFilterTrue = {handleFilterTrue}
                    handlerFilterFalse = {handleFilterFalse}
                    showNoDataFound={showNoDataFound}
                    currentPage={currentPage}
                    docsPerPage={docsPerPage}
                    filterValues={
                    currentToggle === 'pending'
                    ? pendingFilterValues
                    : currentToggle === "completed" ? completedFilterValues : rejectedFilterValues
                }/>
                <AssignAllocatedTaskModel show={showAssignModel} close={handleAssignModelClose} data={taskId} />
                <AcceptModel show={showAcceptModel} close={handleCloseAcptModel} taskId={taskId} />
                <RejectModel show={showRejectModel} close={handleCloseRjctModel} taskId={taskId} />

                <Row className='d-flex justify-content-between align-items-center'>
                    <Col md={6} className="d-flex justify-content-start align-items-center">
                        <Button
                            className={` ${filterAllTasks?.data?.length > 0 || showNoDataFound  ? 'activeBtn': 'primaryBtn'}`}
                            onClick={() => setShowFilter(true)}
                            >
                            <Icon.Funnel className='me-2' size={17} />
                            Filter
                        </Button>
                    </Col>
                     <Col sm={12} md={6} className='d-flex justify-content-end align-items-center'>
                        <ButtonGroup aria-label="Basic example">
                        <Button
                            disabled={currentToggle === "pending" ? true : false}
                            variant={currentToggle === 'pending' ? 'warning' : 'outline-warning'} // Use 'warning' when active, 'light' otherwise
                            onClick={() => {handleToggleChange('pending');dispatch(setFilterTasksData([]));setShowNoDataFound(false)
                            }}
                        >
                            Pending
                        </Button>
                        <Button
                            disabled={currentToggle === "completed" ? true : false}
                            variant={currentToggle === 'completed' ? 'success' : 'outline-success'}
                            onClick={() => {handleToggleChange('completed');dispatch(setFilterTasksData([]));setShowNoDataFound(false)}}
                        >
                            Completed
                        </Button>
                        <Button
                            disabled={currentToggle === "rejected" ? true : false}
                            variant={currentToggle === 'rejected' ? 'danger' : 'outline-danger'}
                            onClick={() => {handleToggleChange('rejected');dispatch(setFilterTasksData([]));setShowNoDataFound(false)}}
                        >
                            Rejected
                        </Button>
                        </ButtonGroup>
                    </Col>
                </Row>
                <div className='outer-wrapper mx-auto'>
                    <div className='table-wrapper'>
                        <table>
                            <thead>
                                <tr className='tbl-header'>
                                    <th>Priority</th>
                                     {currentToggle === "pending" && 
                                    <th>Due(Days)</th>}
                                    <th>Project</th>
                                    <th className='taskwidth'>Task Name</th>
                                    <th>Department</th>
                                    <th className = "max-width-th">Category</th>
                                    <th className='assignwidth'>Assign By</th>
                                    <th>Date</th>
                                    <th>Initial Hr</th>
                                    {
                                        currentToggle === "completed" &&
                                        <>
                                            <th>Actual Hr</th>
                                            <th>IDC</th>
                                            <th>QWD</th>
                                            <th>Slippage</th>
                                            <th>Efficiency</th>

                                        </>
                                    }
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            {filterAllTasks?.data?.length > 0 || !showNoDataFound ? (
                            <tbody>
                                {
                                  allAllocatedTasks?.data !== null &&
                                   allAllocatedTasks?.data !== undefined &&
                                   allAllocatedTasks?.data?.length > 0 ? (
                                    (filterAllTasks?.data?.length > 0 
                                    ? filterAllTasks?.data
                                    : allAllocatedTasks?.data
                                    ).map((itm) => {
                                    const { taskName, taskPriority, departmentName, taskDepartmentName, projectName, categoryOfName, assignBy_Name, resourceName, proposedStartDate, proposedEndDate, status, idc, qwd, slippage, efficiency, taskId, isAccepted, isRejected, initialEstimateHr,actualHours } = itm;

                                     // for convert hours and minute
                                    const parts = initialEstimateHr.toFixed(2).toString().split(".");
                                    const integerPart = parseInt(parts[0]);
                                    const decimalPart = parseInt(parts[1]);
                                    const hours = integerPart;
                                    const minutes = Math.round((decimalPart / 100) * 60);


                                    // for due days
                                    const currentDate = new Date();
                                    const endDate = new Date(itm.proposedEndDate);
                                    const endTimestamp = Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());
                                    const currentTimestamp = Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());

                                    let daysDifference = 0; 
                                    if (currentTimestamp > endTimestamp) {
                                        const differenceMillis = Math.abs(currentTimestamp - endTimestamp);
                                        daysDifference = Math.ceil(differenceMillis / (1000 * 60 * 60 * 24));
                                    }
                                        return (
                                            <tr key={Math.random() * 999999999}>
                                                <td>
                                                    {taskPriority === "normal" ?
                                                        <img width={50} height={50} src={normal} data-toggle="tooltip" title="Normal" />
                                                        :
                                                        taskPriority === "medium"
                                                            ?
                                                            <img width={50} height={50} src={medium} data-toggle="tooltip" title="Medium" />
                                                            :
                                                            taskPriority === "immediate"
                                                                ?
                                                                <img width={50} height={50} src={immediate} data-toggle="tooltip" title="Immediate" />
                                                                : ''
                                                    }
                                                </td>
                                                {currentToggle === "pending" && 
                                                <td>
                                                    <div className={`circle ${daysDifference < 5 ? 'green' : daysDifference < 15 ? 'orange' : 'red'}`} style = {{height:'35px',width:'35px',borderRadius:'55%',margin:'auto'}}>
                                                    <span className="number">{Math.floor(daysDifference)}</span>
                                                    </div>
                                                </td>
                                                }
                                                <td>{projectName}</td>
                                                <td>{taskName}</td>
                                                <td>{departmentName}</td>
                                                <td>{categoryOfName}</td>
                                                <td>{assignBy_Name}</td>
                                                <td>
                                                    {new Date(
                                                        proposedStartDate
                                                    ).toLocaleDateString("en-US", {
                                                        month: "long",
                                                        year: "numeric",
                                                    }) ==
                                                        new Date(proposedEndDate).toLocaleDateString("en-US", { month: "long", year: "numeric" })
                                                        ? `${new Date(
                                                            proposedStartDate
                                                        ).getDate()} - ${new Date(
                                                            proposedEndDate
                                                        ).getDate()} ${new Date(
                                                            proposedStartDate
                                                        ).toLocaleDateString("en-US", {
                                                            month: "long",
                                                            year: "numeric",
                                                        })}`
                                                        : `${new Date(
                                                            proposedStartDate
                                                        ).getDate()} ${new Date(
                                                            proposedStartDate
                                                        ).toLocaleDateString("en-US", {
                                                            month: "long",
                                                            year: "numeric",
                                                        })} -  ${new Date(
                                                            proposedEndDate
                                                        ).getDate()} ${new Date(
                                                            proposedEndDate
                                                        ).toLocaleDateString("en-US", {
                                                            month: "long",
                                                            year: "numeric",
                                                        })}`}
                                                </td>
                                                {/* <td>{initialEstimateHr ? initialEstimateHr.toFixed(2)  : 0}</td> */}
                                                <td><DisplayHoursAndMinutes value={initialEstimateHr} /></td>

                                                {
                                                    currentToggle === "completed" &&
                                                    <>
                                                        <td><DisplayHoursAndMinutes value={actualHours} />{' '}</td>
                                                        <td>{idc !== null && idc !== undefined ? idc.toFixed(2) : 0}</td>
                                                        <td>{qwd !== null && qwd !== undefined ? qwd.toFixed(2) : 0}</td>
                                                        <td>{slippage !== null && slippage !== undefined ? slippage.toFixed(2) : 0}</td>
                                                        <td>{efficiency !== null && efficiency !== undefined ? efficiency.toFixed(2) : 0}</td>
                                                    </>
                                                }

                                               <td className='text-capitalize'>
                                                    <Badge
                                                        bg={
                                                        status === "rejected"
                                                            ? 'danger p-1'
                                                            : status === 'completed'
                                                            ? 'success p-1 text-white'
                                                            : 'warning text-dark p-1'
                                                        }
                                                    >
                                                        {status}
                                                    </Badge>
                                                </td>
                                                <td>
                                                    <span data-toggle="tooltip" title="view"  >
                                                        {/* disabled={!isAccepted && !isRejected} */}
                                                        <Icon.Eye size={22} className='me-2'
                                                        onClick={() => {
                                                            // if (!isAccepted && !isRejected) {
                                                            //     return; // Do nothing if both isAccepted and isRejected are false
                                                            // }

                                                            navigate(`/${loginUserData?.resourceType}/allocated-task/view-details`, {
                                                                state: { data: itm }
                                                            });
                                                            }} 
                                                        // onClick={() => {
                                                        //     navigate(`/${loginUserData?.resourceType}/self-task/view-details`, {
                                                        //         state: { data: itm }
                                                        //     })
                                                        // }} 
                                                        />
                                                    </span>
                                                   

                                                    {/* comment edit and delete button */}
                                                    {
                                                        status !== "completed" &&
                                                        <>
                                                                    {
                                                                        !isAccepted && !isRejected &&
                                                                        <>
                                                                            <Icon.CheckCircle size={20} onClick={() => { handleAcceptModel(taskId); }} className="complete-icon me-2"  color='green' />
                                                                            <Icon.XCircle size={20} className="pending-icon me-2" onClick={() => { handleRejectModel(taskId); }} color='red' />
                                                                        </>
                                                                    }
                                                                    {
                                                                        isAccepted &&
                                                                             <span
                                                                                    data-toggle="tooltip"
                                                                                    title="Assign"
                                                                                    style={{
                                                                                        color: "#000000",
                                                                                        fontSize: "25px",
                                                                                    }}
                                                                                >
                                                                                    <Icon.PersonAdd onClick={() => handleAssignModelOpen(itm)} />
                                                                                </span>  
                                                                        
                                                                    }


                                                        </>
                                                            

                                                    }

                                                </td>
                                            </tr>
                                        )
                                     })
                                    ):(
                                            <tr>
                                            <td colSpan="18" className="text-center">No data available</td>
                                            </tr>
                                        )}
                            </tbody>
                             )
                            :
                            <tr>
                               <td colSpan="18" className="text-center">No Task Available</td>
                            </tr>
                            }
                        </table>
                    </div>
                </div>

                {allAllocatedTasks?.data !== null || allAllocatedTasks?.data?.length > 0 && filterAllTasks?.data?.length > 0 || !showNoDataFound ? (
                <div className='pagination-wrapper p-0'>
                <Pagination
                    currentPage={currentPage}
                    totalPages={filterAllTasks?.data?.length > 0 ? filterAllTasks?.noOfPages : allAllocatedTasks?.noOfPages}
                    onPageChange={handlePageChange}
                    onDocsPerPage={handleDocsPerPage}
                    docsPerPage={docsPerPage}
                    />
                </div>
                ) : (
                ''
                )}
            </Container>
        </div>
    )
}

export default AllocatedTask;