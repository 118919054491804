import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
    AdminAllEmpTaskReview: [],
    AdminSelfReview: [],
    projectData: [],
    clientData: [],
    getAdminFilterDropdown:[],
    loading: 'idle'
}

export const getAllReview = createAsyncThunk("getAllReviewPromise", async (data, { rejectWithValue }) => {
    const response = await fetch(process.env.REACT_APP_BASE_URL + "/reviewTask", {
        method: "POST",
        headers: { "Content-Type": "application/json" }
    })
    try {
        const result = await response.json()
        return result
    } catch (error) {
        return rejectWithValue(error);
    }
}
)

export const getSelfReview = createAsyncThunk("getSelfReviewPromise", async (data, { rejectWithValue }) => {
    const response = await fetch(process.env.REACT_APP_NEW_BASE_URL + '/selfReview', {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data)
    })
    try {
        const result = await response.json()
        return result
    } catch (error) {
        return rejectWithValue(error);
    }
}
)

export const getProjectData = createAsyncThunk("getProjectDataPromise", async (data, { rejectWithValue }) => {
    const response = await fetch(process.env.REACT_APP_BASE_URL + "/project/getAllProjects", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body:JSON.stringify(data)
    })
    try {
        const result = await response.json()
        return result
    } catch (error) {
        return rejectWithValue(error);
    }
}
)

export const getClientData = createAsyncThunk("getClientDataPromise", async (data, { rejectWithValue }) => {
    const response = await fetch(process.env.REACT_APP_BASE_URL + "/client/getAllClient1", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body:JSON.stringify(data)
    })
    try {
        const result = await response.json()
        return result
    } catch (error) {
        return rejectWithValue(error);
    }
}
)

// for filter
export const getAdminFilterOption = createAsyncThunk("getAdminFilterOptionDataPromise", async (data, { rejectWithValue }) => {
    const response = await fetch(process.env.REACT_APP_NEW_BASE_URL + '/adminFilters', {
        method: "GET",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data)
    })
    try {
        const result = await response.json()
        return result
    } catch (error) {
        return rejectWithValue(error);
    }
}
)


const adminSlice = createSlice({
    name: "adminData",
    initialState,
    extraReducers: builder => {
        builder
         // get all employee reviews
            .addCase(getAllReview.pending, (s, a) => {
                s.loading = "pending";
            })
            .addCase(getAllReview.rejected, (s, a) => {
                s.loading = "idle";
            })
            .addCase(getAllReview.fulfilled, (s, a) => {
                s.AdminAllEmpTaskReview = a.payload;
                s.loading = 'success';
            })


            // self reviews
            .addCase(getSelfReview.pending, (s, a) => {
                s.loading = "pending";
            })
            .addCase(getSelfReview.rejected, (s, a) => {
                s.loading = "idle";
            })
            .addCase(getSelfReview.fulfilled, (s, a) => {
                s.AdminSelfReview = a.payload;
                s.loading = 'success';
            })

            // project
            .addCase(getProjectData.pending, (s, a) => {
                s.loading = "pending";
            })
            .addCase(getProjectData.rejected, (s, a) => {
                s.loading = "idle";
            })
            .addCase(getProjectData.fulfilled, (s, a) => {
                s.projectData = a.payload
                s.loading = "success"
            })

            // client
            .addCase(getClientData.pending, (s, a) => {
                s.loading = "pending";
            })
            .addCase(getClientData.rejected, (s, a) => {
                s.loading = "idle";
            })
            .addCase(getClientData.fulfilled, (s, a) => {
                s.clientData = a.payload
                s.loading = "success"
            })

            // filter
            .addCase(getAdminFilterOption.pending, (s, a) => {
                s.loading = "pending";
            })
            .addCase(getAdminFilterOption.rejected, (s, a) => {
                s.loading = "idle";
            })
            .addCase(getAdminFilterOption.fulfilled, (s, a) => {
                s.getAdminFilterDropdown = a.payload
                s.loading = "success"
            })
        }
    })
    
    export const { setProjectDetails ,setClientDetails} = adminSlice.actions;
    export default adminSlice.reducer
