import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const initialState = {
    TLAssignedData: [],
    TlAllEmpTaskReview: [],
    TlSelfReview: [],
    TlManageEmp: [],
    loading: 'idle', // idle, pending, failed
};

export const getTLAssignedData = createAsyncThunk("getTLAssignedDataPromise", async (data, { rejectWithValue }) => {
    const response = await fetch(process.env.REACT_APP_BASE_URL + '/task/perticularTLTask', {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data)
    })
    try {
        const result = await response.json()
        return result
    } catch (error) {
        return rejectWithValue(error);
    }
}
)


// ------------ New changes 18-07-2023 -------------  //

export const getAllReview = createAsyncThunk("getAllReviewPromise", async (data, { rejectWithValue }) => {
    const response = await fetch(process.env.REACT_APP_NEW_BASE_URL + '/reviewTask', {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data)
    })
    try {
        const result = await response.json()
        return result
    } catch (error) {
        return rejectWithValue(error);
    }
}
)

export const getSelfReview = createAsyncThunk("getSelfReviewPromise", async (data, { rejectWithValue }) => {
    const response = await fetch(process.env.REACT_APP_NEW_BASE_URL + '/selfReview', {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data)
    })
    try {
        const result = await response.json()
        return result
    } catch (error) {
        return rejectWithValue(error);
    }
}
)

// ------------  20-07-2023 -------------  //
export const getEmployee = createAsyncThunk("getEmployeePromise", async (data, { rejectWithValue }) => {
    
    const response = await fetch(process.env.REACT_APP_BASE_URL + data.endPoint, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data.payload)
    })
    try {
        const result = await response.json()
        return result
    } catch (error) {
        return rejectWithValue(error)
    }
}
)


const TLSlice = createSlice({
    name: 'tlAssignedData',
    initialState,
    reducers: {
        setLogout(state, action) {
            state.userData = action.payload
        },

    },
    extraReducers: builder => {
        builder
            .addCase(getTLAssignedData.pending, (s, a) => {
                s.loading = 'pending';
            })
            .addCase(getTLAssignedData.rejected, (s, a) => {
                s.loading = 'idle';
                alert('Failed');
            })
            .addCase(getTLAssignedData.fulfilled, (s, a) => {
                s.TLAssignedData = a.payload;
                s.loading = 'success';
            })

            //  ----new chnages 18-07-2023

            // get all employee reviews
            .addCase(getAllReview.pending, (s, a) => {
                s.loading = "pending";
            })
            .addCase(getAllReview.rejected, (s, a) => {
                s.loading = "idle";
            })
            .addCase(getAllReview.fulfilled, (s, a) => {
                s.TlAllEmpTaskReview = a.payload;
                s.loading = 'success';
            })


            // self reviews
            .addCase(getSelfReview.pending, (s, a) => {
                s.loading = "pending";
            })
            .addCase(getSelfReview.rejected, (s, a) => {
                s.loading = "idle";
            })
            .addCase(getSelfReview.fulfilled, (s, a) => {
                s.TlSelfReview = a.payload;
                s.loading = 'success';
            })


            // --------- 20-07-2023 -----------
            .addCase(getEmployee.pending, (s, a) => {
                s.loading = "pending";
            })
            .addCase(getEmployee.rejected, (s, a) => {
                s.loading = "idle";
            })
            .addCase(getEmployee.fulfilled, (s, a) => {
                s.TlManageEmp = a.payload;
                s.loading = 'success';
            });


    },
});


export const {  } = TLSlice.actions;
export default TLSlice.reducer;