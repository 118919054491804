import React, { useRef, useState } from 'react'
import { Button, Card, Col, Container, Form, InputGroup, Row } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import registerImg from '../../../Assets/register.svg';
import Select from 'react-select';
import logo from '../../../Assets/Foxberry.svg';
import useGetAllSkills from '../../Common/ReuseApi/useGetAllSkills';
import useGetDepartments from '../../Common/ReuseApi/useGetDepartments';
import useGetAllDesignation from '../../Common/ReuseApi/useGetAllDesignation';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';


const Register = () => {

  var today = new Date();
  var curHr = today.getHours();

  const { allDepartmentOptionsById } = useGetDepartments()
  const { allDesignationOptionsById } = useGetAllDesignation()

  const [resourceType, setResourceType] = useState([]);
  const [gender, setGender] = useState([]);

  const [department, setDepartment] = useState([])
  const [designation, setDesignation] = useState([])
  const [skills, setSkills] = useState("")

  const payload = {
    department: department?.map((itm) => itm.label)
  }
  const { allSkillOptions } = useGetAllSkills(payload, department);
  const elementToScrollRef = useRef(null);

  const navigate = useNavigate();
  // --- Common STATES ---------------
  const [loader, setLoader] = useState(false);

  // --- INPUT STATES for register -------
  const [register, setRegister] = useState({
    fname: "",
    lname: "",
    empId: "",
    password: "",
    mobile: "",
    workEmail: "",
    empDesgn: "",
    experience: ""
  })



  // --- ERR STATES for register ---------
  const [fNameErr, setFnameErr] = useState(false);
  const [lNameErr, setLNameErr] = useState(false);
  const [empIdErr, setEmpIdErr] = useState(false);
  const [passErr, setPassErr] = useState(false);
  const [mobileErr, setMobileErr] = useState(false);
  const [emailErr, setEmailErr] = useState(false);
  const [empDesgnErr, setEmpDesgnErr] = useState(false);
  const [expErr, setExpErr] = useState(false);
  const [resourceTypeErr, setResourceTypeErr] = useState(false);
  const [genderErr, setGenderErr] = useState(false);
  const [deptErr, setDeptErr] = useState(false);
  const [skillErr, setSkillErr] = useState(false);

  const [showPass, setShowPass] = useState(false);


  // ----- INPUT FUNCTION for register ----------
  const handleRegister = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setRegister({ ...register, [name]: value });
  }

  const handleSkillInput = (options) => {
    const filterData = options.filter((x) => x.label === "All");
    options.length === 0 ? setSkillErr(true) : setSkillErr(false);

    if (filterData?.length > 0) {
      setSkills(allSkillOptions);
    } else {
      setSkills(options);
    }
  }

  const ResourceType = [
    { value: 'superAdmin', label: 'Super Admin' },
    { value: 'admin', label: 'Admin' },
    { value: 'humanResource', label: 'HR' },
    { value: 'manager', label: 'Manager' },
    { value: 'teamlead', label: 'TeamLead' },
    { value: 'employee', label: 'Employee' },
    { value: 'intern', label: 'Intern' },
    { value: 'contractual', label: 'Contractual' }
  ]

  const GenderList = [
    { value: 'male', label: 'Male' },
    { value: 'female', label: 'Female' },
    { value: 'other', label: 'Other' }
  ]

  const validate = () => {

    let FormValid = true;
    let scrrollArray = [];

    if (!register.fname) {
      FormValid = false;
      setFnameErr(true);
      scrrollArray.push('fName');
    }
    else if (!register.lname) {
      FormValid = false;
      setLNameErr(true);
      scrrollArray.push('lName');
    }
    else if (!register.empId) {
      FormValid = false;
      setEmpIdErr(true);
      scrrollArray.push('empId');
    }
    else if (!register.password) {
      FormValid = false;
      setPassErr(true);
      scrrollArray.push('password');
    }
    else if (!register.mobile) {
      FormValid = false;
      setMobileErr(true);
      scrrollArray.push('mobile');
    }
    else if (!register.workEmail) {
      FormValid = false;
      setEmailErr(true);
      scrrollArray.push('workEmail');
    }
    else if (designation.length === 0) {
      FormValid = false;
      setEmpDesgnErr(true);
      scrrollArray.push('designation');
    }
    else if (!register.experience) {
      FormValid = false;
      setExpErr(true);
      scrrollArray.push('experience');
    }
    else if (resourceType.length === 0) {
      FormValid = false;
      setResourceTypeErr(true);
      scrrollArray.push('resourceType');
    }
    else if (gender.length === 0) {
      FormValid = false;
      setGenderErr(true);
      scrrollArray.push('gender');
    }
    else if (department.length === 0) {
      FormValid = false;
      setDeptErr(true);
      scrrollArray.push('department');
    }
    else if (skills.length === 0) {
      FormValid = false;
      setSkillErr(true);
      scrrollArray.push('skills');
    }

    // if (scrrollArray?.length > 0) {
    //   document.getElementById(scrrollArray[0]).scrollIntoView({ behavior: 'smooth' });
    // }
    if (scrrollArray.length > 0) {
      // Instead of using document.getElementById(), use the ref to scroll
      if (elementToScrollRef.current) {
        elementToScrollRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
    return FormValid;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setLoader(true);

      await fetch(process.env.REACT_APP_BASE_URL + '/emp/addemp', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          firstName: register.fname,
          lastName: register.lname,
          empId: register.empId,
          password: register.password,
          contactNo: register.mobile,
          workEmail: register.workEmail,
          // designation: register.empDesgn,
          designation: designation,
          experience: register.experience,
          resourceType: resourceType.value,
          gender: gender.value,
          department: department,
          empSkillName: skills.filter((itm) => itm.label),
        })
      }).then((res) => res.json()).then((response) => {
        if (response.message === 'Employee already exists.') {
          toast.error('Sorry, Employee already exist');
        }
        if (response.message === 'Employee added successfully') {
          toast.success('Registerted Successfully');
          setTimeout(()=>{
            navigate("/");
          },2000)
        }
      }).catch((err) => {
        console.log("Err while register", err);
      })
    }
  }

  return (
    <div className='Register'>
      <ToastContainer />
      <Container className='h-100'>
        <Row className='h-100 justify-content-center align-items-center'>
          <Card>
            <Row>
              <Col md={6} className='left ps-4 ps-md-3'>
                <h6>{curHr < 12 ? <>Good Morning<Icon.BrightnessHighFill className='ms-2 me-1' />,</>
                  : curHr < 18 ? <>Good Afternoon<Icon.BrightnessAltHighFill className='ms-2 me-1' size={20} />,</> :
                    <>Good Evening<Icon.MoonStars className='ms-2 me-1' /> ,</>}</h6>
                <h3>Welcome to Foxberry Technologies</h3>
                <h4>We are more than just a company</h4>
                <p>We believe that the right implementation of Processes, Technology and Operations when monitored through Data analytics can lead to helping our Clients deliver what is best for them or their customers. Our Solutions oriented approach comes from years of working with various clients in Government (State, City, PSUs etc), Finance, Education, Agriculture, Media, Consulting Organizations, Communities, Startups etc whom we have served to achieve their objectives. The approach takes us beyond providing mere Product or Tech Services but giving an end to end solution through our 7 step process.</p>
                <img src={registerImg} alt="Registration" className='d-block mx-auto' />
              </Col>

              <Col md={6} className='pe-4 ps-4 ps-md-2'>
                <div className='text-center py-2 w-50 w-md-25 mx-auto mb-3' style={{ background: 'black', borderRadius: '0px 0px 10px 10px' }}>
                  <img src={logo} alt='Foxberry Technologies' width={100} />
                </div>
                <Form onSubmit={(e) => handleSubmit(e)}>
                  <Row className='gy-4'>
                    <Col md={6}>
                      <Form.Control id='fName' placeholder='* First Name' name='fname' value={register.fname} onChange={(e) => {
                        if (!e.target.value.match(/[0-9+@#$&%!~=^_:(){}\[\]*.|//\-/?<>,;`'""/\\]/) &&
                          e.target.value.trim()
                        ) {
                          handleRegister(e);
                        } else if (e.target.value.length === 0) {
                          handleRegister(e);
                        }
                        register.fname.length >= 0 ? setFnameErr(false) : setFnameErr(true);
                      }} />
                      {fNameErr ? <p className='errMsg'>Please enter valid name</p> : ''}
                    </Col>

                    <Col md={6}>
                      <Form.Control id="lName" placeholder='* Last Name' name='lname' value={register.lname} onChange={(e) => {
                        if (!e.target.value.match(/[0-9+@#$&%!~=^_:(){}\[\]*.|//\-/?<>,;`'""/\\]/) &&
                          e.target.value.trim()
                        ) {
                          handleRegister(e);
                        } else if (e.target.value.length === 0) {
                          handleRegister(e);
                        }
                        register.lname.length >= 0 ? setLNameErr(false) : setLNameErr(true);
                      }} />
                      {lNameErr ? <p className='errMsg'>Please enter valid name</p> : ''}
                    </Col>
                    <Col md={6}>
                      <Form.Control id='empId' placeholder='* Emp Id' name='empId' value={register.empId} onChange={(e) => {
                        handleRegister(e);
                        register.empId.length >= 0 ? setEmpIdErr(false) : setEmpIdErr(true);
                      }} />
                      {empIdErr ? <p className='errMsg'>Please enter valid empId</p> : ''}
                    </Col>

                    <Col md={6}>
                      <InputGroup>
                        <Form.Control id='password' type={showPass ? "text" : "password"} placeholder="Enter Password" name="password"
                          value={register.password} onChange={(e) => {
                            if (!e.target.value.match(
                              /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{10,}$/g) && e.target.value.trim()
                            ) {
                              handleRegister(e)
                            } else if (e.target.value.length === 0) {
                              handleRegister(e)
                            }
                            register.password.length >= 0 ? setPassErr(false) : setPassErr(true);
                          }} />
                        <InputGroup.Text onClick={() => setShowPass(!showPass)}>
                          {showPass ? <Icon.EyeSlash size={20} style={{ cursor: 'pointer' }} onClick={() => setShowPass(!showPass)} /> :
                            <Icon.Eye size={20} style={{ cursor: 'pointer' }} />}
                        </InputGroup.Text>
                      </InputGroup>
                      {passErr ? <p className='errMsg'>Please enter valid password</p> : ''}
                    </Col>

                    <Col md={6} >
                      <Form.Control id='mobile' placeholder='* Mobile Number' name='mobile' value={register.mobile} onChange={(e) => {
                        if (
                          !e.target.value.match(/[A-Za-z+@#$&%!~=*./\s/g/\-/?<>,"/]/) && e.target.value.length <= 10
                        ) {
                          handleRegister(e)
                        }
                        register.mobile.length < 0 ? setMobileErr(true) : setMobileErr(false)
                      }} />
                      {mobileErr ? <p className='errMsg'>Please enter valid mobile no.</p> : ''}
                    </Col>
                    <Col md={6} >
                      <Form.Control id='workEmail' placeholder='* Work Email' name='workEmail' value={register.workEmail}
                        onChange={(e) => {
                          handleRegister(e);
                          register.workEmail.length >= 0 ? setEmailErr(false) : setEmailErr(true)
                        }} />
                      {emailErr ? <p className='errMsg'>Please enter valid email</p> : ''}
                    </Col>
                    <Col md={6} >
                      {/* <Form.Control id='empDesgn' placeholder='* Designation' name='empDesgn' value={register.empDesgn}
                        onChange={(e) => {
                          handleRegister(e);
                          register.empDesgn.length >= 0 ? setEmpDesgnErr(false) : setEmpDesgnErr(true)
                        }} /> */}
                      <Select id='designation' isMulti={false} options={allDesignationOptionsById} placeholder="Select your Designation"
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedOption) => {
                          setDesignation(selectedOption.label);
                          selectedOption.length === 0 ? setEmpDesgnErr(true) : setEmpDesgnErr(false)
                        }}
                        isSearchable={true} />
                      {empDesgnErr ? <p className='errMsg'>Please enter valid designation</p> : ''}
                    </Col>
                    <Col md={6} >
                      <Form.Control id='experience' placeholder='* Experience' name='experience' value={register.experience}
                        onChange={(e) => {
                          if (!e.target.value.match(/[A-Za-z+@#$&%!~=*/\s/g/\-/?<>,"/]/)) {
                            handleRegister(e);
                          }
                          register.experience.length >= 0 ? setExpErr(false) : setExpErr(true);
                        }} />

                      {expErr ? <p className='errMsg'>Please enter valid experience</p> : ''}
                    </Col>
                    <Col md={6}>
                      <Select id='resourceType' isMulti={false} options={ResourceType} name='resourceType'
                        className="basic-multi-select" classNamePrefix="select" placeholder="Select Resource Type"
                        onChange={(selectedOption) => {
                          setResourceType(selectedOption);
                          selectedOption.length === 0 ? setResourceTypeErr(true) : setResourceTypeErr(false)
                        }} isSearchable={true} />

                      {resourceTypeErr ? <p className='errMsg'>Please select valid resource type</p> : ''}
                    </Col>

                    <Col md={6}>
                      <Select id='gender' isMulti={false} options={GenderList} className="basic-multi-select"
                        classNamePrefix="select" placeholder="Select your gender"
                        onChange={(selectedOption) => {
                          setGender(selectedOption);

                          selectedOption.length === 0 ? setGenderErr(true) : setGenderErr(false)
                        }} isSearchable={true} />
                      {genderErr ? <p className='errMsg'>Please select your gender</p> : ''}
                    </Col>

                    <Col md={12}>
                      <Select id='department' isMulti={true} options={allDepartmentOptionsById} placeholder="Select your department"
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedOption) => {
                          setDepartment(selectedOption);
                          selectedOption.length === 0 ? setDeptErr(true) : setDeptErr(false)
                        }}
                        isSearchable={true} />

                      {deptErr ? <p className='errMsg'>Please select any department</p> : ''}
                    </Col>

                    <Col md={12}>
                      <Select id='skills' isMulti={true} options={allSkillOptions} placeholder="Select your skills"
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={handleSkillInput}
                        isSearchable={true} />

                      {skillErr ? <p className='errMsg'>Please select any skills</p> : ''}
                    </Col>

                    <Button className='loginBtn mx-auto w-auto' type='submit'>
                      <Icon.Send className='me-2' size={16} />Send for Approval</Button>

                  </Row>
                </Form>

                <Row className='d-flex flex-row justify-content-between mb-3'>

                  <Col xs={6} md={4}>
                    <p className="mt-3 mb-1 text-dark" style={{ fontSize: '12px' }}> Don't have an Emp ID ?</p>
                    <Button className="secondaryBtn w-auto" style={{ fontSize: '14px' }}
                      onClick={() => navigate("/register")} disabled={true}>
                      Contact to HR
                    </Button>
                  </Col>

                  <Col xs={6} md={4}>
                    <p className="mt-3 mb-1 text-dark" style={{ fontSize: '12px' }}>Already have an account ?</p>
                    <Button className="secondaryBtn w-auto ms-auto" style={{ fontSize: '14px' }}
                      onClick={() => navigate("/")} disabled={loader}>
                      Login
                    </Button>
                  </Col>

                </Row>
              </Col>
            </Row>

          </Card>

        </Row>
      </Container>
    </div>
  )
}

export default Register;