import axios from 'axios';
import React, { useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const DeleteToDoListModal = ({ show, close, todoId }) => {

  // toast notiofication for delete
  const DeleteTaskToast = () => {
    toast.success("Deleted Successfully", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 1000
    });
  };

  const deleteToDoListData = async() => {
     const payload= {
        "toDoListId":todoId
     }
     axios.post(process.env.REACT_APP_BASE_URL + "/toDoList/deleteToDoList",payload)
     .then((res) => {
      DeleteTaskToast();
      close()
     }).catch(() => {
      console.log("Error:,err")
     })
  }
  
  return (
    <>
      <ToastContainer />
      <Modal show={show} onHide={close} centered backdrop="static" keyboard={false}>
        <Modal.Header closeButton style={{ color: "black" }}>
          <Modal.Title><h5>Delete Note</h5></Modal.Title>
        </Modal.Header>
        <Modal.Body className='py-4'>
          <p style={{ color: "black", fontSize: "15px", margin: '0px' }}>
            Are you sure you want to delete this Records?👎
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button className='secondaryBtn' onClick={close}>Cancel</Button>
          <Button variant="danger" onClick={() => deleteToDoListData()}>Delete</Button>
        </Modal.Footer>
      </Modal >
    </>

  )
}

export default DeleteToDoListModal