import React, { useEffect, useState } from 'react'
import { Badge, Button, Col, Container, Row, Table } from 'react-bootstrap'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation} from 'react-router-dom';
import { getAllTasks } from './AllTaskSlice';
import SubTaskModel from './Model/SubTaskModel';
import Pagination from './Pagination';
import * as Icon from "react-bootstrap-icons";
import DisplayHoursAndMinutes from './Convertor/DisplayHoursAndMinutes';

const EmpDetails = () => {

    const dispatch = useDispatch();
    const location = useLocation();

    const loginUserData = useSelector((state) => state.loginData.userData)

    const showAllTaskByEmpId = useSelector(state => state.AllTasks.AllTask)

    const [show, setShow] = useState(false)
    const [taskId, setTaskId] = useState("")

    const [docsPerPage, setDocsPerPage] = useState(5);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState();

    useEffect(() => {
        const payload = {
            "empId": location?.state?.data?.empId,
            "taskPriority": "",
            "taskName": "",
            "departmentName": "",
            "departments": [],
            "projectName": "",
            "categoryOfName": "",
            "resource_id": "",
            "proposedStartDate": "",
            "proposedEndDate": "",
            "status": "",
            "resourceType": location?.state?.data?.resourceType,
            "assignBy_Name": "",
            "documentsPerPage": docsPerPage,
            "page": totalPages
        }
        dispatch(getAllTasks(payload));
    }, [])

    const handleShowSubTaskModal = (id) => {
        setTaskId(id);
        setShow(true);
    }

    const handleSubTaskModalClose = () => {
        setShow(false);
        setTaskId("");
    }

    // function for pagination
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);

    };

    const handleDocsPerPage = (docsPageProp) => {
        setCurrentPage(1)
        setDocsPerPage(docsPageProp);
    }

    return (
        <div className='MngAllTask outletPadding'>
            <ToastContainer/>
            <Container>
                <Row>
                    <Col md={4}>
                       <Button className='primaryBtn me-3' 
                       onClick={() => window.history.back()}
                       >
                            <Icon.ArrowLeftShort className='me-1' size={18} />
                            Back
                        </Button>
                    </Col>
                </Row>

                {/* for employee personal details */}
                <Row className='mt-2'>
                    <Col xs={12} className="ViewTaskHeading">
                        <h4>Employee Details</h4>
                    </Col>
                </Row>
                <Table responsive="sm" bordered className="mt-2">
                    <tbody>
                        <tr>
                            <th colSpan={2}>Emp ID</th>
                            <td>{location.state.data?.empId}</td>
                            <th colSpan={4}>Employee Name</th>
                            <td>{`${location?.state?.data?.firstName} ${location?.state?.data?.lastName}`}</td>
                        </tr>
                        <tr>
                            <th colSpan={2}>Work Email</th>
                            <td >{location?.state?.data?.workEmail}</td>
                            <th colSpan={4}>Contact No</th>
                            <td >{location?.state?.data?.contactNo}</td>
                        </tr>
                        <tr>

                        </tr>
                        <tr>
                            <th colSpan={2}>Designation</th>
                            <td>{location?.state?.data?.designation}</td>
                            <th colSpan={4}>Department</th>
                            <td>
                                {" "}
                                {location?.state?.data?.department?.map((item, i) => {
                                    return (
                                        <span>
                                            {item.label}
                                            {location?.state?.data?.department?.length - 1 !== i && ","}
                                        </span>
                                    );
                                })}
                            </td>
                        </tr>
                        <tr>
                            <th colSpan={2}>Skill</th>
                            <td>
                                {" "}
                                {location?.state?.data?.empSkillName?.map((item, i) => {
                                    return (
                                        <span style={{ textTransform: "capitalize" }}>
                                            {/* {item.label} {","} */}
                                            {item.label}
                                            {location?.state?.data?.empSkillName?.length - 1 !== i && ","}
                                        </span>
                                    );
                                })}
                            </td>

                            <th colSpan={4}>Resource Type</th>
                            <td style={{ textTransform: "capitalize" }}>
                                {location?.state?.data?.resourceType}
                            </td>
                        </tr>
                        <tr>
                            <th colSpan={2}>Experience</th>
                            <td>{location?.state?.data?.experience}</td>
                            <th colSpan={4}>Approval Status</th>
                            <td>
                                {location?.state?.data?.isRejected === true
                                    ? "Rejected"
                                    : location?.state?.data?.isApproved === true
                                        ? " Approved"
                                        : "Not Update"}
                            </td>
                        </tr>

                    </tbody>
                </Table>

                {/* for employee main task details */}
                <SubTaskModel show={show} close={handleSubTaskModalClose} taskId={taskId} />
                {
                    loginUserData.resourceType !== "humanResource" || loginUserData.resourceType !== "admin"&&
                    <Row className='mt-2'>
                        <Col xs={12}  className='ViewTaskHeading'>
                            <h4>Task history</h4>
                        </Col>
                        <div className='outer-wrapper noselect mx-auto'>
                            <div className='table-wrapper'>
                                <table hover>
                                    <thead>
                                        <tr>
                                            <th>Sr No</th>
                                            <th>Task Name</th>
                                            <th>Category</th>
                                            <th>Project</th>
                                            <th className='assignwidth'>Assign By</th>
                                            <th>Assign To</th>
                                            <th>Assign Date</th>
                                            <th>End Date</th>
                                            <th>Estiate Hours</th>
                                            <th>Actual Hours</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {showAllTaskByEmpId && showAllTaskByEmpId?.data && showAllTaskByEmpId?.data?.length > 0 ? (
                                            showAllTaskByEmpId?.data?.map((row, index) => (
                                                <tr style={{ textAlign: "left" }} key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{row.taskName}</td>
                                                    <td>{row.categoryOfName}</td>
                                                    <td>{row.projectName}</td>
                                                    <td>{row.assignBy_Name}</td>
                                                    <td>{row.resourceName}</td>
                                                    <td>{new Date(row.proposedStartDate).toLocaleDateString("en-GB")}</td>
                                                    <td>{new Date(row.proposedEndDate).toLocaleDateString("en-GB")}</td>
                                                    <td><DisplayHoursAndMinutes value={row?.initialEstimateHr}/></td>
                                                    <td><DisplayHoursAndMinutes value={row?.actualHours}/></td>
                                                    {/* <td>{row.initialEstimateHr ? row.initialEstimateHr.toFixed(2) + ' Hr' : '0 Hr'}</td>
                                                    <td>{row.actualHours ? row.actualHours.toFixed(2) + ' Hr' : '0 Hr'}</td> */}
                                                    <td>
                                                        {row?.status === "pending" ? <Badge bg="warning p-1" text="dark">Pending</Badge> :
                                                            row?.status === "completed" ? <Badge bg="success p-1">Completed</Badge> : ''}
                                                    </td>
                                                    <td className="">
                                                        <div data-toggle="modal" style={{ color: "#292929" }}>
                                                            <Button className="primaryBtn"
                                                                onClick={() => { handleShowSubTaskModal(row.taskId) }}>
                                                                <Icon.Eye size = {20} className='mx-1'/> SubTask
                                                            </Button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td className="text-center" colSpan={14}>
                                                   No Data Found
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>

                                </table>
                            </div>
                        </div>

                        <Pagination currentPage={currentPage} totalPages={showAllTaskByEmpId?.noOfPages}
                            onPageChange={handlePageChange} onDocsPerPage={handleDocsPerPage} />
                    </Row>
                }
            </Container>
        </div >
    )
}

export default EmpDetails

