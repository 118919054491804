import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Container, Row, Table, Button, Form, Col } from 'react-bootstrap'
import { ToastContainer } from 'react-toastify'
import * as Icon from "react-bootstrap-icons";
import { useDispatch, useSelector } from 'react-redux';
import { getEmployeeData } from './HrSlice';
import { useNavigate } from 'react-router-dom';
import "react-toastify/dist/ReactToastify.css";
import FilterEmpDetailsModel from './Model/FilterEmpDetailsModel';
import DeleteEmpModel from './Model/DeleteEmpModel';
import Pagination from '../Common/Pagination';
import EditEmployeeDetailsModel from './Model/EditEmployeeDetails';
import AssignToTlModel from './Model/AssignToTlModel';
import AssignTlToDepartmentModel from './Model/AssignTlToDepartmentModel';

const Employees = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // Employee Details
  const employeeData = useSelector(state => state?.employeeDetails?.employeeData);
  const loginUserData = useSelector((state) => state?.loginData?.userData);

  const [show, setShow] = useState(false);
  const [showFilter, setShowFilter] = useState(false)
  const[showassigntotl,setAssignToTl] = useState(false)
  const[showassigntltodept,setAssignTLToDept] = useState(false)
  const [taskId, setTaskId] = useState("")
  const [rejected, setRejected] = useState([])
  const [approve, setApprove] = useState([])
  const [item, setItem] = useState();

  // state for pagination
  const [docsPerPage, setDocsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  // for edit task state
    const [showEdit, setShowEdit] = useState(false)

  // for delete model
  const handlerCloseModel = () => { setShow(false); }
  const handlerCloseFilterModel = () => setShowFilter(false)
  const handleCloseAssignToTlModel = () => setAssignToTl(false)
  const handleCloseAssignTLToDeptModel = () => setAssignTLToDept(false)


  const getManageEmpData = () => {
    const endpoint = "/emp/getallemp1"
    const payload = {
      empId: "",
      name: "",
      label: "",
      resourceType: "",
      experience: "",
      isApproved: "",
      documentsPerPage: docsPerPage,
      page: currentPage
    }
    dispatch(
      getEmployeeData({ endpoint, payload: payload }))
  }
  useEffect(() => {
    getManageEmpData()
  }, [approve, rejected, currentPage, docsPerPage,]);


  const handleReject = async (values) => {
    let payload = {
      empId: values.empId,
      isRejected: "true",
    };
    await axios
      .put(process.env.REACT_APP_BASE_URL + "/emp/updateEmployee", payload)
      .then((res) => {
        setRejected(res.data.data)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleApprove = async (values) => {
    let payload = {
      empId: values.empId,
      // isApproved: "true",
    };
    await axios
      .post(process.env.REACT_APP_BASE_URL + "/emp/approveemployee", payload)
      .then((res) => {
        setApprove(res.data.data)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // function for pagination
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);

  };
  const handleDocsPerPage = (docsPageProp) => {
    setCurrentPage(1);
    setDocsPerPage(docsPageProp);
  }


  // for edit model
   const handleShowEditModal = (id) => {
        setItem(id)
        setShowEdit(id)

    }
    const handleEditModalClose = () => {
        setShowEdit(false)
        setItem("");
        getManageEmpData()
    }

  return (
    <div className="outletPadding" data-aos="fade-up">
      <Container>
        <ToastContainer />
        <DeleteEmpModel show={show} close={handlerCloseModel} id={taskId} reCallData={getManageEmpData} />
        <EditEmployeeDetailsModel show={showEdit} close={handleEditModalClose} taskId={taskId} item={item} userData={loginUserData} />
        <FilterEmpDetailsModel show={showFilter} close={handlerCloseFilterModel} />
        <AssignToTlModel show = {showassigntotl} close = {handleCloseAssignToTlModel} />
        <AssignTlToDepartmentModel close = {handleCloseAssignTLToDeptModel} show = {showassigntltodept}/>
        <Row className='m-3 d-flex flex-column flex-md-row justify-content-between align-items-center'>
          <Col sm={12} md={10} className="d-flex justify-content-start justify-md-content-start align-items-center mb-3 mb-md-0">
            <Button
              className="primaryBtn btn-16 px-3 me-3"
              onClick={() => { setAssignToTl(true) }}
            >
              <Icon.PersonCheck className='me-2' size={17} />Assign Employee To TL
            </Button>

            <Button
              className="primaryBtn"
              onClick={() => { setAssignTLToDept(true) }}
            >
              <Icon.PersonCheck className='me-2' size={17} />Assign TL To Dept
            </Button>
          </Col>
          <Col sm={12} md={2} className="d-flex justify-content-end justify-sm-content-start mb-3 mb-md-0">
            <Button
              className="primaryBtn btn-16 px-3 d-flex align-items-center"
              onClick={() => { setShowFilter(true) }}
            >
              <Icon.Funnel className='me-2' size={17} />Filter
            </Button>
          </Col>
        </Row>

        <div className='outer-wrapper mx-auto'>
          <div className='table-wrapper'>
            <table>
              <thead>
                <tr className='tbl-header'>
                  <th>Sr. No.</th>
                  <th>Employee ID</th>
                  <th className='assignwidth'>Name</th>
                  <th>Department</th>
                  <th>Designation</th>
                  <th>Resource Type</th>
                  <th>Experience</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>

              <tbody>
                {
                  employeeData?.data !== null &&
                    employeeData?.data !== undefined &&
                    employeeData?.data?.length > 0
                    ? employeeData?.data?.map((row, index) => {
                      return (
                        <tr style={{ textAlign: "left" }}>
                          <td>{currentPage !== 1 ? (<>{index + 1 + docsPerPage * (currentPage - 1)}</>
                          ) : (<>{index + 1}</>)}</td>
                         
                          <td>{row?.empId}</td>
                          <td>{`${row?.firstName} ${row?.lastName}`}</td>
                          {/* <td>{row?.department[0]?.label}</td> */}
                           <td>{row?.department[0]?.label}</td>
                          <td>{row?.designation}</td>
                          <td>{row?.resourceType}</td>
                          <td>{row?.experience}</td>
                          <td>
                            <div className="approvalstatus ">
                              <div style={{ color: "#DFF2FB" }}>
                                {row?.isApproved === true ? (
                                  <button
                                    className="approval-btn "
                                    disabled
                                    style={{ backgroundColor: "green" }}>
                                    <div className="icon-approval" style={{ fontSize: '15px' }}>
                                      <span className="check-arrow">
                                        <Icon.Check
                                          style={{
                                            fontSize: "20px",
                                            color: "#59AC59",
                                            fontWeight: "bold",
                                          }}
                                        />
                                      </span>
                                      Approved
                                    </div>
                                  </button>
                                ) : row.isRejected === true ? (
                                  ""
                                ) : (
                                  <Button
                                    className="approval-btn"
                                    onClick={() => handleApprove(row)}
                                  >
                                    Approve
                                  </Button>
                                )}
                              </div>

                              {row?.isApproved === true ? (
                                ""
                              ) : row?.isRejected === true ? (
                                <div style={{ color: "#FFDFE1" }}>
                                  <button
                                    disabled
                                    className="reject-btn"
                                    style={{ backgroundColor: "red" }}
                                  >
                                    <div className="icon-approval" style={{ fontSize: '15px' }}>
                                      <span className="check-arrow">
                                        <Icon.X
                                          style={{
                                            fontSize: "20px",
                                            color: "#F85253",
                                            fontWeight: "bold",
                                          }}
                                        />
                                      </span>
                                      Rejected
                                    </div>
                                  </button>
                                </div>
                              ) : (
                                <div
                                  data-toggle="modal"
                                  style={{ color: "#FFDFE1" }}
                                >
                                  <Button
                                    className="reject-btn"
                                    onClick={() => handleReject(row)}
                                  >
                                    Reject
                                  </Button>
                                </div>
                              )}
                            </div>
                          </td>

                          <td>
                            <span
                              data-toggle="tooltip"
                              title="view"
                              style={{
                                color: "#000000",
                                fontSize: "20px",
                              }}>
                              <Icon.Eye className='me-2'
                                onClick={() => {
                                  navigate('employee-details', {
                                    state: { data: row }
                                  })
                                }}
                              />
                            </span>
                            <span
                              data-toggle="tooltip"
                              title="edit"
                            >
                            <Icon.PencilSquare className='me-2' size={20} 
                            onClick={() => { handleShowEditModal(row); }} 
                            />
                          </span>
                                                                
                            <span
                              data-toggle="tooltip"
                              title="delete"
                              style={{
                                color: "#000000",
                                fontSize: "20px",
                              }}>
                              <Icon.Trash className='me-2' onClick={() => { setTaskId(row.empId); setShow(true) }} />
                            </span>
                          </td>
                        </tr>
                      );
                    })
                    : <tr>
                      <td colSpan="13" className="text-center">No data found</td>
                    </tr>}
              </tbody>


            </table>
          </div>
        </div>
        <div className="pagination-wrapper p-0">
          <Pagination currentPage={currentPage} totalPages={employeeData?.noOfPages}
            onPageChange={handlePageChange} onDocsPerPage={handleDocsPerPage}docsPerPage={docsPerPage}/>
        </div>
      </Container>
    </div>
  )
}

export default Employees
