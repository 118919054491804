import axios from 'axios';
import React, {useEffect, useState } from 'react'
import { InputGroup, Modal, Button, Form, Col, Row} from "react-bootstrap";
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CreateToDoListModal = ({ show, close }) => {

  // login userdata
   const loginUserData = useSelector((state) => state?.loginData?.userData);

  // usestate
  const [tasktitle, setTaskTitle] = useState("")
  const [taskdesc, setTaskDesc] = useState("")

  const handlerAddToDoData = async() => {
    const payload = {
      "title": tasktitle,
      "description": taskdesc,
      "empId": loginUserData?.empId
    }
    await axios.post(process.env.REACT_APP_BASE_URL + "/toDoList/addToDoList" , payload)
    .then((res) => {
       if(res.data.message === "Added Successfully"){
          toast.success("Added Successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setTaskTitle("")
        setTaskDesc("")
       }
          close();
    })
    .catch((err) => {
      console.log("Error:",err)
    })
  }
  

  return (
    <>
     <ToastContainer/>
        <Modal show={show} onHide={close} size="md" centered backdrop='static' keyboard={false}>
        <Modal.Header closeButton className="mngmodelheader">
            <Modal.Title><h5>Create List</h5></Modal.Title>
        </Modal.Header>
        <Modal.Body size="lg">
            <Row className='mb-3'>
                <Col sm={12} md={12}>
                    <InputGroup>
                        <Form.Control
                          placeholder="Title"
                          className="input-field project-inputgroup"
                          name = "title"
                          value = {tasktitle}
                          onChange={(e) => setTaskTitle(e.target.value)}
                        />
                    </InputGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={12} md={12}>
                    <InputGroup>
                        <Form.Control
                          as="textarea"
                          rows={5}
                          placeholder="Write the description of task..."
                          className="input-field project-inputgroup"
                          name = "description"
                          value = {taskdesc}
                          onChange={(e) => setTaskDesc(e.target.value)}
                        />
                    </InputGroup>
                </Col>
            </Row>
        </Modal.Body>

        <Modal.Footer className="text-center mt-4">
            <Button className="primaryBtn ">Assign</Button>
            <Button className="activeBtn" onClick={() => { handlerAddToDoData() }}>Save</Button>
        </Modal.Footer>
        </Modal>
    </>
    
  )
}

export default CreateToDoListModal