import axios from 'axios';
import React from 'react'
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';

const DeleteEmpModel = ({ show, close, id, reCallData }) => {

  const DeleteUserToast = () => {
    toast.success("Deleted User Successfully", {
      position: toast.POSITION.TOP_CENTER,
    });
    reCallData()
  };

  const deleteUserData = async (e) => {
    e.preventDefault();
    let data = {
      empId: id,
    };
    await axios
      .delete(process.env.REACT_APP_BASE_URL + "/emp/deleteEmployees", { data })
      .then((res) => {
        close()
        DeleteUserToast();
      })
      .catch((err) => console.log("Error", err));
  };

  return (
    <Modal show={show} onHide={close} centered>
      <Modal.Header closeButton style={{ color: "black" }}>
        <Modal.Title>Delete Employee</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p style={{ color: "black", fontSize: "15px" }}>
          Are you sure you want to delete these Records?👎
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={close}>
          Cancel
        </Button>
        <Button variant="danger" onClick={deleteUserData}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default DeleteEmpModel
