import { Modal, Button, Form, Col, Row} from "react-bootstrap";
import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { getClientData, getAdminFilterOption } from "../AdminSlice";

export const FilterClientModel = ({show, close}) => {

  const dispatch = useDispatch()
  const allAdminDropdownOption = useSelector(state => state?.admin?.getAdminFilterDropdown)
  const StatusOptions = [{ value: 'inactive', label: 'In Active' }, { value: 'active', label: 'Active' }];

  const [clientName, setClientName] = useState("")
  const [company, setCompany] = useState("")
  const [status,setStatus] = useState("")

  const getAllClientData = () =>{
    const payload =  {
      "clientName": clientName.value ?? "",
      "contactNo": "",
      "email": "",
      "company": company.value ?? "",
      "address": "",
      "details": "",
      "status": status.value ?? "",
      "documentsPerPage": 10,
      "page": 1
    }
    dispatch(getClientData(payload)
    )
  }

  // for filter function call
   useEffect(() => {
        dispatch(getAdminFilterOption())
    }, [])


    const handleFilterSubmit = () =>{
      getAllClientData()
      close()
    }

    const clearFilter = () =>{
      setClientName("")
      setCompany("")
      setStatus("")
    }

  return (
    <>
    <ToastContainer/>
    <Modal show={show} onHide={close} size="lg" centered backdrop='static' keyboard={false}>
    <Modal.Header
      closeButton
      style={{
        color: "black",
        width: "100%",
        height: "50px",
        backgroundColor: "#F9D65F",
      }}
    >
      <Modal.Title style={{ fontWeight: "bold", fontFamily: "Poppins" }}>
        Filter
      </Modal.Title>
    </Modal.Header>
    <Modal.Body size="lg">

        <Row className='mb-4'>
          <Col sm={12} md={6}>
            <Form.Group controlId="formBasicId">
              <Form.Label className="mt-3 fw-bold">
                Client Name
              </Form.Label>
               <Select
                isMulti={false}
                options={
                allAdminDropdownOption !== null &&
                allAdminDropdownOption !== undefined &&
                allAdminDropdownOption?.data?.clients
              }
              value={clientName}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder="Select Client Name"
              onChange={(option) => { setClientName(option) }}
              isSearchable={true}
            />
            </Form.Group>
          </Col>

          <Col sm={12} md={6}>
            <Form.Group controlId="">
              <Form.Label className="mt-3 fw-bold">
                Company Name
              </Form.Label>
              <Select
                isMulti={false}
                options={
                allAdminDropdownOption !== null &&
                allAdminDropdownOption !== undefined &&
                allAdminDropdownOption?.data?.companies
              }
              value={company}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder="Select Company Name"
              onChange={(option) => { setClientName(option) }}
              isSearchable={true}
            />
            </Form.Group>
          </Col>
        </Row>

        <Row className='mb-4'>
          <Col sm={12} md={6}>
            <Form.Group className="">
              <Form.Label className="mt-3 fw-bold">
                 Status
              </Form.Label>
              <Select
                isMulti={false}
                isSearchable={true}
                name='status'
                placeholder = "Select Status"
                value={status}
                options={StatusOptions}
                onChange={(option) => {
                  setStatus(option);
                }}
              />
            </Form.Group>
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <Button className="secondaryBtn" type='clear' onClick={clearFilter}>Reset</Button>
        <Button className="primaryBtn" onClick={() => handleFilterSubmit() }>Submit </Button>
      </Modal.Footer>
    </Modal>
    </>
  )
}
