import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import DashCards from '../Common/DashCards';
import MultibarChart from '../Charts/MultibarCharts';
import VerticalChart from '../Charts/VerticalChart';
import HorizontalChart from '../Charts/HorizontalChart';
import MultiLineChart from '../Charts/MultiLineChart';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

const EmpDash = () => {

  const userState = useSelector(state => state.loginData.userData);
  const roleOfUser = ["employee", "intern", "contractual"]
  // ---------------------- STATES & API TOGETHER --------------------------------
  const [empLeads, setEmpLeads] = useState([]);
  const [empValue, setEmpValue] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    totalCardsApis();
    getProjectWiseChart();
    handlerCategoryWiseChar();
    handlerSlippageWiseChar();
    handlerIDCWiseChar();
    handlerQWDWiseChar();
  }, [empValue, startDate, endDate])

  // ----- Lead/Manager List Fetching ----------
  useEffect(() => {
    getResoucewiseTL();
  }, [])

  const getResoucewiseTL = async () => {
    await fetch(process.env.REACT_APP_BASE_URL + "/emp/getEmployeeLeadOrManager", {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ empId: userState?.empId })
    })
      .then((res) => res.json()).then((response) => {
        setEmpLeads(response?.data.lead_name);
      })
      .catch((err) => {
        console.log("Error while getting leads =>", err);
      });
  };

  // ----- Card State & API-------
  const [cardApiRes, setCardApiRes] = useState({});
  const totalCardsApis = async () => {
    const payload = {
      empId: userState?.empId,
      fromDate: startDate ? startDate : "",
      toDate: endDate ? endDate : "",
      lead: empValue ? empValue : ""
    };

    await axios.post(process.env.REACT_APP_BASE_URL + "/empDashboard/empDashboardApi", payload)
      .then((res) => {
        setCardApiRes(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // --------- PROJECTS Chart States & Chart API ----------------
  const [projectLabel, setProjectLabel] = useState();
  const [projectData, setProjectData] = useState();

  const getProjectWiseChart = async () => {
    const data = {
      empId: userState?.empId,
      fromDate: startDate ? startDate : "",
      toDate: endDate ? endDate : "",
      lead: empValue ? empValue : ""
    };
    await axios.post(process.env.REACT_APP_BASE_URL + "/graph/projectWiseChartAndTime", data)
      .then((res) => {
        setProjectLabel(res?.data?.data);

        let projectsArrayData = [];

        projectsArrayData.push({
          type: "bar",
          label: "Actual Hours",
          fill: true,
          data: res.data.data,
          backgroundColor: "#4E79A7",
        });

        projectsArrayData.push({
          type: "bar",
          label: "Estimate Hours",
          fill: true,
          data: res.data.data,
          backgroundColor: "#F28E2B",
        });

        setProjectData(projectsArrayData);
      })
      .catch((err) => {
        console.log("Error:", err);
      });
  };

  // -------- CATEGORIES States & API --------------
  const [categoryLabel, setCategoryLabel] = useState();
  const [categoryData, setCategoryData] = useState();

  const handlerCategoryWiseChar = async () => {
    const data = {
      empId: userState?.empId,
      fromDate: startDate ? startDate : "",
      toDate: endDate ? endDate : "",
      lead: empValue ? empValue : ""
    };
    await axios.post(process.env.REACT_APP_BASE_URL + "/graph/workCategoryWiseTimeSpend", data)
      .then((res) => {
        setCategoryLabel(res?.data?.data);

        let categoryArrayData = [];

        categoryArrayData.push({
          // type: "bar",
          label: "Category",
          fill: true,
          data: res?.data?.data,
          backgroundColor: "#76B7B2",
        });
        setCategoryData(categoryArrayData);
      })
      .catch((err) => {
        console.log("Error:", err);
      });
  };


  // -------- Project Wise Slippage------------
  const [slippageLabel, setSlippageLabel] = useState();
  const [slippageData, setSlippageData] = useState();

  const handlerSlippageWiseChar = async () => {
    const data = {
      empId: userState?.empId,
      fromDate: startDate ? startDate : "",
      toDate: endDate ? endDate : "",
      lead: empValue ? empValue : ""
    };
    await axios
      .post(process.env.REACT_APP_BASE_URL + "/graph/projectWiseSlippage", data)
      .then((res) => {

        setSlippageLabel(res.data.data);

        let slippageArrayData = [];

        slippageArrayData.push({
          // type: "bar",
          label: "Slippage",
          fill: true,
          data: res.data.data,
          backgroundColor: "#F28E2B",
        });

        setSlippageData(slippageArrayData);
      })
      .catch((err) => {
        console.log("Error:", err);
      });
  };


  // ------- IDC States & API -----------------
  const [idcLabel, setIDCLabel] = useState();
  const [idcData, setIDCData] = useState();

  const handlerIDCWiseChar = async () => {
    const data = {
      empId: userState?.empId,
      fromDate: startDate ? startDate : "",
      toDate: endDate ? endDate : "",
      lead: empValue ? empValue : ""
    };
    await axios.post(process.env.REACT_APP_BASE_URL + "/graph/projectWiseIDC", data)
      .then((res) => {
        setIDCLabel(res.data.data);

        let idcArrayData = [];

        idcArrayData.push({
          // type: "bar",
          label: "IDC",
          fill: true,
          data: res.data.data,
          backgroundColor: "#D4A6C8",
        });

        setIDCData(idcArrayData);
      })
      .catch((err) => {
        console.log("Error:", err);
      });
  };

  // ----- QWD State & API -----
  const [qwdLabel, setQWDLabel] = useState();
  const [qwdData, setQWDData] = useState();

  const handlerQWDWiseChar = async () => {
    const data = {
      empId: userState?.empId,
      fromDate: startDate ? startDate : "",
      toDate: endDate ? endDate : "",
      lead: empValue ? empValue : ""
    };
    await axios.post(process.env.REACT_APP_BASE_URL + "/graph/projectWiseQWD", data)
      .then((res) => {
        setQWDLabel(res.data.data);

        let qwdArrayData = [];

        qwdArrayData.push({
          // type: "bar",
          label: "QWD",
          fill: true,
          data: res.data.data,
          backgroundColor: "#59A14F",
        });

        setQWDData(qwdArrayData);
      })
      .catch((err) => {
        console.log("Error:", err);
      });
  };

  // for reset all data in dropdown
  const handleronChange = (selectedValue) => {
    if (selectedValue === "All") {
      setEmpValue("");
    } else {
      setEmpValue(selectedValue);
    }
  };

  return (
    <div className='EmpDash' data-aos='fade-up'>
      <Container>
        {roleOfUser.includes(userState.resourceType) ?
          <Row className='d-flex align-items-center justify-content-between w-100 mt-5'>
            <Col md={2} className='mb-md-0'>
              <Form.Group>
                <Form.Label> Lead/Manager </Form.Label>
                <Form.Select className="w-100" onClick={(e) => handleronChange(e.target.value)}>
                  <option value="All">All</option>
                  {empLeads.length > 0 && empLeads !== undefined && empLeads !== null &&
                    empLeads.map((row) => {
                      return (
                        <option value={row}>{row}</option>
                      )
                    })}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={4} className='d-flex justify-content-end mt-3 mt-mb-0'>
              <Row style={{ width: '100%' }}>
                <Col md={6} className='ps-0 mb-2 mb-md-0 '>
                  <Form.Group className='w-100 THIS'>
                    <Form.Label> From </Form.Label>
                    <Form.Control className='w-100' type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                  </Form.Group>
                </Col>

                <Col md={6} className='ps-0'>
                  <Form.Group>
                    <Form.Label> To </Form.Label>
                    <Form.Control type="date" value={endDate} min={startDate} onChange={(e) => setEndDate(e.target.value)} />
                  </Form.Group>
                </Col>
              </Row>
            </Col>
          </Row> :
          <Row className='d-flex align-items-center justify-content-between w-100 mt-0'>
            <Col md={2} className='mb-md-0'>
              <Form.Group>
                <Form.Label> Lead/Manager </Form.Label>
                <Form.Select className="w-100" onClick={(e) => handleronChange(e.target.value)}>
                  <option value="All">All</option>
                  {empLeads.length > 0 && empLeads !== undefined && empLeads !== null &&
                    empLeads.map((row) => {
                      return (
                        <option value={row}>{row}</option>
                      )
                    })}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={4} className='d-flex justify-content-end mt-3 mt-mb-0'>
              <Row style={{ width: '100%' }}>
                <Col md={6} className='ps-0 mb-2 mb-md-0  '>
                  <Form.Group className='w-100 THIS'>
                    <Form.Label> From </Form.Label>
                    <Form.Control className='w-100' type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                  </Form.Group>
                </Col>

                <Col md={6} className='ps-0'>
                  <Form.Group>
                    <Form.Label> To </Form.Label>
                    <Form.Control type="date" value={endDate} min={startDate} onChange={(e) => setEndDate(e.target.value)} />
                  </Form.Group>
                </Col>
              </Row>
            </Col>
          </Row>}


        {/* <DashCards totalPro={cardApiRes ? cardApiRes.totalProjects : 0} totalEst={cardApiRes ? cardApiRes.totalEstimatedHrs.toFixed(2) : 0}
          totalActTime={cardApiRes ? cardApiRes.totalActualHrs.toFixed(2) : 0} slippage={cardApiRes?.slippage ? cardApiRes?.slippage.toFixed(2) : 0}
          totalTask={cardApiRes?.totalTasks ? cardApiRes?.totalTasks : 0} efficiency={cardApiRes?.efficiency ? cardApiRes?.efficiency.toFixed(2) : 0}
          totalPendingTask={cardApiRes?.totalPending ? cardApiRes?.totalPending : 0}
          totalCompTask={cardApiRes?.totalCompleted ? cardApiRes?.totalCompleted : 0}
          avgQWD={isNaN(Number(cardApiRes?.avgQWD)) ? 0 : Number(cardApiRes?.avgQWD).toFixed(2)}
          avgIDC={isNaN(Number(cardApiRes?.avgIDC)) ? 0 : Number(cardApiRes?.avgIDC).toFixed(2)} /> */}

        <DashCards
          totalPro={cardApiRes?.totalProjects || 0}
          totalEst={cardApiRes?.totalEstimatedHrs?.toFixed(2) || 0}
          totalActTime={cardApiRes?.totalActualHrs?.toFixed(2) || 0}
          slippage={cardApiRes?.slippage?.toFixed(2) || 0}
          totalTask={cardApiRes?.totalTasks || 0}
          efficiency={cardApiRes?.efficiency?.toFixed(2) || 0}
          totalPendingTask={cardApiRes?.totalPending || 0}
          totalCompTask={cardApiRes?.totalCompleted || 0}
          avgQWD={isNaN(Number(cardApiRes?.avgQWD)) ? 0 : Number(cardApiRes?.avgQWD)?.toFixed(2) || 0}
          avgIDC={isNaN(Number(cardApiRes?.avgIDC)) ? 0 : Number(cardApiRes?.avgIDC)?.toFixed(2) || 0}
        />


        <Row className='mt-5'>
          {projectData !== undefined && projectData !== null && projectData.length > 0 ? (
            <MultiLineChart chartData={projectLabel} label="_id" value1="totalEstimateHrs" value2="totalActualHrs"
              chartHeading='Project Wise Estimated Vs Actual Time Spent (In Hrs)' indexAxis="x" chartHeight={500} color1={"#4E79A7"} color2={"#F28E2B"} />
          )
            : ("")}
        </Row>

        <Row className='mt-5'>
          <Col md={6} className='px-3 mb-4 mb-md-0' >
            {categoryData !== undefined && categoryData !== null && categoryData.length > 0 ? (
              <HorizontalChart chartData={categoryLabel} chartHeading="Work Category Wise Time Spent" label="_id" value="totalActualHrs" chartHeight={600} color={"#76B7B2"} />
            )
              : ("")
            }
          </Col>

          <Col md={6} className='px-3'>
            {slippageData !== undefined && slippageData !== null && slippageData.length > 0 ? (
              <HorizontalChart chartData={slippageLabel} chartHeading="Project Wise Slippage" label="_id" value="slippage" chartHeight={600} color={"#F28E2B"} />
            ) : (
              " ")}
          </Col>
        </Row>

        <Row className='mt-5 mb-5'>
          <Col md={6} className='px-3 mb-4 mb-md-0' >
            {idcData !== undefined && idcData !== null && idcData.length > 0 ? (
              <HorizontalChart chartData={idcLabel} chartHeading="Avg. IDC (Independent Delivery Capability) Rating (1-10)" label="_id" value="avgIDC" chartHeight={600} color={"#D4A6C8"} />
            ) : (
              ""
            )}
          </Col>

          <Col md={6} className='px-3'>
            {qwdData !== undefined && qwdData !== null && qwdData.length > 0 ? (
              <HorizontalChart chartData={qwdLabel} chartHeading="Avg. QWD (Quality of work delivered) Rating (1-10)" label="_id" value="avgQWD" chartHeight={600} color={"#59A14F"} />
            ) : (
              ""
            )}
          </Col>
        </Row>
      </Container>
    </div >
  )
}

export default EmpDash;