import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, LabelList, Label } from 'recharts';

const HorizontalChart = ({ chartData, chartHeading, label, value, chartHeight, color }) => {

    const CustomizedLabel = ({ x, y, width, height, value }) => (
        <text x={x + width + 5} y={y + height / 1.4} fill={value === 0 ? 'red' : '#8884d8'} textAnchor="start" fontSize={12} marginBottom={20}>
            {value}
        </text>
    );

    return (
        <div className='chart-container' >
            <p className='text-center fw-bold'>{chartHeading}</p>
            <ResponsiveContainer width="100%" height={chartHeight ? chartHeight : '400'}>
                <BarChart data={chartData} layout="vertical" margin={{ top: 10, right: 60, left: 20, bottom: 20 }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis type="number" fontSize={10} />
                    <YAxis dataKey={label} type="category" fontSize={12} width={100} angle={-15}
                        textAnchor="end" />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey={value} fill={color} barSize={30} >
                        <LabelList dataKey={value} position="right" content={<CustomizedLabel />} />
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        </div>
    );

}

export default HorizontalChart;