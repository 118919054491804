import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setActiveKey } from './ActiveKeySlice';
import { setActiveToggle } from './AllTaskSlice';


const DashCards = ({ totalProj, totalResource, totalPro, totalEst, totalActTime, slippage, totalTask, efficiency, totalPendingTask, totalCompTask, avgQWD, avgIDC }) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const loginUserData = useSelector((state) => state.loginData.userData);
    const currentDashToggleTab = useSelector((state) => state?.AllTasks?.ActiveDashToggle);
    const roleOfUser = ["intern", "employee", "contractual"]

    return (
        <div className='dashCards'>
            <Container className='my-4'>
                {loginUserData.resourceType === "humanResource" && currentDashToggleTab === "All Dashboard" ? <Row className='w-100 gy-3 gy-md-0 d-flex justify-content-center'>
                    <Col lg={5}>
                        <Row className='gy-3'>
                            <Col md={6}>
                                {((loginUserData?.resourceType === 'humanResource' || currentDashToggleTab === 'All Dashboard') && (
                                    <Card style={{ cursor: 'pointer' }} onClick={() => {
                                        navigate(`/${loginUserData?.resourceType}`);
                                        dispatch(setActiveKey({ activeKey: 1, activeTab: 'Home' })); // If using Redux for state management
                                    }}>
                                        <h6>Total Resource</h6>
                                        <h3>{totalResource ? totalResource : 0}</h3>
                                    </Card>
                                ))}
                            </Col>
                            <Col md={6}>
                                <Card>
                                    <h6>Total Project</h6>
                                    <h3>{totalProj ? totalProj : 0}</h3>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row> : <Row className='w-100 gy-3 gy-md-0 d-flex justify-content-center'>
                    <Col lg={5}>
                        <Row className='gy-3'>
                            <Col md={6}>
                                <Card>
                                    <h6>Total Est. Time</h6>
                                    <h3>{totalEst ? totalEst : 0}</h3>
                                </Card>
                            </Col>
                            <Col md={6}>
                                <Card>
                                    <h6>Actual Time Spent</h6>
                                    <h3>{totalActTime ? totalActTime : 0}</h3>
                                </Card>
                            </Col>
                            <Col md={6}>
                                <Card>
                                    <h6>Overall Efficiency</h6>
                                    <h3>{efficiency ? efficiency : 0}</h3>
                                </Card>
                            </Col>
                            <Col md={6}>
                                <Card>
                                    <h6>Slippage</h6>
                                    <h3>{slippage ? slippage : 0}</h3>
                                </Card>
                            </Col>
                        </Row>
                    </Col>

                    <Col lg={5}>
                        <Row className='gy-3'>
                            <Col md={6}>
                                <Card>
                                    <h6>Projects Worked On</h6>
                                    <h3>{totalPro ? totalPro : 0}</h3>
                                </Card>
                            </Col>
                            <Col md={6}>

                                {((roleOfUser.includes(loginUserData?.resourceType) || currentDashToggleTab === 'All Dashboard') && (
                                    <Card style={{ cursor: 'pointer' }} onClick={() => {
                                        navigate(`/${loginUserData.resourceType}`);
                                        dispatch(setActiveKey({ activeKey: 1, activeTab: 'Home' }))
                                    }}>
                                        <h6>Total Tasks</h6>
                                        <h3>{totalTask ? totalTask : 0}</h3>
                                    </Card>
                                )) ||
                                    (!roleOfUser.includes(loginUserData?.resourceType) && currentDashToggleTab === 'Self Dashboard' && (
                                        <Card
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                navigate(`/${loginUserData.resourceType}/self-task`);
                                                dispatch(setActiveKey({ activeKey: 5, activeTab: 'Self Task' }))
                                            }}
                                        >
                                            <h6>Total Tasks</h6>
                                            <h3>{totalTask ? totalTask : 0}</h3>
                                        </Card>
                                    ))}

                            </Col>
                            <Col md={6}>
                                {((roleOfUser.includes(loginUserData?.resourceType) || currentDashToggleTab === "All Dashboard") && (
                                    <Card style={{ cursor: 'pointer' }} onClick={() => {
                                        navigate(`/${loginUserData.resourceType}`);
                                        dispatch(setActiveKey({ activeKey: 1, activeTab: 'Home' }));
                                        dispatch(setActiveToggle('pending'));
                                    }}>
                                        <h6>Pending Tasks</h6>
                                        <h3>{totalPendingTask ? totalPendingTask : 0}</h3>
                                    </Card>
                                )) || (!roleOfUser.includes(loginUserData?.resourceType) && currentDashToggleTab === "Self Dashboard" && (
                                    <Card style={{ cursor: 'pointer' }} onClick={() => {
                                        navigate(`/${loginUserData.resourceType}/self-task`);
                                        dispatch(setActiveKey({ activeKey: 5, activeTab: 'Self Task' }));
                                        dispatch(setActiveToggle('pending'));
                                    }}>
                                        <h6>Pending Tasks</h6>
                                        <h3>{totalPendingTask ? totalPendingTask : 0}</h3>
                                    </Card>
                                ))}

                            </Col>
                            <Col md={6}>
                                {((roleOfUser.includes(loginUserData?.resourceType) || currentDashToggleTab === "All Dashboard") && (
                                    <Card style={{ cursor: 'pointer' }} onClick={() => {
                                        navigate(`/${loginUserData.resourceType}`);
                                        dispatch(setActiveKey({ activeKey: 1, activeTab: 'Home' }));
                                        dispatch(setActiveToggle('completed'));
                                    }}>
                                        <h6>Completed Tasks</h6>
                                        <h3>{totalCompTask ? totalCompTask : 0}</h3>
                                    </Card>
                                )) || ((!roleOfUser.includes(loginUserData?.resourceType) && currentDashToggleTab === "Self Dashboard") && (
                                    <Card style={{ cursor: 'pointer' }} onClick={() => {
                                        navigate(`/${loginUserData.resourceType}/self-task`);
                                        dispatch(setActiveKey({ activeKey: 5, activeTab: 'Self Task' }));
                                        dispatch(setActiveToggle('completed'));
                                    }}>
                                        <h6>Completed Tasks</h6>
                                        <h3>{totalCompTask ? totalCompTask : 0}</h3>
                                    </Card>
                                ))}

                            </Col>
                        </Row>
                    </Col>

                    <Col lg={2} className='pe-0'>
                        <Row className='gy-3'>
                            <Col>
                                <Card>
                                    <h6 className='d-none d-md-block'>Average QWD</h6>
                                    <h6 className='d-block d-md-none'>Avg QWD</h6>
                                    <h3>{avgQWD ? avgQWD : 0}</h3>
                                </Card>
                            </Col>

                            <Col>
                                <Card>
                                    <h6 className='d-none d-md-block'>Average IDC</h6>
                                    <h6 className='d-block d-md-none'>Avg IDC</h6>
                                    <h3>{avgIDC ? avgIDC : 0}</h3>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>}

            </Container>

        </div>
    )
}

export default DashCards;