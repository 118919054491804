import React, { useEffect, useState } from "react";
import * as Icon from "react-bootstrap-icons";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import useGetAllProjects from '../ReuseApi/useGetAllProjects';
import useGetAllCategories from '../ReuseApi/useGetAllCategories';
import Select from "react-select";
import { InputGroup, Modal, Button, Form, Row, Col } from "react-bootstrap";
import axios from "axios";
import DisplayHoursAndMinutes from "../Convertor/DisplayHoursAndMinutes";

const EditTaskModel = (props) => {

  const { show, close, item, } = props;
  const toastDate = () => {
    toast.error("Start date cannot less than the End date", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 1000,
    });
  };

  const toastSuccess = () => {
    toast.success("Update Successfully", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 1000,
    });
  };

  const EstimateHr = () => {
    toast.error("Initial Estimate hr can not be 0.", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 1000,
    });
  }

  const [projectname1, setProjectname1] = useState("")
  const [category1, setCategory1] = useState("")
  const [departmentName, setDepartmentName] = useState("")

  // for get Task assign tp

  const [tasktitleerror, setTaskTitleError] = useState(false);
  const [taskdescerror, setTaskDescError] = useState(false);
  const [taskcategoryError, setTaskCategoryError] = useState(false);
  const [taskprojnameerror, setTaskProjNameError] = useState(false);
  const [taskpriorityerror, setTaskPriorityError] = useState(false);
  const [taskproposeddterror, setTaskProposedDtError] = useState(false);
  const [taskproposedenddterror, setTaskProposedEndDtError] = useState(false);
  const [taskinitialestihrerror, setTaskInitialEstimateHrError] = useState(false);


  const [updatetask, setUpdateTask] = useState({
    empId: "",
    projectName: "",
    taskName: "",
    taskId: "",
    resourceName: "",
    categoryOfName: "",
    proposeStartDate: "",
    proposeEndDate: "",
    taskDescription: "",
    taskPriority: "",
    initialEstimateHr: "",
  });


  const { allProjectOptions, allProjectOptionsById } = useGetAllProjects()
  const { allCategoriesOptions } = useGetAllCategories();


  useEffect(() => {
    setUpdateTask({
      empId: item?.empId,
      projectName: item?.projectName,
      taskName: item?.taskName,
      taskId: item?.taskId,
      resourceName: item?.resourceName,
      categoryOfName: item?.categoryOfName,
      proposeStartDate: item?.proposedStartDate,
      proposeEndDate: item?.proposedEndDate,
      taskDescription: item?.taskDescription,
      taskPriority: item?.taskPriority,
      initialEstimateHr: item?.initialEstimateHr,
    });
    setProjectname1({ label: item?.projectName, value: item?.projectName })
    setCategory1({ label: item?.categoryOfName, value: item?.categoryOfName })
    setDepartmentName({ label: item?.departmentName, value: item?.departmentName })
  }, [props]);

  // for hours and minute
  const initialEstimateHr = item?.initialEstimateHr || 0;
  const parts = initialEstimateHr.toFixed(2).toString().split(".");
  const integerPart = parseInt(parts[0]) || 0;
  const decimalPart = parseInt(parts[1]) || 0;
  const hours = integerPart;
  const minutes = Math.round((decimalPart / 100) * 60);

  let name, value;
  const handleInputs = (e) => {
    name = e.target.name;
    value = e.target.value;
    setUpdateTask({ ...updatetask, [name]: value });
  };

  const handlerValidate = () => {
    let FormValid = true;

    if (updatetask.taskName.length === 0) {
      FormValid = false;
      setTaskTitleError(true);
    }
    else if (updatetask.taskDescription.length === 0) {
      FormValid = false;
      setTaskDescError(true);
    }
    else if (updatetask.taskPriority.length === 0) {
      FormValid = false;
      setTaskPriorityError(true);
    }
    else if (updatetask.proposeStartDate.length === 0) {
      FormValid = false;
      setTaskProposedDtError(true);
    }
    else if (updatetask.proposeEndDate.length === 0) {
      FormValid = false;
      setTaskProposedEndDtError(true);
    }
    else if (updatetask.initialEstimateHr?.length === 0) {
      FormValid = false;
      setTaskInitialEstimateHrError(true);
    }
    return FormValid;
  };

  const updateTask = async (e) => {
    e.preventDefault();
    setTaskTitleError(false);
    setTaskDescError(false);
    setTaskCategoryError(false);
    setTaskProjNameError(false);
    setTaskPriorityError(false);
    setTaskProposedDtError(false);
    setTaskProposedEndDtError(false);
    setTaskInitialEstimateHrError(false);
    if (handlerValidate()) {
      if (parseFloat(updatetask?.initialEstimateHr) === 0) {
        EstimateHr()
      } else {
        let upadatePayload = {
          empId: updatetask?.empId,
          taskId: updatetask?.taskId,
          projectName: projectname1.label,
          taskName: updatetask?.taskName,
          resourceName: updatetask.resourceName,
          categoryOfName: category1.label,
          proposedStartDate: new Date(updatetask?.proposeStartDate).toISOString().split('T')[0],
          proposedEndDate: new Date(updatetask?.proposeEndDate).toISOString().split('T')[0],
          taskDescription: updatetask?.taskDescription,
          taskPriority: updatetask?.taskPriority,
          initialEstimateHr: updatetask?.initialEstimateHr,
        }
        await axios.post(process.env.REACT_APP_BASE_URL + "/task/updateTask", upadatePayload)
          .then((res) => {
            toastSuccess()
            setTimeout(() => {
              props.close()
            }, 1000)
          })
          .catch((err) => {
            console.log("Error from update api", err);
          })
      }
    }
  }

  return (
    <div>
      <ToastContainer />
      <Modal show={show} onHide={close} size="lg" centered>
        <Modal.Header
          closeButton
          style={{
            color: "black",
            width: "100%",
            height: "50px",
            backgroundColor: "#F9D65F",
          }}
        >
          <Modal.Title><h5>Edit Task</h5></Modal.Title>
        </Modal.Header>
        <Modal.Body size="lg">
          <Row className="mt-3">
            <Col md={6}>
              <Form.Group controlId="formBasicName">
                <Form.Label>
                  Task Name{" "}
                  <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                </Form.Label>
                <Form.Control
                  name="taskName"
                  value={updatetask?.taskName}
                  onChange={(e) => {
                    if (
                      !e.target.value.match(
                        /[+@#$&%!~=^_:(){}\[\]*.|//\-/?<>,;`'""/\\]/
                      )
                    ) {
                      handleInputs(e);
                    } else if (e.target.value.length === 0) {
                      handleInputs(e);
                    }
                    updatetask.taskName.length <= 0
                      ? setTaskTitleError(true)
                      : setTaskTitleError(false);
                  }}
                />
                {tasktitleerror ? (
                  <p className="errMsg">
                    <>
                      <Icon.ExclamationTriangle className="me-2" />
                    </>
                    Required Field
                  </p>
                ) : null}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group
              >
                <Form.Label>
                  Project{" "}
                  <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                </Form.Label>
                <Select
                  isMulti={false}
                  isSearchable={true}
                  value={projectname1}
                  options={allProjectOptionsById}
                  onChange={(option) => { setProjectname1(option) }}
                />
                {taskprojnameerror ? (
                  <p className="errMsg">
                    <>
                      <Icon.ExclamationTriangle className="me-2" />
                    </>
                    Required Field
                  </p>
                ) : null}
              </Form.Group>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col md={12}>
              <Form.Group controlId="formBasicEmail">
                <Form.Label className="emp-form-label fw-bold">
                  Task Description{" "}
                  <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                </Form.Label>
                <Form.Control
                  as="textarea"
                  name="taskDescription"
                  value={updatetask?.taskDescription}
                  onChange={(e) => {
                    if (
                      !e.target.value.match(
                        /[+@#$&%!~=^_:(){}\[\]*.|//\-/?<>,;`'""/\\]/
                      )
                    ) {
                      handleInputs(e);
                    } else if (e.target.value.length === 0) {
                      handleInputs(e);
                    }
                    updatetask?.taskDescription.length <= 0
                      ? setTaskDescError(true)
                      : setTaskDescError(false);
                  }}
                />

                {taskdescerror ? (
                  <p className="errMsg">
                    <>
                      <Icon.ExclamationTriangle className="me-2" />
                    </>
                    Required Field
                  </p>
                ) : null}
              </Form.Group>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col md={4}>
              <Form.Group
              >
                <Form.Label >
                  Task Priority{" "}
                  <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                </Form.Label>
                <Form.Select
                  name="taskPriority"
                  onChange={(e) => {
                    handleInputs(e);
                    updatetask.taskPriority.length < 0
                      ? setTaskPriorityError(true)
                      : setTaskPriorityError(false);
                  }}
                  value={updatetask?.taskPriority}
                >
                  <option value={"normal"}>Normal</option>
                  <option value={"medium"}>Medium</option>
                  <option value={"immediate"}>Immediate</option>
                </Form.Select>
                {taskpriorityerror ? (
                  <p className="errMsg">
                    <>
                      <Icon.ExclamationTriangle className="me-2" />
                    </>
                    Required Field
                  </p>
                ) : null}
              </Form.Group>
            </Col>

            <Col md={4}>
              <Form.Group
              >
                <Form.Label>
                  Category{" "}
                  <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                </Form.Label>
                <Select
                  isMulti={false}
                  isSearchable={true}
                  value={category1}
                  options={allCategoriesOptions}
                  onChange={(option) => { setCategory1(option) }}
                />

                {taskcategoryError ? (
                  <p className="errMsg">
                    <>
                      <Icon.ExclamationTriangle className="me-2" />
                    </>
                    Required Field
                  </p>
                ) : null}
              </Form.Group>
            </Col>

            <Col md={4}>
              <Form.Group>
                <Form.Label>
                  Assign To{" "}
                </Form.Label>

                <Form.Control
                  value={updatetask?.resourceName}
                  readOnly
                >
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col md={4}>
              <Form.Group>
                <Form.Label>
                  Start Date{" "}
                </Form.Label>
                <Form.Control
                  type="date"
                  name="proposeStartDate"
                  min={new Date().toISOString().split("T")[0]}
                  onKeyDown={(event) => event.preventDefault()}
                  value={moment(updatetask?.proposeStartDate).format(
                    "YYYY-MM-DD"
                  )}
                  readOnly
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <Form.Label>
                  End Date{" "}
                </Form.Label>
                <Form.Control
                  type="date"
                  name="proposeEndDate"
                  onKeyDown={(event) => event.preventDefault()}
                  min={(updatetask?.proposeStartDate)?.split('T')[0]}
                  value={updatetask?.proposeEndDate
                    ? new Date(updatetask?.proposeEndDate).toISOString().split("T")[0]
                    : ""}
                  readOnly
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group controlId="formBasiContact">
                <Form.Label>
                  Initial Estimate(Hours){" "}
                </Form.Label>
                <Form.Control
                  // value={updatetask?.initialEstimateHr}
                  value={`${hours} hr ${minutes} min`}
                  readOnly
                />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="text-center mt-4">
          <Button
            className="primaryBtn"
            onClick={(e) => updateTask(e)}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default EditTaskModel;
