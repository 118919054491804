import React, { useEffect, useState } from "react";
import * as Icon from "react-bootstrap-icons";
import { ToastContainer, toast } from "react-toastify";
import { InputGroup, Modal, Button, Form, Row, Col } from "react-bootstrap";
import axios from "axios";

const EditToDOListModal = ({ show, close,item}) => {

  // state for update
 const [updatetodolist,setUpdateToDoList] = useState({
      toDoListId:"",
      title:"",
      description:"",
 })
 

let name,value;
const handlerUpdateData = (e) => {
  name = e.target.name;
  value = e.target.value;
  setUpdateToDoList({...updatetodolist,[name]:value})
}
 
useEffect(() => {
    setUpdateToDoList({
      toDoListId: item?.toDoListId,
      title: item?.title,
      description: item?.description
    })
  },[])

  // api calling for update data
  const updateToDoListData = async() => {
    const payload = {
       "toDoListId": item?.toDoListId,
       "title": updatetodolist?.title,
       "description": updatetodolist?.description
    }
    await axios.post(process.env.REACT_APP_BASE_URL + "/toDoList/updateToDoList" , payload)
    .then((res) => {
      toast.success('Update Successfully',{
         position: toast.POSITION.TOP_RIGHT,
      })
      close();
    })
  }

  return (
    <>
      <ToastContainer autoClose = {5000}/>
        <Modal show={show} onHide={close} size="md" centered backdrop='static' keyboard={false}>
        <Modal.Header closeButton className="mngmodelheader">
            <Modal.Title><h5>Edit Note</h5></Modal.Title>
        </Modal.Header>
        <Modal.Body size="lg">
            <Row className='mb-3'>
                <Col sm={12} md={12}>
                    <InputGroup>
                        <Form.Control
                         className="input-field project-inputgroup"
                         name = "title"
                         value = {updatetodolist?.title}
                         onChange={(e) => handlerUpdateData(e)}
                        />
                    </InputGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={12} md={12}>
                    <InputGroup>
                        <Form.Control
                          className="input-field project-inputgroup"
                          as="textarea"
                          rows={5}
                          name = "description"
                          value = {updatetodolist?.description}
                          onChange={(e) => handlerUpdateData(e)}
                        />
                    </InputGroup>
                </Col>
            </Row>
        </Modal.Body>

        <Modal.Footer className="text-center mt-4">
          <Button className="primaryBtn" onClick={() => updateToDoListData()}>Update</Button>
        </Modal.Footer>
        </Modal>
    </>
  );
};

export default EditToDOListModal;
