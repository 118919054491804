import axios from 'axios';
import React, { useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const DeleteProjectModel = ({show, close, projectId}) => {

  const DeleteProjectToast = () => {
    toast.success("Deleted Task Successfully", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose:1000
    });
  };

  const DeleteProjectData = async (e) => {
    e.preventDefault();
    let payload = {
      projectId: projectId,
    };
    console.log("payload--------",payload);
    try {
      await axios.post(
        process.env.REACT_APP_BASE_URL + "/project/deleteProject",payload )
        .then((res) => {
          DeleteProjectToast()
          setTimeout(() => {
            close()
          }, 1000)
        })
        .catch((error) => {
          console.log("error", error);
        })
    } catch (error) {
      console.log("Error", error);
    }
  };

  return (
    <>
        <ToastContainer />
      <Modal show={show} onHide={close} centered backdrop="static" keyboard={false}>
        <Modal.Header closeButton style={{ color: "black" }}>
          <Modal.Title><h5>Delete Employee</h5></Modal.Title>
        </Modal.Header>
        <Modal.Body className='py-4'>
          <p style={{ color: "black", fontSize: "15px", margin: '0px' }}>
            Are you sure you want to delete this Records?👎
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button className='secondaryBtn' onClick={close}>
            Cancel
          </Button>
          <Button variant="danger"
            onClick={(e) => DeleteProjectData(e)}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
