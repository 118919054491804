import axios from 'axios';
import React, { useState } from 'react'
import { Button, Col, Form, InputGroup, Modal, Row, Spinner } from 'react-bootstrap';
import * as Icon from "react-bootstrap-icons";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from "react-select";
import { useDispatch, useSelector } from 'react-redux';
import useGetAllCategories from '../ReuseApi/useGetAllCategories';
import useGetAllProjects from '../ReuseApi/useGetAllProjects';
import { useNavigate } from 'react-router-dom';
import { setActiveKey } from '../ActiveKeySlice';

export const AssignNoTaskModel = ({show, close, data}) => {

    const loginUserData = useSelector((state) => state.loginData.userData)

    // for redirect
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // for map department
    const userDepartmentName = (data?.department || []).map(itm => ({ label: itm.label, value: itm.value }));

    // for taskpriotrity
    const taskPriorityOptions = [{ value: 'normal', label: 'Normal' }, { value: 'medium', label: 'Medium' }, { value: 'immediate', label: 'Immediate' }];
    // use custome hook
    const { allCategoriesOptions } = useGetAllCategories()
    const { allProjectOptions, allProjectOptionsById } = useGetAllProjects()

    // for indivisual state
     const [taskpriority, setTaskpriority] = useState("")
     const [projectname, setProjectname] = useState("")
     const [categoryname, setCategoryname] = useState("")

    // for usestate
     const [addnotask, setAddNoTask] = useState({
        empId: "",
        taskId: "",
        taskName: "",
        taskdescription: "",
        department_id: "",
        departmentName: "",
        AssignTask: "",
        assignBy_id: "",
        assignby: "",
        assignto: "",
        assigntoid: "",
        proposedstartdate: "",
        proposedenddate: "",
        initialEstimateHr: "",
        initialEstimateMinutes:"",
        estimatehr: "",
        logStatus: {
            resource_id: "",
            resourceName: "",
        },
    });

    // create usestate for error
    const [tasktitleerror, setTaskTitleError] = useState(false);
    const [taskdescerror, setTaskDescError] = useState(false);
    const [taskcategoryError, setTaskCategoryError] = useState(false);
    const [taskprojnameerror, setTaskProjNameError] = useState(false);
    const [taskpriorityerror, setTaskPriorityError] = useState(false);
    const [taskproposeddterror, setTaskProposedDtError] = useState(false);
    const [taskproposedenddterror, setTaskProposedEndDtError] = useState(false);
    const [taskinitialestihrerror, setTaskInitialEstimateHrError] = useState(false);
    const[initialminuteErr,setInitialMinuteErr] = useState(false)

     const handlerValidate = () => {
        let FormValid = true;

        if (addnotask.taskName.length === 0) {
            FormValid = false;
            setTaskTitleError(true);
        }else if (projectname.length === 0) {
            FormValid = false;
            setTaskProjNameError(true);
        }
        else if (addnotask.taskdescription.length === 0) {
            FormValid = false;
            setTaskDescError(true);
        }else if (categoryname.length === 0) {
            FormValid = false;
            setTaskCategoryError(true);
        }else if (taskpriority.length === 0) {
            FormValid = false;
            setTaskPriorityError(true);
        }else if (addnotask.proposedstartdate.length === 0) {
            FormValid = false;
            setTaskProposedDtError(true);
        }else if (addnotask.proposedenddate.length === 0) {
            FormValid = false;
            setTaskProposedEndDtError(true);
        }else if (addnotask.initialEstimateHr.length === 0) {
            FormValid = false;
            setTaskInitialEstimateHrError(true);
        }else if (addnotask.initialEstimateMinutes.length === 0) {
            FormValid = false;
            setInitialMinuteErr(true);
        }

        return FormValid;
    };

    const handlerData = (e) => {
      let name,value
        name = e.target.name;
        value = e.target.value;
        setAddNoTask({ ...addnotask, [name]: value });
    };

    
    const AddTaskSuccess = () => {
        toast.success("Assigned Task Successfully", {
          position: toast.POSITION.TOP_CENTER,
          autoClose:1000,
        });
    };

    const AlreadyTaskAdd = () => {
        toast.error("Task Already Assigned", {
          position: toast.POSITION.TOP_CENTER,
          autoClose:1000,
        });
    };

    const InitialHrMinute = () =>{
      toast.error("00 hours and 00 minutes at the same time are not permitted, please choose an acceptable time.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose:1000,
      });
  }

    const handleSubmit = (e) => {
        setTaskTitleError(false);
        setTaskDescError(false);
        setTaskCategoryError(false);
        setTaskProjNameError(false);
        setTaskPriorityError(false);
        setTaskProposedDtError(false);
        setTaskProposedEndDtError(false);
        setTaskInitialEstimateHrError(false);
        setInitialMinuteErr(false)
        if(handlerValidate()){
          if(parseFloat(addnotask.initialEstimateHr) === 0 && parseFloat(addnotask.initialEstimateMinutes) === 0){
            InitialHrMinute();
          }else{
      const payload = {
        "empId": data?.empId,
        "taskName": addnotask?.taskName,
        "taskDescription": addnotask?.taskdescription,
        "taskPriority": taskpriority?.value,
        "department_id":userDepartmentName[0].value,
        "departmentName": userDepartmentName[0].label,
        "taskDepartmentId": userDepartmentName[0].value,
        "taskDepartmentName": userDepartmentName[0].label,
        "projectId": projectname.value,
        "projectName": projectname.label,
        "AssignTask": "",
        "assignBy_id": loginUserData?.empId,
        "assignBy_Name": `${loginUserData.firstName} ${loginUserData.lastName}`,
        "resource_id": data?.empId,
        "resourceName": `${data.firstName} ${data.lastName}`,
        "categoryOfName": categoryname.label,
        "category_id": categoryname.value,
        "proposedStartDate": new Date(addnotask.proposedstartdate).toISOString().split('T')[0],
        "proposedEndDate": new Date(addnotask.proposedenddate).toISOString().split('T')[0],
        "initialEstimateHr": addnotask.initialEstimateHr,
        "initialEstimateMinutes":addnotask.initialEstimateMinutes,
        "estimateHr": "",
        "status": "pending",
        "logStatus": {
          "status": "pending",
          "resource_id": data?.empId,
          "resourceName": `${data.firstName} ${data.lastName}`,
        }
      }

      axios.post(process.env.REACT_APP_BASE_URL + '/task/addTask', payload)
        .then((res) => {
          console.log("response",res)
          AddTaskSuccess(); 
          // useDispatch(setActiveKey({ activeKey: 3.2 , activeTab: 'Employee Task' }))
            setTimeout(()=>{
              close()
              navigate(`/${loginUserData?.resourceType}/employee-task`)
            }, 1000)
            
          // if(res.data.msg === "Add Task Successfully"){
          //   AddTaskSuccess(); 
          //   setTimeout(()=>{
          //     close()
          //     navigate(`/${loginUserData?.resourceType}`)
          //   }, 1000)
           
          // } else{
          //    AlreadyTaskAdd()
          //    close()
          // } 
        })
        .catch((err) => {
          console.log("Error", err)
        })
      }
   } 
  }
     
  return (
    <>
    <ToastContainer/>
     <Modal show={show} onHide={close} size="lg" centered backdrop='static' keyboard={false}>
    <Modal.Header closeButton className="mngmodelheader">
      <Modal.Title><h5>Assign Task</h5></Modal.Title>
    </Modal.Header>
    <Modal.Body size="lg">
      <Row className='mb-3'>
        <Col sm={12} md={6}>
          <Form.Group controlId="formBasicId">
            <Form.Label>
              Task Title
              <span className="requiredfield">*</span>
            </Form.Label>
              <Form.Control
                placeholder="Task Name"
                name="taskName"
                value = {addnotask.taskName}
                onChange={(e) => {
                    if (!e.target.value.match(/[+@#$&%!~=^_:(){}\[\]*.|//\-/?<>,;`'""/\\]/) && e.target.value.trim()) {
                          handlerData(e);} 
                          else if (e.target.value.length === 0) {
                          handlerData(e);}
                          addnotask.taskName.length >= 0
                          ? setTaskTitleError(false)
                          : setTaskTitleError(true);
                          }}
              />
              {tasktitleerror ? (
                <p className="errMsg">
                  <>
                    <Icon.ExclamationTriangle className="me-2" />
                  </>
                  Required Field
                </p>
              ) : null}
          </Form.Group>
        </Col>
         <Col sm={12} md={6}>
          <Form.Group className="">
            <Form.Label> Project Name<span>*</span> </Form.Label>
             <Select isMulti={false} isSearchable={true}
                value={projectname}
                options={allProjectOptionsById}
                onChange={(option) => {
                  setProjectname(option);
                  projectname.length < 0
                    ? setTaskProjNameError(true)
                    : setTaskProjNameError(false);
                }}
              />
          </Form.Group>
          {taskprojnameerror ? (
                <p className="errMsg">
                  <>
                    <Icon.ExclamationTriangle className="me-2" />
                  </>
                  Required Field
                </p>
              ) : null}
        </Col>
      </Row>

      <Row className='mb-3'>
        <Col sm={12} md={12}>
          <Form.Group className="">
            <Form.Label className="mt-3">
              Task Description<span> *</span>
            </Form.Label> 
              <Form.Control
                as="textarea"
                placeholder="Task Description"
                name='taskdescription'
                value={addnotask.taskdescription}
                onChange={(e) => {
                    if (
                      e.target.value.trim()
                    ) {
                      handlerData(e);
                    } else if (e.target.value.length === 0) {
                      handlerData(e);
                    }
                    addnotask.taskdescription.length >= 0
                      ? setTaskDescError(false)
                      : setTaskDescError(true);
                  }}
              />
           
          </Form.Group>
           {taskdescerror ? (<p className="errMsg" style={{ color: "red" }}>
            <>
              <Icon.ExclamationTriangle className="me-2" />
            </>Required Field
            </p>
            ) : null}
        </Col>
      </Row>

     <Row className='mb-3'>
      <Col sm={12} md={6}>
          <Form.Group className="">
            <Form.Label>
              Category Name
              <span className="requiredfield">*</span>
            </Form.Label>
                 <Select
                    isMulti={false}
                    isSearchable={true}
                    value={categoryname}
                    options={allCategoriesOptions}
                    onChange={(option) => {
                      setCategoryname(option);
                       categoryname?.length < 0
                    ? setTaskCategoryError(true)
                    : setTaskCategoryError(false);
                    }}
              />
          </Form.Group>
          {taskcategoryError ? (
                <p className="errMsg">
                  <>
                    <Icon.ExclamationTriangle className="me-2" />
                  </>
                  Required Field
                </p>
              ) : null}
      </Col>
      <Col sm={12} md={6}>
          <Form.Label className="emp-form-label mt-3 fw-bold">
              Task Priority
              <span className="requiredfield">*</span>
          </Form.Label>
            <Select
                isMulti={false}
                isSearchable={true}
                value={taskpriority}
                options={taskPriorityOptions}
                onChange={(option) => {
                  setTaskpriority(option);
                  taskpriority.length < 0
                    ? setTaskPriorityError(true)
                    : setTaskPriorityError(false)
                }}
            />
          {taskpriorityerror ? (
                <p className="errMsg" style={{ color: "red" }}>
                  <>
                    <Icon.ExclamationTriangle className="me-2" />
                  </>
                  Required Field
                </p>
              ) : null}
      </Col>
      </Row>

      <Row className='mb-4'>
        <Col sm={12} md={4}>
          <Form.Group className="">
            <Form.Label className="">
              Department Name
            </Form.Label>
            <Form.Control 
             value={userDepartmentName.length > 0 ? userDepartmentName[0].label : ''}
              readOnly 
            />
          </Form.Group>
        </Col>

        <Col sm={12} md={4}>
          <Form.Group controlId="formBasicId">
            <Form.Label className="">
              Assign By
            </Form.Label>
            <Form.Control 
            name='assignBy_Name'
            value={`${loginUserData?.firstName} ${loginUserData?.lastName}`} 
            readOnly 
            />
          </Form.Group>
        </Col>
        
        <Col sm={12} md={4}>
          <Form.Group className="">
            <Form.Label className="mt-3 fw-bold">
              Assign To
            </Form.Label>
            <Form.Control 
              value={`${data?.firstName} ${data?.lastName}`}
              readOnly
            />
          </Form.Group>
        </Col>
      </Row>

       <Row className='mb-4'>
        <Col sm={12} md={3}>
          <Form.Group controlId="formBasiContact" className="w-100">
            <Form.Label>
              Proposed Start date{" "}
              <span className="requiredfield">*</span>
            </Form.Label>
              <Form.Control
                className=""
                type="date"
                name="proposedstartdate"
                min={new Date().toISOString().split("T")[0]}
                onKeyDown={(event) => event.preventDefault()}
                value={addnotask?.proposedstartdate ? new Date(addnotask?.proposedstartdate).toISOString().split("T")[0]: ""}
                 onChange={(e) => {
                    handlerData(e);
                    addnotask?.proposedstartdate?.length < 0
                      ? setTaskProposedDtError(true)
                      : setTaskProposedDtError(false);
                  }}
              />
          </Form.Group>
          {taskproposeddterror ? (
                <p className="errMsg">
                  <>
                    <Icon.ExclamationTriangle className="me-2" />
                  </>
                  Required Field
                </p>
              ) : null}
        </Col>

        <Col sm={12} md={3}>
          <Form.Group controlId="formBasiContact" style={{ width: "100%" }}>
            <Form.Label>
              Proposed End date{" "}
              <span className="requiredfield">*</span>
            </Form.Label>
              <Form.Control
                className=""
                type="date"
                name='proposedenddate'
                value={addnotask?.proposedenddate  ? new Date(addnotask?.proposedenddate ).toISOString().split("T")[0]: ""}
                 min={addnotask.proposedstartdate}
                  disabled={
                    addnotask?.proposedstartdate?.length === 0 ? true : false
                  }
                  onChange={(e) => {
                    handlerData(e);
                    addnotask?.proposedenddate?.length < 0
                      ? setTaskProposedEndDtError(true)
                      : setTaskProposedEndDtError(false);
                  }}
              />
          </Form.Group>
          {taskproposedenddterror ? (
                <p className="errMsg">
                  <>
                    <Icon.ExclamationTriangle className="me-2" />
                  </>
                  Required Field
                </p>
              ) : null}
        </Col>

        <Col sm={12} md={3}>
          <Form.Group controlId="formBasiContact" style={{ width: "100%" }}>
            <Form.Label>
              Initial Estimate(Hours){" "}
              <span className="requiredfield">*</span>
            </Form.Label>
              <Form.Control
                type="text"
                name="initialEstimateHr"
                value={addnotask?.initialEstimateHr}
                 onChange={(e) => {
                    if(/^[0-9]*(\.[0-9]*)?$/.test(e.target.value) && e.target.value.length <= 4){
                        handlerData(e);
                      } else if (e.target.value.length === 0) {
                        handlerData(e);
                      }
                    addnotask?.initialEstimateHr?.length < 0
                      ? setTaskInitialEstimateHrError(true)
                      : setTaskInitialEstimateHrError(false);
                  }}
              />
          </Form.Group>
          {taskinitialestihrerror ? (
                <p className="errMsg">
                  <>
                    <Icon.ExclamationTriangle className="me-2" />
                  </>
                  Required Field
                </p>
              ) : null}
        </Col>

         <Col sm={12} md={3}>
          <Form.Group controlId="formBasiContact" style={{ width: "100%" }}>
            <Form.Label>
              Initial Minutes
              <span className="requiredfield">*</span>
            </Form.Label>
              <Form.Control
                type="text"
                name="initialEstimateMinutes"
                value={addnotask?.initialEstimateMinutes}
                onChange={(e) => {
                    if(/^[0-9]{0,2}$/.test(e.target.value) && e.target.value >= 0 && e.target.value <= 59){
                        handlerData(e);
                      } else if (e.target.value > 59) {
                         toast.error('Please enter a value between 0 and 59.')}
                        else if (e.target.value.length === 0) {
                        handlerData();
                      }
                    addnotask?.initialEstimateMinutes?.length < 0
                      ? setInitialMinuteErr(true)
                      : setInitialMinuteErr(false);
                  }}
              />
          </Form.Group>
          {initialminuteErr ? (
                <p className="errMsg">
                  <>
                    <Icon.ExclamationTriangle className="me-2" />
                  </>
                  Required Field
                </p>
              ) : null}
         </Col>
      </Row>
    </Modal.Body>
    <Modal.Footer className="text-center mt-4">
      <Button className="primaryBtn " 
      onClick={() => { handleSubmit() }}
      >
        <Icon.PersonAdd className='me-2' /> Create Task
      </Button>

    </Modal.Footer>

    </Modal>
    </>
   
  )
}
