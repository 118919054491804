import axios from 'axios'
import React, { useState } from 'react'
import { Button, Form, Modal, Row } from 'react-bootstrap'

export const RejectModel = ({show,close,taskId}) => {
    const [rejectState, setRejectState] = useState("")

const RejectedTaskOtherDepartment = async()=>{
    let data = {
        taskId  : taskId,
        rejectReason : rejectState
    }
    await axios.post(process.env.REACT_APP_BASE_URL + "/task/rejectTask",data)
    .then((res)=>{
        console.log("response reject",);
        close()
            // getTaskAllocateDetails()
    })
    .catch((err)=>{
        console.log("error from api",err);
    })
    }
    return (
    <Modal
        show={show}
        onHide={close}
        centered >
        <Modal.Header closeButton style={{ color: "black" }}>
        <Modal.Title>Reject Task</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <h4 style={{ color: "black"}}>
            Are You Sure To Reject Task?👎
        </h4>
        <Row>
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label>Reject Reason</Form.Label>
        <Form.Control type="text" value={rejectState} onChange={(e)=>setRejectState(e.target.value)} as="textarea" rows={3} />
        </Form.Group>
        </Row>
        </Modal.Body>
        <Modal.Footer>
         <Button variant="secondary" onClick={()=>close()}>Cancel</Button>
         <Button style={{backgroundColor:"red"}} onClick={RejectedTaskOtherDepartment}disabled={!rejectState}> Reject </Button>
        </Modal.Footer>
   </Modal>
  )
}
