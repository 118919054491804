import React, { useEffect, useState } from "react";
import * as Icon from "react-bootstrap-icons";
import { ToastContainer, toast } from "react-toastify";
import { InputGroup, Modal, Button, Form, Row, Col } from "react-bootstrap";

const ViewToDOListModal = ({ show, close,item}) => {

  return (
    <>
      <ToastContainer autoClose = {5000}/>
        <Modal show={show} onHide={close} size="md" centered backdrop='static' keyboard={false}>
        <Modal.Header closeButton className="mngmodelheader">
            <Modal.Title><h5>View Note</h5></Modal.Title>
        </Modal.Header>
        <Modal.Body size="lg">
            <Row className='mb-3'>
                <Col sm={12} md={12}>
                    <InputGroup>
                        <Form.Control
                          value = {item?.title}
                          className="input-field project-inputgroup"
                          readOnly
                        />
                    </InputGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={12} md={12}>
                    <InputGroup>
                        <Form.Control
                          as="textarea"
                          rows={5}
                          value = {item?.description}
                          className="input-field project-inputgroup"
                          readOnly
                        />
                    </InputGroup>
                </Col>
            </Row>
        </Modal.Body>
        </Modal>
    </>
  );
};

export default ViewToDOListModal;
